export default [
  {
    id: 1,
    title: 'investmentBanking.title',
    description: 'investmentBanking.description',
    url: '/solution/investment-banking',
  },
  {
    id: 2,
    title: 'lombardLoan.title',
    description: 'lombardLoan.description',
    url: '/solution/lombard-loan',
  },
  {
    id: 3,
    title: 'propertyFinancing.title',
    description: 'propertyFinancing.description',
    url: '/solution/property-financing',
  },
  {
    id: 4,
    title: 'commercialLoan.title',
    description: 'commercialLoan.description',
    url: '/solution/commercial-loan',
  },
];