import React from 'react';
import cx from 'classnames';

import tabs from '../../data/tabs';
import services from '../../data/services';
import StandardLayout from "@/views/common/layouts/StandardLayout";
import LinearWhiteBackground from "../../../common/svgs/backgrounds/LinearWhiteBackground";
import WhyUsOurServicesHeadSection from "./sections/WhyUsOurServicesHeadSection";
import WhyUsOurServicesWealthManagementSection from "./sections/WhyUsOurServicesWealthManagementSection";
import WhyUsOurServicesEdgeSection from "./sections/WhyUsOurServicesEdgeSection";
import ContactSection from "../../../common/section/ContactSection";

import s from './styles.module.scss';


const WhyUsOurServicesPage = (props = {}) => {

  return (
    <StandardLayout>
      <div className={cx(
        'relative',
        'flex', 'flex-col', 'justify-start', 'align-stretch', 'bg-linear-white',
        s.container
      )}>
        <LinearWhiteBackground/>
        <WhyUsOurServicesHeadSection tabs={tabs}/>
        <WhyUsOurServicesWealthManagementSection services={services}/>
        <WhyUsOurServicesEdgeSection/>
        <ContactSection/>
      </div>
    </StandardLayout>
  );

};

export default WhyUsOurServicesPage;