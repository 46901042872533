import imgBanner01 from '@/assets/images/page-why-us-benefits/banner-01.png';
import imgMobileBanner01 from '@/assets/images/page-why-us-benefits/mobile-banner-01.png';
import imgCover01 from '@/assets/images/page-why-us-benefits/cover-01.png';
import imgCover02 from '@/assets/images/page-why-us-benefits/cover-02.png';
import imgCover03 from '@/assets/images/page-why-us-benefits/cover-03.png';
import imgCover04 from '@/assets/images/page-why-us-benefits/cover-04.png';
import imgCover05 from '@/assets/images/page-why-us-benefits/cover-05.png';
import imgCall from '@/assets/images/page-why-us-benefits/ic-call.svg';
import imgConsultant from '@/assets/images/page-why-us-benefits/ic-consultant.svg';
import imgMobile from '@/assets/images/page-why-us-benefits/ic-mobile.svg';

export default {
  id: 1,
  type: 'lifestyle',
  url: 'https://kkpsecurities.co/2023PrivilegesList',
  banner: {
    mobile: imgMobileBanner01,
    desktop: imgBanner01,
  },
  title: {
    en: 'Lifestyle Privilege',
    th: 'Lifestyle Privilege',
  },
  summary: {
    en: 'Kiatnakin Phatra Securities provides a variety of ultimate benefits to our valued clients. These benefits are intended to deliver the greatest experience, as follows',
    th: 'เอกสิทธิ์พิเศษที่ บล.เกียรตินาคินภัทร ตั้งใจคัดสรร ออกแบบ และจัดทำ เพื่อมอบประสบการณ์ที่ดีที่สุด พร้อมเติมเต็มความสุขให้กับลูกค้าคนสำคัญของเรา โดยเอกสิทธิ์พิเศษมีหลากหลายประเภท ดังนี้',
  },
  description: {
    en: 'Carefully selected exclusive privileges are available, including limousine airport transfer, exclusive airport lounges, hotel accommodations, dining credits at hotels and restaurants, wellness programs, gift sets, and the option to make donations to charitable organizations. Clients can choose one or mix and match as desired, and the company will inform them of their privileges via email and MMS.',
    th: 'สิทธิพิเศษที่คัดสรรมาเป็นอย่างดี อาทิ ลิมูซีนรับ-ส่งสนามบิน ห้องรับรองพิเศษสนามบิน ห้องพักพร้อมอาหารเช้า \n' +
      'เครดิตรับประทานอาหาร ณ ห้องอาหารชั้นนํา โปรแกรมด้านสุขภาพ เซตของขวัญและช็อปปิ้ง รวมถึงเงินบริจาคเพื่อช่วยเหลือองค์กรการกุศล ท่านสามารถเลือกอย่างใดอย่างหนึ่ง หรือ Mix & Match ได้ตามต้องการ โดยบริษัทฯ จะแจ้งสิทธิ์ให้ท่านลูกค้าทราบทาง E-mail และ MMS'
  },
  items: [
    {
      id: 1,
      cover: imgCover01,
      url: 'https://wealthmanagement.kkpfg.com/PreLogin/ViewRowAnnoucement?cId=63904',
      title: {
        en: 'Flagship \nSeminars',
        th: 'Flagship \nSeminars',
      },
      items: [
        {
          id: 1,
          description: {
            en: 'The annual Flagship Seminar series features KKP YEAR AHEAD, Mid-Year Outlook, and Thai Equity. These insightful sessions delve into economic trends, investment strategies, and essential industry analysis, empowering you to make informed decisions.',
            th: 'งานสัมมนา Flagship ประจำปี ประกอบด้วย KKP YEAR AHEAD, Mid-Year Outlook และ Thai Equity ถ่ายทอดทิศทางเศรษฐกิจ ยุทธศาสตร์การลงทุน และการวิเคราะห์อุตสาหกรรมสำคัญ',
          },
        },
        // {
        //   id: 2,
        //   description: {
        //     en: 'THE YEAR AHEAD',
        //     th: 'THE YEAR AHEAD',
        //   },
        // },
        // {
        //   id: 3,
        //   description: {
        //     en: 'Mid Year Outlook',
        //     th: 'Mid Year Outlook',
        //   },
        // },
      ],
    },
    {
      id: 2,
      cover: imgCover02,
      url: 'https://wealthmanagement.kkpfg.com/PreLogin/ViewRowAnnoucement?cId=63905',
      title: {
        en: 'Advisory Events',
        th: 'Advisory Events',
      },
      items: [
        {
          id: 1,
          description: {
            en: 'Exclusive seminar events gathering top investment advice for both Thai and international markets. Gain insights from world-class experts and Thailand\'s leading economists.',
            th: 'กิจกรรมสัมมนาสุด Exclusive ที่รวบรวมคำแนะนำด้านการลงทุน ทั้งการลงทุนในไทย และต่างประเทศไว้ในที่เดียว โดยฉายภาพการลงทุน ผ่านมุมมองของกูรูระดับโลก รวมถึงนักเศรษฐศาสตร์ชั้นแนวหน้าของประเทศ',
          },
        },
      ],
    },
    {
      id: 3,
      cover: imgCover03,
      url: 'https://wealthmanagement.kkpfg.com/PreLogin/ViewRowAnnoucement?cId=63906',
      title: {
        en: 'Leisure Events',
        th: 'Leisure Events',
      },
      items: [
        {
          id: 1,
          description: {
            en: 'Kiatnakin Phatra Securities crafts an unforgettable experience through specially curated, exclusive activities in a variety of formats. Meticulously selected, these events ensure our valued clients are delighted and enjoy every moment.',
            th: 'สัมผัสประสบการณ์พิเศษจากกิจกรรมสุด Exclusive ในหลากหลายรูปแบบ ที่ บล.เกียรตินาคินภัทร ตั้งใจคัดสรรมาเพื่อให้ลูกค้าของเราได้อิ่มเอมกับความสุข และความสนุกสนานในทุกๆ โมเมนต์ตลอดทั้งปี',
          },
        },
        // {
        //   id: 2,
        //   description: {
        //     en: 'Health packages provide peace of mind and stress relief with health programs from top hospitals.',
        //     th: 'แพ็กเกจด้านสุขภาพอุ่นใจและคลายกังวลเรื่องสุขภาพด้วยโปรแกรมด้านสุขภาพจากโรงพยาบาลชั้นนำ',
        //   },
        // },
      ],
    },
    {
      id: 4,
      cover: imgCover04,
      url: 'https://wealthmanagement.kkpfg.com/PreLogin/ViewRowAnnoucement?cId=63902',
      title: {
        en: 'Lifestyle \nPrivileges',
        th: 'Lifestyle \nPrivileges',
      },
      items: [
        {
          id: 1,
          description: {
            en: 'Kiatnakin Phatra Securities offers lifestyle privileges to valued clients, providing them with a unique experience catering to their diverse lifestyles.',
            th: 'เอกสิทธิ์พิเศษด้านไลฟ์สไตล์ที่ บล.เกียรตินาคินภัทร มอบให้ลูกค้า เพื่อสร้างประสบการณ์พิเศษ ตอบโจทย์ชีวิตเหนือระดับ',
          },
        },
        // {
        //   id: 2,
        //   description: {
        //     en: 'เครดิตรับประทานอาหาร ณ \nห้องอาหารชั้นนํา',
        //     th: 'เครดิตรับประทานอาหาร ณ \nห้องอาหารชั้นนํา',
        //   },
        // },
        // {
        //   id: 3,
        //   description: {
        //     en: 'รถลีมูซีนรับ-ส่งสนามบิน',
        //     th: 'รถลีมูซีนรับ-ส่งสนามบิน',
        //   },
        // },
      ],
    },
    {
      id: 5,
      cover: imgCover05,
      url: 'https://wealthmanagement.kkpfg.com/PreLogin/ViewRowAnnoucement?cId=63903',
      title: {
        en: 'Seasonal Gifts',
        th: 'Seasonal Gifts',
      },
      items: [
        {
          id: 1,
          description: {
            en: 'Kiatnakin Phatra Securities gives special presents to clients for major festivals.',
            th: 'ของขวัญในเทศกาลสำคัญ ที บล.เกียรตินาคินภัทรคัดสรรอย่างประณีต เพื่อมอบให้ลูกค้าคนสำคัญ แทนคำขอบคุณ',
          },
        },
      ]
    }
  ],
  channels: [
    {
      id: 1,
      icon: imgCall,
      title: {
        en: 'Financial Solution Center 02-305-9559',
        th: 'Financial Solution Center 02-305-9559',
      },
    },
    {
      id: 2,
      icon: imgConsultant,
      title: {
        en: 'Contact your financial consultant',
        th: 'ที่ปรึกษาการลงทุนของท่าน',
      },
    },
    {
      id: 3,
      icon: imgMobile,
      title: {
        en: 'KKP MOBILE Application',
        th: 'KKP MOBILE Application',
      },
    },
  ]
}
