import React, {useState} from 'react';
import cx from 'classnames';

import tabs from '@/views/why-us/data/tabs';
import privilege02 from '@/views/why-us/data/privilege-02';
import StandardLayout from '@/views/common/layouts/StandardLayout';
import LinearWhiteBackground from "@/views/common/svgs/backgrounds/LinearWhiteBackground";
import WhyUsBenefitsHeadSection from '@/views/why-us/sections/WhyUsBenefitsHeadSection';
import WhyUsBenefitsPrivilegeSection from '@/views/why-us/sections/WhyUsBenefitsPrivilegeSection';
import ContactSection from '@/views/common/section/ContactSection';

import s from './styles.module.scss';


const WhyUsBenefitsPage = (props = {}) => {

  return (
    <StandardLayout>
      <div className={cx(
        'relative', 'flex', 'flex-col', 'justify-start', 'align-stretch', 'bg-linear-white',
        s.container
      )}>
        <LinearWhiteBackground/>
        <WhyUsBenefitsHeadSection tabs={tabs}/>
        <WhyUsBenefitsPrivilegeSection privilege={privilege02}/>
        <ContactSection/>
      </div>
    </StandardLayout>
  );

};

export default WhyUsBenefitsPage;