import React from 'react';
import cx from 'classnames';

import useLocale from '@/hooks/useLocale';

import s from './styles.module.scss';
import {splitLines} from '@/utils/html-utils';
const SolutionHeadSection = (props = {}) => {

  const {namespace, title, excerpt} = props;

  const {t} = useLocale(namespace);

  return (
    <div className={cx('relative', 'h-[480px]', s.container)}>
      <div className={cx(
        'absolute', 'left-0', 'top-0', 'w-full', 'h-full', 'px-4',
        'flex', 'flex-col', 'justify-center', 'items-center',
        'space-y-4',
      )}>
        <h1 className={cx('text-center', 'text-white', 'font-serif', 'font-bold')}>
          {splitLines(t(title))}
        </h1>
        <p className={cx(
          'w-full', 'px-8', 'sm:px-0', 'max-w-[720px]',
          'text-white', 'text-center', 'font-light', 'text-sm', 'sm:text-xl',
        )}>
          {t(excerpt)}
        </p>
      </div>
    </div>
  );

};

export default SolutionHeadSection;