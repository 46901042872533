import React from 'react';
import cx from 'classnames';


import ApplicationFeature from "../../components/ApplicationFeature";
import useLocale from '@/hooks/useLocale';

import s from './styles.module.scss';

// import imgMobile from '@/assets/images/page-why-us-digital-section-mobile/mobile.png';
import imgMobile from '@/assets/images/page-why-us-digital-section-mobile/mobile-app-hd.png';



const WhyUsDigitalMobileSection = (props = {}) => {

  const {t} = useLocale('page-why-us-digital-section-mobile');

  return (
    <section className={cx('relative', 'py-6', 'overflow-visible', s.container)}>
      <div className={cx(
        'relative', 'h-full',
        'content', 'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch',
        s.content
      )}>
        <div className={cx(
          'h-[360px]', 'sm:h-auto',
          'sm:flex-1', 'sm:flex-shrink-0',
        )}>
          <div className={cx(
            'absolute', 'top-0', 'sm:top-1/2', 'left-1/2', 'sm:left-0', 'transform', '-translate-y-1/2',
            'pointer-events-none', 'bg-contain', 'bg-no-repeat', 'bg-center', 'mt-4',
            s.mobile
          )} style={{backgroundImage: `url(${imgMobile})`}}/>
        </div>
        <div className={cx(
          'relative', 'flex-1', 'flex-shrink-0',
          'flex', 'flex-col', 'justify-start', 'items-stretch',
        )}>
          <h2 className={cx('text-primary-900')}>
            {t('title')}
          </h2>
          <p className={cx('mt-4', 'text-xl', 'text-legacy-purple')}>
          {t('description').split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index !== t('description').split('\n').length - 1 && <br />} {/* Add a line break except for the last line */}
            </React.Fragment>
          ))}
        </p>
          <ul className={cx('mt-6', 'space-y-4', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
            <li className={cx('relative')}>
              <ApplicationFeature title={t('feature01')}/>
            </li>
            <li className={cx('relative')}>
              <ApplicationFeature title={t('feature02')}/>
            </li>
            <li className={cx('relative')}>
              <ApplicationFeature title={t('feature03')}/>
            </li>
          </ul>
          <p className={cx('mt-4', 'text-xl', 'text-legacy-purple')}>
            {t('callToAction')}
          </p>
          <div className={cx('mt-4', 'space-x-4', 'flex', 'flex-row', 'justify-start', 'items-stretch')}>
            <a target="_blank" href="https://edgeinvest.cc/kkp-mobile/download"
               className={cx('btn', 'btn-submit', 'btn-long')}>
              <span className={cx('tracking-wide')}>Download</span>
            </a>
            <a target="_blank" className={cx('btn', 'btn-outlined', 'btn-outlined-contrast', 'btn-long')}
               href="https://kkpsecurities.co/KKPMobileMigration">
              <span className={cx('tracking-wide')}>Learn More</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );

};

export default WhyUsDigitalMobileSection;