import React, { useState,useEffect  } from 'react';
import cx from 'classnames';

import {splitLines} from '@/utils/html-utils';
import useLocale from '@/hooks/useLocale';

import s from './styles.module.scss';
import {
	apisNewsLetter
} from '@/apis/NewsLetter/index';


const NewsLetterSection = (props = {}) => {

  const {t} = useLocale('section-newsletter');
  const [email, setEmail] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch IP address when the component mounts
    fetch('https://api64.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        setIpAddress(data.ip);
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });
  }, []); // Run once on component mount


  const handleApiCall = async () => {
    // Perform your API call logic here
    // Include the email value in the API call
    setIsLoading(true);
    // const headers = {'Content-Type':'application/json',
    //                 'Access-Control-Allow-Origin':'*',
    //                 'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS'}
    // fetch(`${process.env.REACT_APP_CRATE_NEWS_LETTER}`, {
    //   method: 'POST', // or 'GET', 'PUT', etc.
    //   headers: headers,
    //   body: JSON.stringify({ email,ipAddress }),
    // })
    //   .then(response => response.text())
    //   .then(data => {
    //     // Handle the API response data
    //     console.log(data);
    //     if (data === "Success") {
    //       // Handle success case
    //       console.log("API call was successful");
    //       setEmail('');
    //   } else {
    //       // Handle other cases
    //       console.log("API call was not successful");
    //   }
    //   })
    //   .catch(error => {
    //     // Handle errors
    //     console.error('Error:', error);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
    const result = await apisNewsLetter.createNewsLetter(email,ipAddress)
    const { apiData } = result.data;

    if (apiData === "Success") {
      console.log("API call was successful");
      setEmail('');
      setIsLoading(false);
    } else {
      console.log("API call was not successful");
    }

  };
  
  const handleInput = (event) => {
    const inputValue = event.target.value;

    // Regular expression to allow only valid email characters
    const validRegex = /^[a-zA-Z0-9._ก-๙-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}!,() $/;
    
    // Check if the input value matches the valid email pattern
    if (!validRegex.test(inputValue)) {
      // If not valid, replace the input value without special characters
      const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9._@ก-๙-]/g, '');
      event.target.value = sanitizedValue;
    }
  };

 
  

  return (
    <section className={cx('relative', 'py-12', 'bg-light-gradient', s.container)}>
      <div className={cx(
        'content', 'space-y-6', 'sm:space-y-0', 'sm:space-x-8',
        'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch', 'sm:items-center',
      )}>
        <div className={cx(
          'flex-1', 'px-0', 'sm:px-12', 'space-y-2',
          'flex', 'flex-col', 'justify-start', 'items-stretch', 'sm:items-start',
        )}>
          <h3 className={cx('text-primary-900', 'text-center', 'sm:text-left')}>
            Unlock the secrets of investing
          </h3>
          <p className={cx('text-legacy-purple', 'text-center', 'sm:text-left')}>
            {splitLines(t('excerpt'))}
          </p>
        </div>
        <div className={cx('flex-1', 'flex', 'flex-row', 'justify-start', 'align-stretch')}>
          <input type="email" className={cx('flex-1', s.email)} placeholder="Email Address"     value={email}onChange={(e) => setEmail(e.target.value)} />
          <button onClick={handleApiCall}  disabled={isLoading} className={cx('btn', 'btn-submit', 'btn-rounded-right', 'btn-compact-long')}>
            {/* <span className={cx('tracking-wider')}>Sign up</span> */}
            <span className={cx('tracking-wider')}>{isLoading ? 'Signing up...' : 'Sign up'}</span>
          </button>
        </div>
      </div>
    </section>
  );

};

export default NewsLetterSection;