export default {
  motivations: [
    {
      id: 1,
      title: 'motivations.motivation01.title',
      description: 'motivations.motivation01.description',
    },
    {
      id: 2,
      title: 'motivations.motivation02.title',
      description: 'motivations.motivation02.description',
    },
    {
      id: 3,
      title: 'motivations.motivation03.title',
      description: 'motivations.motivation03.description',
    },
  ],
  prospectuses: [
    {
      id: 1,
      title: 'prospectuses.prospectus01.title',
      description: 'prospectuses.prospectus01.description',
    },
    {
      id: 2,
      title: 'prospectuses.prospectus02.title',
      description: 'prospectuses.prospectus02.description',
    },
    {
      id: 3,
      title: 'prospectuses.prospectus03.title',
      description: 'prospectuses.prospectus03.description',
    },
    {
      id: 4,
      title: 'prospectuses.prospectus04.title',
      description: 'prospectuses.prospectus04.description',
    },
  ],
  investors: [
    {
      id: 1,
      title: 'investors.investor01',
    },
    {
      id: 2,
      title: 'investors.investor02',
    },
  ]
};