import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const BannerCard = ({ banner }) => {
  return (
    <div
      className={cx(
        'w-full',
        'p-6',
        'box-border',
        'aspect-square',
        'bg-cover',
        'bg-no-repeat',
        'bg-center',
        'h-[310px]'
      )}
      style={{ backgroundImage: `url(${banner})` }}
    />
  );
};

BannerCard.propTypes = {
  banner: PropTypes.string.isRequired,
};

export default BannerCard;
