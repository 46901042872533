import React, {useEffect, useRef} from 'react';
import cx from 'classnames';
import gsap from "gsap";

import useLocale from '@/hooks/useLocale';
import {splitLines} from '@/utils/html-utils';

import s from './styles.module.scss';

const GoalQuestionSection = (props = {}) => {

  const containerRef = useRef(undefined);
  const triggerRef = useRef(undefined);

  const {locale, t} = useLocale('page-goal-section-question');

  useEffect(() => {

    const ctx = gsap.context(() => {
      gsap.fromTo(
        containerRef.current,
        {
          opacity: 0,
          translateY: '50%',
        },
        {
          opacity: 1,
          translateY: '0',
          scrollTrigger: {
            trigger: triggerRef.current,
            start: "top center",
            end: "bottom center",
            scrub: 1,
          }
        },
      );
    });

    return () => ctx.revert();
  });

  return (
    <section className={cx('relative', 'pt-[120px]', s.container)}>
      <div ref={containerRef} className={cx('content', 'flex', 'flex-col', 'justify-start', 'items-center')}>
        <div className={cx('flex', 'flex-col', 'justify-start', 'items-center')}>
          <h2 className={cx('text-legacy-purple')}>
            Question to ask yourself
            <hr className={cx('self-stretch', 'my-6', 'border-0')}/>
          </h2>
        </div>
        <div className={cx('space-y-4', 'flex', 'flex-col', 'justify-start', 'items-center', 'w-full', 'max-w-[732px]')}>
          <h1 className={cx(
            'text-legacy-purple', 'text-center',
            {'font-sans': locale === 'th', 'font-semibold': locale === 'th'}
          )}>
            {splitLines(t('title'))}
          </h1>
          <p className={cx('text-xl', 'text-legacy-purple', 'text-center')}>
            {splitLines(t('description'))}
          </p>
        </div>
        <hr className={cx('self-stretch', 'mt-[72px]', 'mb-4', 'border-0')}/>
      </div>
      <div ref={triggerRef} className={cx('absolute', '-top-[200px]', 'left-0', 'w-full', 'h-full')}/>
    </section>
  );

};

export default GoalQuestionSection;