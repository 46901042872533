import React, {Suspense} from 'react';
import {Link} from "react-router-dom";
import cx from 'classnames';

import {useIsScreenSm} from '@/providers/ScreenSizeProvider';
import useLocale from "@/hooks/useLocale";
import {splitLines} from "@/utils/html-utils";

import s from './styles.module.scss';

import imgButton from '@/assets/images/page-home-section-goals/discover-button.svg';


const GoalEntry = (props = {}) => {

  const {goal = {}} = props;
  const {title, excerpt, url} = goal;

  const isScreenSm = useIsScreenSm();

  const {locale} = useLocale('');

  return (
    <div className={cx(
      'goal', 'flex-1', 'p-6', 'space-y-4', 'cursor-pointer',
      'flex', 'flex-col', 'justify-start', 'items-stretch',
      'w-full', 'max-w-none', 'sm:max-w-[33.2727%]',
      'bg-center', 'bg-no-repeat', 'bg-expanded',
      'transition-all', 'ease-in-out', 'duration-500',
      s.container
    )}>
      <div className={cx(
        'space-y-2',
        'flex', 'flex-col', 'justify-start', 'items-stretch'
      )}>
        <h3 className={cx('text-legacy-purple', 'leading-[1.2]')}>
          {isScreenSm ? title : splitLines(title)}
        </h3>
        <hr className={cx('flex-shrink-0')}/>
        <h4 className={cx('text-legacy-purple', 'text-xl', 'sm:text-sm-s4', 'font-normal')}>
          {splitLines(excerpt)}
        </h4>
      </div>
      <Link to={`/${locale}${url}`} className={cx(
        'self-start', 'bg-white', 'btn', 'btn-outlined', 'btn-outlined-light', 'btn-sm'
      )}>
        <span className={cx('leading-[1]', 'tracking-wide')}>Discover More</span>
      </Link>
    </div>
  );

};

export default GoalEntry;