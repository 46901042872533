import React, {useMemo} from 'react';
import cx from 'classnames';

import tabs from '@/views/legal/data/tabs';
import useLocale from '@/hooks/useLocale';
import StandardLayout from '@/views/common/layouts/StandardLayout';
import BannerTitleSection from '@/views/common/section/BannerTitleSection';
import TabList from '@/views/common/components/TabList';

import s from './styles.module.scss';

import imgBanner from '@/assets/images/page-legal/banner.png';


const LegalInfoPage = (props = {}) => {

  const {locale} = useLocale('');

  const localisedTabs = useMemo(() => {
    return tabs.map(tab => {
      let {url = '', ...rest} = tab;
      if (url.indexOf('https://') === 0) {
        url = url.replace('{{locale}}', locale);
      } else {
        url = `/${locale}${url}`;
      }
      return {...rest, url};
    });
  }, [locale]);


  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch', s.container)}>
        <BannerTitleSection banner={imgBanner} title="Legal Info"/>
        <div className={cx('py-6', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
          <TabList items={localisedTabs} selectedIndex={1}/>
        </div>
        <section className={cx('relative', 'pt-[72px]', 'pb-[168px]')}>
          <div className={cx('content', 'text-primary-900', 'font-light', 'space-y-5', 'text-xl', s.content)}>
            <p>
              By accessing this website and any pages thereof, you agree to be bound by all terms, conditions and
              notices stated herein.
            </p>
            <p>
              KKP Capital Public Company Limited (“Company”) and any of its affiliates, subsidiaries and associated
              companies do not warrant the accuracy, completeness, quality, adequacy or content of any information on
              this website. Such information is provided on an “as is” basis without express or implied warranty or
              affirmation of condition of any kind, including without limitation, the implied warranties of
              merchantability, suitability for a particular purpose, or non-infringement of third parties rights.
              Availability of date and services on this website are subject to change without prior notice. Neither the
              Company, nor any of the Company’s affiliates, subsidiaries, associated companies and their directors,
              officers or employees shall have any responsibility for any losses or damages, including, without
              limitation, direct, indirect, special, incidental, consequential or punitive damages, resulting from the
              use or inability to use this website or the contents contained herein by any party, even if the Company is
              advised of the possibility of such damages.
            </p>
            <p>
              The information on this website does not constitute an offer or solicitation by the Company, any of its
              affiliates, or associated companies to buy or sell any securities or other financial instruments or
              provide any investment advice. The Company shall have no liability arising from the investment decisions
              made based on the information provided on this website.
            </p>
            <p>
              The investments, products and services described on this website are available only in jurisdictions where
              the Company may legally offer them for sale.

            </p>
            <p>
              Links to other sites from this website are for information only and the Company does not warrant the
              accuracy, completeness, quality, adequacy or content of any information on such sites. The Company accepts
              no responsibility arising from access to, or the material on, any site which is linked from or to this
              website.
            </p>
            <p>
              The material and contents contained on this website are owned by the Company. You may not deal with any
              copyright, trademarks or other intellectual property rights in the material on this site without the
              Company’s permission.
            </p>
            <p>
              <b>Mutual Fund Disclaimer</b>
            </p>
            <p>
              Copyright 2020 Kiatnakin Phatra Securities Public Company Limited (“Company”). All rights reserved. Any
              unauthorized use or disclosure is prohibited. The information herein was obtained from various sources.
              The
              Company does not guarantee its accuracy or completeness.
            </p>
            <p>
              Neither the information nor any opinion expressed constitutes an offer, or an invitation to make an offer,
              to buy or sell mutual fund.
            </p>
            <p>
              This report / information is prepared for general circulation and is circulated for general information
              only. It does not have regard to the specific investment objectives, financial situation and the
              particular needs of any specific person who may receive this report / information. Information and
              opinions expressed
              in this report / information is not prepared by an in-depth study and/or may not be continually updated.
              It is prepared based on available information which may not be complete or up-to-date. There may be
              changes which do not require prior notice. Investors should understand that statements regarding future
              prospects may not be realized. Investors should note that income from such mutual fund, if any, may
              fluctuate and that each mutual fund’s price or value may rise or fall. Accordingly, investors may receive
              back less than originally invested. Past performance is not necessarily a guide to future performance.
              Investors should exercise due care before making investment.
            </p>
          </div>
        </section>
      </div>
    </StandardLayout>
  )
    ;

};

export default LegalInfoPage;