import * as yup from 'yup';

const schema = yup.object().shape({
  firstName: yup.string()
    .required()
    .max(128),
  lastName: yup.string()
    .required()
    .max(128),
  phoneNumber: yup.string()
    .required()
    .max(10)
    .matches(/^[0-9]{10}$/),
  email: yup.string()
    .required()
    .max(64)
    .email(),
}).required();

export default schema;
