import React from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';

import useLocale from '@/hooks/useLocale';
import {splitLines} from '@/utils/html-utils';

import s from './styles.module.scss';

import imgLightBackground from '@/assets/images/page-solution-section-approach-link-grid/light-background.png';

export const LINK_CARD_THEME_LIGHT = 'light';
export const LINK_CARD_THEME_GREY = 'grey';
export const LINK_CARD_THEME_PURPLE = 'purple';
export const LINK_CARD_THEME_DARK = 'dark';


const LinkCard = (props = {}) => {

  const {theme = LINK_CARD_THEME_LIGHT, title, description, url} = props;

  const {locale} = useLocale('');

  return (
    <Link to={`/${locale}${url}`}>
      <div className={cx(
        'w-full', 'space-y-8', 'p-6', 'box-border', 'aspect-square',
        'flex', 'flex-col', 'justify-start', 'items-stretch',
        'bg-expanded', 'bg-no-repeat', 'bg-center',
        {'bg-primary-100': theme === LINK_CARD_THEME_GREY},
        {'bg-primary-900': theme === LINK_CARD_THEME_DARK},
        {'bg-legacy-purple': theme === LINK_CARD_THEME_PURPLE},
        {'text-legacy-purple': theme === LINK_CARD_THEME_LIGHT},
        {'text-primary-900': theme === LINK_CARD_THEME_GREY},
        {'text-white': theme === LINK_CARD_THEME_DARK},
        {'text-primary-100': theme === LINK_CARD_THEME_PURPLE},
        'h-[310px]',
        s.container
      )} style={{backgroundImage: theme !== LINK_CARD_THEME_LIGHT ? 'none' : `url(${imgLightBackground})`}}>
        <div className={cx(
          'flex-1', 'flex-shrink-0', 'font-normal',
          'flex', 'flex-col', 'justify-center', 'items-start',
          'space-y-4',
        )}>
          <h2>{splitLines(title)}</h2>
        </div>
        <div className={cx('block', 'text-xl')}>
          {splitLines(description)}
        </div>
      </div>
    </Link>
  );

};

export default LinkCard;