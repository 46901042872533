import React, {useMemo} from 'react';
import cx from 'classnames';

import {useIsScreenSm} from '@/providers/ScreenSizeProvider';
import useLocale from "../../../../../../hooks/useLocale";
import TabList, {TAB_MODE_LIGHT, TAB_SIZE_SMALL} from "../../../../../common/components/TabList";

import s from './styles.module.scss';

import imgBanner from '@/assets/images/page-why-us-expertise-section-head/banner.png';
import imgMobileBanner from '@/assets/images/page-why-us-expertise-section-head/banner-mobile.png';
import imgPhilosophy from '@/assets/images/page-why-us-expertise-section-head/logo-philosophy.png';


const WhyUsExpertiseHeadSection = (props = {}) => {

  const {tabs = []} = props;

  const isScreenSm = useIsScreenSm();

  const {t, locale} = useLocale('page-why-us-expertise-section-head');

  const localizedTabs = useMemo(() => {
    return tabs.map(tab => {
      const {url} = tab;
      return {...tab, url: `/${locale}${url}`}
    });
  }, [tabs, locale]);

  return (
    <>
      <section className={cx(
        'relative', 'aspect-square', 'sm:aspect-title-banner', 'sm:max-h-[480px]',
        'pt-8', 'sm:pt-[48px]', 'md:pt-[60px]', 'lg:pt-[100px]',
        'bg-no-repeat', 'sm:bg-left-bottom', 'bg-cover',
        s.container
      )} style={{backgroundImage: `url(${isScreenSm ? imgMobileBanner : imgBanner})`}}>
        <div className={cx(
          'content', 'h-full', 'space-y-12', 'sm:space-y-6', 'md:space-y-8', 'lg:space-y-12',
          'flex', 'flex-col', 'justify-start', 'items-stretch'
        )}>
          <TabList size={TAB_SIZE_SMALL} mode={TAB_MODE_LIGHT} items={localizedTabs} selectedIndex={0}/>
          <div className={cx(
            'space-y-2', 'sm:space-y-0',
            'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-center'
          )}>
            <div className={cx(
              'relative', 'flex-1', 'flex-shrink-0',
              'flex', 'flex-col', 'justify-center', 'items-center',
            )}>
              <img className={cx('w-[272px]', 'sm:w-full')} alt="Philosophy" src={imgPhilosophy}/>
            </div>
            <h1 className={cx('flex-1', 'flex-shrink-0', 'text-white', 'text-right', s.title)}>
              Our wealth and asset
              management expertise.
            </h1>
          </div>
        </div>
      </section>
      <section className={cx('relative')}>
        <div className={cx('content', 'text-center', 'text-primary-900')}>
          <p className={cx('py-9')}>
            {t('excerpt')}
          </p>
        </div>
      </section>
    </>
  )

};

export default WhyUsExpertiseHeadSection;