import React, {useCallback, useMemo} from 'react';
import cx from 'classnames';
import {observer} from 'mobx-react';
import {Trans} from 'react-i18next';

import {CONTACT_MODE_MEMBER, CONTACT_MODE_NON_MEMBER} from '@/views/contact/stores/contact-store';
import {useContactStore} from '@/views/contact/stores/contact-context';
import useLocale from '@/hooks/useLocale';
import ContactLayout from '@/views/contact/layouts/ContactLayout';

import s from './styles.module.scss';

import imgWelcome from '@/assets/images/dialog-contact-section-member-screening/welcome.svg';


const MemberScreeningSection = observer((props = {}) => {

  const contactStore = useContactStore();

  const {t, locale} = useLocale('dialog-contact');

  const onClickNonMember = useCallback(() => {
    contactStore.setMode(CONTACT_MODE_NON_MEMBER);
    setTimeout(() => {
      contactStore.next();
    }, 0);
  }, [contactStore]);

  const onClickMember = useCallback(() => {
    contactStore.setMode(CONTACT_MODE_MEMBER);
    setTimeout(() => {
      contactStore.next();
    }, 0);
  }, [contactStore]);

  const footerComponent = useMemo(() => {
    return (
      <div className={cx('flex', 'flex-row', 'justify-start', 'items-stretch', s.footer)}>
        <button className={cx('flex-1', 'btn', 'btn-bright', 'btn-bright-light', 'btn-lg')}
                onClick={onClickNonMember}>
          <span className={cx('leading-[1.2]')}>{t('no')}</span>
        </button>
        <div className={cx('w-px', 'mx-4', s.divider)}/>
        <button className={cx('flex-1', 'btn', 'btn-bright', 'btn-bright-light', 'btn-lg')}
                onClick={onClickMember}>
          <span className={cx('leading-[1.2]')}>{t('yes')}</span>
        </button>
      </div>
    );
  }, [t, onClickNonMember, onClickMember]);

  return (
    <ContactLayout footerComponent={footerComponent}>
      <div className={cx('flex-1', 'flex', 'flex-col', 'justify-center', 'items-center', s.container)}>
        <div className={cx(
          '-mt-[112px]', 'px-4', 'sm:px-0', 'sm:mt-0', 'flex-1',
          'flex', 'flex-col', 'justify-center', 'items-center'
        )}>
          <img alt="Welcome" src={imgWelcome}/>
          <div className={cx('mt-10', 'flex', 'flex-col', 'justify-start', 'items-center')}>
            <hr className={cx('upper', 'border-0', 'h-px', 'w-full', 'max-w-[100px]')}/>
            <div className={cx('h-4')}/>
            <p className={cx('text-center', 'text-2xl', 'font-medium', 'text-legacy-purple', 'leading-[1.2]')}>
              <Trans t={t} i18nKey="memberScreening.title">
                {locale === 'th' ? <br/> : null}
                <span className={cx('font-serif', 'font-bold')}></span>
              </Trans>
            </p>
            <div className={cx('h-4')}/>
            <hr className={cx('lower', 'border-0', 'h-px', 'w-full', 'sm:max-w-[320px]')}/>
          </div>
        </div>
      </div>
    </ContactLayout>
  );

});

export default MemberScreeningSection;