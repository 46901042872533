import React from 'react';
import cx from 'classnames';

import {splitLines} from "../../../../utils/html-utils";

import s from './styles.module.scss';


const GoalHeadSection = (props = {}) => {

  const {title = '', excerpt = '', banner} = props;

  return (
    <section className={cx(
      'relative', 'h-[480px]', 'bg-legacy-purple',
      'flex', 'flex-row', 'justify-start', 'items-stretch'
    )}>
      <div className={cx('sm:content', 'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch')}>
        <div className={cx(
          'w-full', 'space-y-6', 'px-4', 'sm:px-0', 'py-12', 'sm:py-0',
          'flex-1', 'flex', 'flex-col', 'justify-center', 'items-center'
        )}>
          <h1 className={cx('block', 'text-white')}>{title}</h1>
          <span className={cx('font-light', 'text-white', 'text-center', 'text-xl')}>{splitLines(excerpt)}</span>
        </div>
        <div className={cx('hidden', 'sm:block', 'sm:flex-1')}/>
        <img alt="banner" src={banner} className={cx('block', 'sm:hidden', 'w-full', 'h-auto')}/>
      </div>
      <div className={cx(
        'absolute', 'top-0', 'left-0', 'w-full', 'h-full',
        'hidden', 'sm:flex', 'flex-row', 'justify-start', 'items-stretch',
      )}>
        <div className={cx('flex-1')}/>
        <div className={cx('flex-1', 'bg-no-repeat', 'bg-cover', 'bg-left', 'bg-top')}
             style={{backgroundImage: `url(${banner})`}}/>
      </div>
    </section>
  );

};

export default GoalHeadSection;