import React from 'react';
import cx from 'classnames';
import s from './styles.module.scss';
import { splitLines,formatDescription } from '@/utils/html-utils';

const ConsultingCard = ({ title, description }) => {
 
  return (
    <div className={cx(
      'box-border', 'w-full', 'h-full', 'flex-shrink-0', 'p-3', 'space-y-4',
      'flex', 'flex-col', 'justify-start', 'align-stretch', 'rounded-lg',
      s.container
    )}>
      {title && (
        <div className={cx(
          'text-legacy-purple', 'text-light', 'text-xl', 
        )}>
          {splitLines(title)}
        </div>
      )}
      {title && description && (
        <div className={cx('h-2')} />
      )}
      {description && (
        <div className={cx(
          'text-legacy-purple', 'text-light', 'text-xl',
        )}>
          {description.map((item, index) => (
            <div key={index} dangerouslySetInnerHTML={{ __html: formatDescription(item) }} />
          ))}
        </div>
      )}
    </div>
    
  );
};

export default ConsultingCard;
