import React from 'react';

import useLocale from "@/hooks/useLocale";
import GoalDoubleColumnSection, {GOAL_DOUBLE_SECTION_MODE_RTL,} from '@/views/goal/sections/GoalDoubleColumnSection';

import imgBanner from '@/assets/images/page-goal-accumulation-section-capital-preservation/banner.png';


const CapitalPreservationSection = (props = {}) => {

  const {relatedProducts} = props;

  const {t} = useLocale('page-goal-accumulation-section-capital-preservation');

  return (
    <GoalDoubleColumnSection mode={GOAL_DOUBLE_SECTION_MODE_RTL}
                             banner={imgBanner} title={t('title')} excerpt={t('excerpt')}
                             description={t('description')} products={relatedProducts}/>
  )

};

export default CapitalPreservationSection;