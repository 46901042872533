import React, {useMemo} from 'react';
import cx from 'classnames';

import tabs from '@/views/legal/data/tabs';
import useLocale from '@/hooks/useLocale';
import TabList from '@/views/common/components/TabList';
import StandardLayout from '@/views/common/layouts/StandardLayout';
import BannerTitleSection from '@/views/common/section/BannerTitleSection';

import s from './styles.module.scss';

import imgBanner from '@/assets/images/page-legal/banner.png';


const PrivacyStatementPage = (props = {}) => {

  const {locale} = useLocale('');

  const localisedTabs = useMemo(() => {
    return tabs.map(tab => {
      let {url = '', ...rest} = tab;
      if (url.indexOf('https://') === 0) {
        url = url.replace('{{locale}}', locale);
      } else {
        url = `/${locale}${url}`;
      }
      return {...rest, url};
    });
  }, [locale]);


  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch', s.container)}>
        <BannerTitleSection banner={imgBanner} title="Privacy Statement"/>
        <div className={cx('py-6', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
          <TabList items={localisedTabs} selectedIndex={2}/>
        </div>
        <section className={cx('relative', 'pt-[72px]', 'pb-[168px]')}>
          <div className={cx('content', 'text-primary-900', 'font-light', 'space-y-5', 'text-xl', s.content)}>
            <p>
              KKP Capital Public Company Limited (“Company”) is committed to protect privacy of your personal
              information. Personal information is information about you that is personally identifiable like your name,
              address, email address, phone number, age, gender, preferences, interests and that is not otherwise
              publicly available.
            </p>
            <p>
              The Company, any of the Company’s affiliates, subsidiaries and associated companies, and our operational
              service partner may use your personal information for the following general purposes: to customize the
              advertising and content on this website, fulfill you requests for products and services, improve our
              services, conduct research and provide anonymous reporting for internal and external clients. The Company
              does not rent, sell or share your personal information with other people or any company which is not the
              Company’s affiliates, subsidiaries and associated companies except for the above purposes or required by
              laws.
            </p>
            <p>
              The Company will use variety of security technologies and procedures to help protect your personal
              information from unauthorized access, use or disclosure. Although the Company is committed to protect your
              privacy, the Company or the Company’s affiliates, subsidiaries and associated companies accepts no
              responsibility or liability from such unauthorized access, use or disclosure by the third parties.
            </p>
            <p>
              In case where the Company’s privacy policy is changed, the Company will notify you via an update notice on
              the Company’s home page. Should you have any further questions, please contact the Company’s Webmaster at
              clientservice@kkpfg.com
            </p>
          </div>
        </section>
      </div>
    </StandardLayout>
  )
    ;

};

export default PrivacyStatementPage;