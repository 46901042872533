import React from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';

import useLocale from '@/hooks/useLocale';

import s from './styles.module.scss';

import imgPhilosophy from '@/assets/images/common/logo-philosophy.png';
import imgLogo from '@/assets/images/common/logo-light.svg';


const FooterSection = (props = {}) => {

  const {locale} = useLocale('');

  return (
    <section className={cx('bg-black', 'pb-12', s.container)}>
      <div className={cx('content', 'flex', 'flex-col', 'justify-start', 'items-center')}>
        <Link to={`/${locale}`}>
          <img className={cx('block')} alt="Logo" src={imgPhilosophy}/>
        </Link>
        <div className={cx(
          'self-stretch', 'text-white',
          'space-y-8', 'sm:space-y-0', 'sm:space-x-8',
          'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch'
        )}>
          <div className={cx(
            'flex-1', 'space-y-6',
            'flex', 'flex-col', 'justify-start', 'items-center', 'sm:items-stretch'
          )}>
            <Link to={`/${locale}`} className={cx('link', 'link-white')}>
              <i className={cx('text-2xl', 'icon-logo')}/>
            </Link>
          </div>
          <div className={cx('flex-1', 'space-y-5', 'sm:space-y-6', 'text-center', 'sm:text-left')}>
            <h4 className={cx('text-2xl')}>Why Us</h4>
            <ul className={cx('font-light', 'text-grey-0.7', 'space-y-2')}>
              <li><Link className={cx('link', 'link-gray-70')} to={`/${locale}/why-us/expertise`}>Expertise</Link></li>
              <li><Link className={cx('link', 'link-gray-70')} to={`/${locale}/why-us/digital`}>Platforms</Link></li>
              <li>
                <Link className={cx('link', 'link-gray-70')} to={`/${locale}/why-us/lifestyle-privilege`}>
                  Ultimate Benefits
                </Link>
              </li>
              <li><Link className={cx('link', 'link-gray-70')} to={`/${locale}/why-us/services`}>Our Service</Link></li>
            </ul>
          </div>
          <div className={cx('flex-1', 'space-y-5', 'sm:space-y-6', 'text-center', 'sm:text-left')}>
            <h4 className={cx('text-2xl')}>Our Solutions</h4>
            <ul className={cx('font-light', 'space-y-2')}>
              <li>
                <Link className={cx('link', 'link-gray-70')} to={`/${locale}/solution/investing`}>Investing</Link>
              </li>
              <li>
                <Link className={cx('link', 'link-gray-70')} to={`/${locale}/solution/lending`}>Lending</Link></li>
              <li>
                <Link className={cx('link', 'link-gray-70')} to={`/${locale}/solution/planning`}>
                  Planning & Advice
                </Link>
              </li>
              <li>
                <Link className={cx('link', 'link-gray-70')} to={`/${locale}/solution/cio-office`}>
                  Advisory Approach
                </Link>
              </li>
            </ul>
          </div>
          <div className={cx('flex-1', 'space-y-5', 'sm:space-y-6', 'text-center', 'sm:text-left')}>
            <h4 className={cx('text-2xl')}>About us</h4>
            <ul className={cx('font-light', 'text-grey-0.7', 'space-y-2')}>
              <li>
                <a className={cx('link', 'link-gray-70')} target="_blank" href="https://career.kkpfg.com">Career</a>
              </li>
              <li>
                <a className={cx('link', 'link-gray-70')} target="_blank" href="https://ir.kkpfg.com">
                  Investor Relations
                </a>
              </li>
              <li>
                <a className={cx('link', 'link-gray-70')} target="_blank"
                   href="https://market.sec.or.th/LicenseCheck/CompanyDetail/0000000451">
                  Business License
                </a>
              </li>
              <li>
                <Link className={cx('link', 'link-gray-70')} to="#contact" state={{noScroll: true}}>Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className={cx(
          'my-6', 'py-6', 'sm:py-2', 'space-y-2', 'sm:space-y-0',
          'self-stretch', 'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-center',
          'border', 'border-t-primary-500', 'border-b-primary-500',
        )}>
          <a target="_blank" href="https://kkpfg.com">
            <span className={cx('text-grey-0.7')}>Explore KKPFG.com</span>
          </a>
          <div className={cx('hidden', 'sm:block', 'flex-1', 'min-w-6')}/>
          <ul className={cx(
            'flex', 'flex-row', 'justify-center', 'sm:justify-start', 'items-center', 'text-white',
            'flex-wrap', 'sm:flex-nowrap',
            'text-sm', 'sm:text-base',
            s.external
          )}>
            <li>
              <a className={cx('link', 'link-white')} target="_blank" href="https://kkpfg.com">
                Kiatnakin Phatra Financial Group
              </a>
            </li>
            <li>
              <a className={cx('link', 'link-white')} target="_blank" href="https://bank.kkpfg.com">
                Bank
              </a>
            </li>
            <li>
              <a className={cx('link', 'link-white')} target="_blank" href="https://am.kkpfg.com">
                Asset Management
              </a>
            </li>
            <li>
              <a className={cx('link', 'link-white')} target="_blank" href="https://edge.co.th">
                EDGE
              </a>
            </li>
            <li>
              <a className={cx('link', 'link-white')} target="_blank" href="https://dime.co.th">
                Dime
              </a>
            </li>
            <li>
              <a className={cx('link', 'link-white')} target="_blank" href="https://dw06.kkpfg.com">
                DW06
              </a>
            </li>
            <li>
              <a className={cx('link', 'link-white')} target="_blank" href="https://optimise.kkpfg.com">
                Optimise
              </a>
            </li>
            <li>
              <a className={cx('link', 'link-white')} target="_blank" href="https://advicecenter.kkpfg.com">
                Advice Center
              </a>
            </li>
          </ul>
        </div>
        <div className={cx(
          'self-stretch', 'space-y-6', 'sm:space-y-0',
          'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-center'
        )}>
          <span className={cx('text-gd-grey-light', 'font-light', 'text-center', 'sm:text-left')}>
            Copyright © 2024 Kiatnakin Phatra Securities Public Company Limited.
          </span>
          <div className={cx('hidden', 'sm:block', 'flex-1')}/>
          <ul className={cx(
            'relative', 'space-x-4', 'sm:space-x-8', 'text-white', 'font-light',
            'flex', 'flex-row', 'justify-center', 'sm:justify-start', 'items-center',
            'flex-wrap', 'sm:flex-nowrap', 'whitespace-nowrap',
            'leading-[1.1]', 'text-sm', 'sm:text-base',
            s['foot-menu']
          )}>
            <li>
              <Link className={cx('link', 'link-white')} to={`/${locale}/legal/announcement`}>
                Announcement
              </Link>
            </li>
            <li>
              <Link className={cx('link', 'link-white')} to={`/${locale}/legal/info`}>
                Legal Info
              </Link>
            </li>
            {/* <li>
              <Link className={cx('link', 'link-white')} to={`/${locale}/legal/privacy-statement`}>
                Privacy statement
              </Link>
            </li> */}
            <li>
              <a className={cx('link', 'link-white')} target="_blank"
                 href={`https://kkpfg.com/${locale}/dataprotection`}>
                Privacy Notice
              </a>
            </li>
            <li>
              <a className={cx('link', 'link-white')} target="_blank"
                 href={`https://kkpsecurities.co/OtherInformation`}>
                Other Information
              </a>
            </li>
          </ul>
        </div>
        {/* <div >
          <div className="flex flex-col sm:flex-row ">
            <div className="text-gd-grey-light font-light">
            <span className="mr-2">&#8226;</span>This website is the online investment portal of Kiatnakin Phatra 
              Securities Public Company Limited providing various services, 
              including but not limitation to online and mobile trading, planning 
              and analyzing tools, investment advisories, trustworthy research, 
              consolidated reports. All under a single platform.
            </div>
          </div>
        </div>
        <div >
          <div className="flex flex-col sm:flex-row ">
            <div className="text-gd-grey-light font-light">
              <span className="mr-2">&#8226;</span>Investor should carefully consider and fully understand the 
              product's features, conditions and investment risks before making 
              investment decision.
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );

};

export default FooterSection;