import React from 'react';
import cx from 'classnames';

import imgBanner from '@/assets/images/page-event-section-head/banner.png';


const EventHeadSection = (props = {}) => {

  return (
    <div className={cx('bg-primary-50')}>
      <section className={cx('relative')}>
        <div className={cx('w-full', 'aspect-event-banner', 'bg-bottom', 'bg-cover', 'bg-no-repeat')}
             style={{backgroundImage: `url(${imgBanner})`}}/>
      </section>
      <section className={cx('w-full', 'pt-[60px]', 'pb-[64px]')}>
        <div className={cx('content', 'flex', 'flex-horizontal', 'justify-start', 'items-stretch')}>
        </div>
      </section>
    </div>
  );

};

export default EventHeadSection;