import React from 'react';
import cx from 'classnames';

import {splitLines} from "../../../../../../utils/html-utils";
import imgCheck from '../../../../../../assets/images/page-why-us-digital/ic-check.svg';

import s from './styles.module.scss';


const ApplicationFeature = (props = {}) => {

  const {title, mode} = props;

  return (
    <div className={cx(
      'p-4', 'bg-light-gradient-4', 'w-full', 'space-x-4', 'rounded-lg',
      'flex', 'flex-row', 'justify-start', 'align-center',
      s.container,
      {[s.light]: mode === 'light'},
    )}>
      <img alt="Check" src={imgCheck}/>
      <span className={cx('text-legacy-purple', 'text-xl')}>{splitLines(title)}</span>
    </div>
  );

};

export default ApplicationFeature;