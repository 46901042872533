import React, {useMemo} from 'react';
import cx from 'classnames';

import {useIsScreenSm, useIsScreenXs} from '@/providers/ScreenSizeProvider';
import useLocale from '@/hooks/useLocale';
import TabList, {TAB_MODE_LIGHT, TAB_SIZE_SMALL} from "@/views/common/components/TabList";

import s from './styles.module.scss';

import imgBanner from '@/assets/images/page-why-us-digital-section-head/banner.png';
import imgMobileBanner from '@/assets/images/page-why-us-digital-section-head/banner-mobile.png';
import {splitLines} from '@/utils/html-utils';


const WhyUsDigitalHeadSection = (props = {}) => {

  const isScreenXs= useIsScreenXs();
  const isScreenSm = useIsScreenSm();

  const {tabs = []} = props;

  const {t, locale} = useLocale('page-why-us-digital-section-head');

  const localizedTabs = useMemo(() => {
    return tabs.map(tab => {
      const {url} = tab;
      return {...tab, url: `/${locale}${url}`}
    });
  }, [tabs, locale]);

  return (
    <section className={cx(
      'relative', 'aspect-square', 'sm:aspect-title-banner', 'sm:max-h-[480px]',
      'pt-8', 'sm:pt-[48px]', 'md:pt-[60px]', 'lg:pt-[100px]',
      'bg-no-repeat', 'bg-left-bottom', 'bg-cover',
      s.container
    )} style={{backgroundImage: `url(${isScreenSm ? imgMobileBanner : imgBanner})`}}>
      <div className={cx(
        'content', 'h-full', 'space-y-[48px]', 'xs:space-y-[72px]', 'sm:space-y-[48px]', 'md:space-y-[60px]', 'lg:space-y-[76px]',
        'flex', 'flex-col', 'justify-start', 'items-stretch'
      )}>
        <TabList size={TAB_SIZE_SMALL} mode={TAB_MODE_LIGHT} items={localizedTabs} selectedIndex={1}/>
        <div className={cx('space-y-4', 'sm:space-y-5', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
          <h1 className={cx('flex-shrink-0', 'text-white', 'text-center', s.title)}>
            Comprehensive Platform{' '}
            <br className={cx('hidden', 'md:inline')}/>
            for Everything You Need
          </h1>
          <h3 className={cx('text-white', 'text-center', 'font-sans', 'font-medium', 'text-xl', 'sm:text-2xl')}>
            {isScreenXs ? splitLines(t('excerpt')) : t('excerpt').replace('\n', '')}
          </h3>
        </div>
      </div>
    </section>
  )

};

export default WhyUsDigitalHeadSection;