import React from 'react';
import cx from 'classnames';
import s from './styles.module.scss';

const InsightDetailsBanner = ({ imageUrl = '', isLoading = true }) => {
  // Determine the background style based on the isLoading prop
  const backgroundStyle = isLoading
    ? { backgroundColor: '#EDEDF3' } // Background color when loading
    : { backgroundImage: `url(${imageUrl})` }; // Background image when not loading

  return (
    <section className={cx('relative', 'overflow-visible', s.container)}>
      <div
        className={cx(
          'bg-cover',
          'bg-center',
          'bg-no-repeat',
          'aspect-square',
          'sm:aspect-title-banner',
          '-mb-mobile-mid-banner',
          'sm:-mb-mid-banner'
        )}
        style={backgroundStyle}
      />
    </section>
  );
};

export default InsightDetailsBanner;
