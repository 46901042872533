import Api from "../api-core";

const getExperts = async ({
  limit = 100,
  offset = 1,
  id
}) => {
  try {
    var payload = {
      params: {
        limit,
        offset
      }
    }
		if(id) payload.params.id = id;
    const result = await Api().get(
      `${process.env.REACT_APP_WEALTH_BASE_API}/experts`,
      payload
    );
    return result.data;
  } catch (error) {
    throw new ("Error:", error)();
  }
};

export {
	getExperts
};
