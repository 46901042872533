import React, { useState } from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';

import Tag from "../Tag";
import {splitLines} from "@/utils/html-utils";
import useLocale from '@/hooks/useLocale';

import s from './styles.module.scss';
import { locals } from '@/utils/locales';


const ExpertDetails = (props = {}) => {

  const {expert = {}} = props;
  const {nameEn, nameTh, shortName, positionEn, positionTh, profileImage, experienceEn, experienceTh, tags} = expert;

  const {t,locale} = useLocale('page-experts-detail');
  const [isImgFail, setIsImgFail] = useState(false);

  const inputBoxes = [];
  if(tags){
    for (let i of tags) {
      inputBoxes.push(
      <Tag title={i.name} />
      );
    }
  }
  
  const onImgError = () => {
    if(profileImage && profileImage != ""){
      setIsImgFail(true)
    }
  }

  return (
    <section className={cx(
      'relative', 'py-9',
      'bg-bottom', 'bg-cover', 'bg-no-repeat',
      s.container
    )}>
      <div className={cx(
        'relative', 'content', 'mt-8', 'space-y-6', 'sm:space-y-0', 'sm:space-x-8',
        'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-start',
      )}>
        {
          profileImage && profileImage != "" && !isImgFail?
             <div className={cx(
              'flex-shrink-0',
              'bg-center', 'bg-cover', 'bg-no-repeat', 'bg-primary-200',
            )}>
              <img className={cx('item-start','flex','w-64')} src={profileImage} onError={onImgError}></img>
             </div>
          : 
          <div className={cx(
            'flex-shrink-0', 'aspect-square', 'w-64',
            'bg-center', 'bg-cover', 'bg-no-repeat', 'bg-primary-200', 'content-center'
          )}>
              <p className={cx('text-center', 'font-serif', 'font-bold', 'text-8xl', 'text-secondary-100')}>{shortName}</p>
          </div>
        }
        <div className={cx(
          'sm:px-6', 'pb-6',
          'flex', 'flex-col', 'justify-start', 'items-start'
        )}>
          <h2 className={cx('font-sans', 'font-semibold', 'text-primary-900')}>{locale === locals.th ? nameTh : nameEn}</h2>
          <span className={cx('mt-1', 'text-primary-900')}>{locale === locals.th ? positionTh : positionEn}</span>
          <div className={cx('flex', 'flex-wrap', 'gap-1.5', 'py-2', 'min-h-[35px]')}>
            {inputBoxes}
          </div>
          <div className={cx(
            'self-stretch', 'py-1', 'mb-2', 'mt-6',
            'text-primary-900', 'border-y', 'border-primary-100'
          )}>
            {t('experience')}
          </div>
          <p className={cx('pt-4', 'pb-6', 'text-primary-900')}>
            {locale === locals.th ? splitLines(experienceTh) : splitLines(experienceEn)}
          </p>
        </div>
      </div>
    </section>
  );

};

ExpertDetails.propTypes = {
  expert: PropTypes.shape({
    nameEn: PropTypes.string,
    name: PropTypes.object,
    description: PropTypes.object,
    expertise: PropTypes.object,
    experience: PropTypes.object,
  }),
};

export default ExpertDetails;