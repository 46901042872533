import React, {useMemo} from 'react';
import cx from 'classnames';

import gridRelatedProducts from '@/views/goal/pages/GoalAccumulationPage/data/grid-related-products';
import wealthAccumulationRelatedProducts
  from "@/views/goal/pages/GoalAccumulationPage/data/wealth-accumulation-related-products";
import capitalReserveRelatedProducts
  from "@/views/goal/pages/GoalAccumulationPage/data/capital-reserve-releated-products";
import useLocale from "@/hooks/useLocale";
import StandardLayout from "@/views/common/layouts/StandardLayout";
import BreadCrumbs from "@/views/common/components/BreadCrumbs";
import GoalAccumulationHeadSection from "@/views/goal/pages/GoalAccumulationPage/sections/GoalAccumulationHeadSection";
import GoalQuestionSection from "@/views/goal/sections/GoalQuestionSection";
import WealthAccumulationSection from "@/views/goal/pages/GoalAccumulationPage/sections/WealthAccumulationSection";
import CapitalPreservationSection from "@/views/goal/pages/GoalAccumulationPage/sections/CapitalPreservationSection";
import GoalHelpSection from '@/views/goal/sections/GoalHelpSection';
import GoalRelatedProductsGridSection from '@/views/goal/sections/GoalRelatedProductsGridSection';


const GoalAccumulationPage = (props = {}) => {

  const {t, locale} = useLocale('page-goal-accumulation');

  const breadcrumbsPaths = useMemo(() => {
    return [
      {id: 1, title: 'Home', url: `/${locale}`},
      {id: 2, title: 'What\'s your goal', url: ''},
      {id: 3, title: t('pageName'), url: ''},
    ];
  }, [t, locale]);

  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <BreadCrumbs paths={breadcrumbsPaths}/>
        <GoalAccumulationHeadSection/>
        <GoalQuestionSection/>
        <WealthAccumulationSection relatedProducts={wealthAccumulationRelatedProducts}/>
        <CapitalPreservationSection relatedProducts={capitalReserveRelatedProducts}/>
        <GoalHelpSection/>
        <GoalRelatedProductsGridSection namespace="solutions" relatedProducts={gridRelatedProducts}/>
      </div>
    </StandardLayout>
  );

};

export default GoalAccumulationPage;