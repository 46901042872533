import imgBanner02 from '@/assets/images/page-why-us-benefits/banner-02.png';
import imgMobileBanner02 from '@/assets/images/page-why-us-benefits/mobile-banner-02.png';
import imgCover01 from '@/assets/images/page-why-us-benefits/cover-01.png';
import imgCover02 from '@/assets/images/page-why-us-benefits/cover-02.png';
import imgCover03 from '@/assets/images/page-why-us-benefits/cover-03.png';
import imgCover04 from '@/assets/images/page-why-us-benefits/cover-04.png';
import imgMobile from '@/assets/images/page-why-us-benefits/ic-mobile.svg';

export default {
  id: 2,
  type: 'privilegeClub',
  url: 'https://wealthmanagement.kkpfg.com/PreLogin/ViewRowAnnoucement?cId=62977',
  banner: {
    mobile: imgMobileBanner02,
    desktop: imgBanner02,
  },
  title: {
    en: 'Lifestyle Privilege',
    th: 'Lifestyle Privilege',
  },
  summary: {
    en: '"KKPS Privileges" are special privileges that Kiatnakin Phatra Securities Public Company Limited (KKPS) offers to the important clients in order to provide them with a unique experience that caters to their diverse lifestyles. There are many categories, including travel, dining credits, wellness programs, as well as various activities and entertainment that have been selected to enhance the happiness of our clients. These privileges are divided into two types: "Lifestyle Privileges" and "Privilege Club."',
    th: 'KKPS Privileges เอกสิทธิ์พิเศษที่ บล.เกียรตินาคินภัทร มอบให้กับลูกค้าคนสําคัญ เพื่อสร้างประสบการณ์พิเศษ ที่ตอบโจทย์ไลฟ์สไตล์ที่หลากหลายของท่าน มีให้เลือกมากมายทั้งการเดินทางท่องเที่ยว มื้ออาหารในโรงแรม 5 ดาว โปรแกรมดูแลสุขภาพ ตลอดจนกิจกรรม และความบันเทิงอีกมากมายที่คัดสรรมาเป็นอย่างดี เพื่อเติมเต็มความสุขให้คนสําคัญของเราเอกสิทธิ์แบ่งเป็น Lifestyle Privileges และ Privilege Club',
  },
  description: {
    en: 'Exclusive privileges for all clients. Here\'s another benefit for all our clients: receive special products and services from various stores and get up to 30% discount from top service providers, including top-rated stores, hotels, restaurants, spas, and various brand products. Simply click to claim your privilege and view details on the KKP Securities Application.',
    th: 'สิทธิพิเศษสําหรับลูกค้า บล.เกียรตินาคินภัทร ทุกท่าน อีกหนึ่งสิทธิประโยชน์สําหรับลูกค้าทุกท่าน รับสินค้าและบริการพิเศษจากร้านค้า และส่วนลดสูงสุดถึง 30% จากสถานบริการ ร้านค้าชั้นนํา โรงแรมหรู ร้านอาหาร บริการสปา สถานเสริมความงาม และแบรนด์สินค้าต่างๆ ง่ายๆ เพียงกดรับสิทธิ์และดูรายละเอียดทาง KKP Securities Application',
  },
  items: [
    {
      id: 1,
      cover: imgCover01,
      title: {
        en: 'Travel & Hotel',
        th: 'Travel & Hotel',
      },
      items: [
        {
          id: 1,
          description: {
            en: 'Enjoy various discounts and promotions that will make your leisure time more worthwhile than before. These include discounted room rates and a limousine airport transfer service.',
            th: 'เพลิดเพลินกับส่วนลด และโปรโมชันหลากหลาย ที่จะทําให้ช่วงเวลาพักผ่อนของคุณคุ้มค่ากว่าเดิม อาทิ ส่วนลดห้องพัก ลิมูซีนรับ-ส่งสนามบิน',
          },
        },
      ],
    },
    {
      id: 2,
      cover: imgCover02,
      title: {
        en: 'Dining',
        th: 'Dining',
      },
      items: [
        {
          id: 1,
          description: {
            en: 'Savor a curated selection of menus, which includes complimentary snacks and beverages when you meet the required service criteria, or discounts on food at leading hotels and restaurants.',
            th: 'อิ่มอร่อยกับเมนูที่คัดสรรมาให้ท่าน อาทิ ขนมและ เครื่องดื่มฟรี เมื่อใช้บริการตามเกณฑ์ที่กําหนด หรือส่วนลดค่าอาหาร ในโรงแรมและร้านอาหารชั้นนำ',
          },
        },
      ],
    },
    {
      id: 3,
      cover: imgCover03,
      title: {
        en: 'Health\n& Beauty',
        th: 'Health\n& Beauty',
      },
      items: [
        {
          id: 1,
          description: {
            en: 'Unwind with our comprehensive health and wellness programs, complete with discounts on treatments and spa packages.',
            th: 'ผ่อนคลายกับโปรแกรมสุขภาพและความงาม พร้อมส่วนลดจากแพ็กเกจสปา ทรีตเมนต์ โปรแกรมด้านสุขภาพและความงามแบบครบวงจร',
          },
        },
      ],
    },
    {
      id: 4,
      cover: imgCover04,
      title: {
        en: 'Shopping',
        th: 'Shopping',
      },
      items: [
        {
          id: 1,
          description: {
            en: 'Experience even more fun shopping with instant discounts and exclusive gifts from top brands',
            th: 'สนุกกับการช็อปปิ้งยิ่งกว่าเดิม เมื่อซื้อสินค้าแบรนด์ดัง รับทันทีส่วนลดและของขวัญสุดพิเศษจากแบรนด์',
          },
        },
      ],
    },
  ],
  channels: [
    {
      id: 1,
      icon: imgMobile,
      title: {
        en: 'KKP MOBILE Application',
        th: 'KKP MOBILE Application',
      },
    },
  ]
}
