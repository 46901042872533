import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import cx from 'classnames';
import {observer} from 'mobx-react';
import {useContactStore} from '@/views/contact/stores/contact-context';
import useLocale from '@/hooks/useLocale';
import ContactLayout from '@/views/contact/layouts/ContactLayout';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import s from './styles.module.scss';


const NonMemberEnquirySection = observer((props = {}) => {
  const {t, locale} = useLocale('dialog-contact');

  const contactStore = useContactStore();
  const {visible, memberEnquirySchema} = contactStore || {};

  const formRef = useRef(undefined);
  const formMethods = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(memberEnquirySchema),
  });



  const {
    watch,
    reset,
    register,
    formState: {errors},
    setValue,
    clearErrors,
    handleSubmit,
  } = formMethods;

  useEffect(() => {
    const {current: form} = formRef;
    if (!form || !visible) return;
    clearErrors();
    reset();
    form.reset();
  }, [visible, reset, clearErrors]);

  const onClickNext = useCallback(() => {
    const {current: form} = formRef;
    form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }, [])

  const onSubmit = useCallback((value) => {
    contactStore.setNonMemberEnquiry(value);
    contactStore.next();
  }, [contactStore]);

  const footerComponent = useMemo(() => {
    return (
      <div className={cx('flex', 'flex-row', 'justify-start', 'items-stretch', s.footer)}>
        <button className={cx('flex-1', 'btn', 'btn-submit', 'btn-next', 'uppercase')} onClick={onClickNext}>
          <span className={cx('tracking-wider')}>
            Next Question
          </span>
        </button>
      </div>
    );
  }, [onClickNext]);

  const handleInput = (event) => {
    const inputValue = event.target.value;
    // Regular expression to allow only valid email characters
    const validRegex = /^[a-zA-Z0-9._ก-๙-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}!,() $/;

    // Check if the input value matches the valid email pattern
    if (!validRegex.test(inputValue)) {
      // If not valid, replace the input value without special characters
      const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9._@ก-๙-]/g, '');
      event.target.value = sanitizedValue;
    }
  };
 
  const validatePhoneNumber = (event) => {
    const inputValue = event.target.value;
    const phoneNumberRegex = /^\d{10}$/;

    if (!phoneNumberRegex.test(inputValue)) {
        // If not a valid phone number, replace the input value with only digits
        const sanitizedValue = inputValue.replace(/\D/g, ''); 
        event.target.value = sanitizedValue;
    }
};
  return (
    <ContactLayout footerComponent={footerComponent}>
      <div className={cx(
        'flex-1', 'px-4', 'sm:px-8',
        'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'sm:justify-center', 'items-stretch',
        s.container
      )}>
        <div className={cx(
          'pb-4', 'sm:py-6', 'box-border', 'flex-1', 'space-y-4', 'sm:space-y-6',
          'flex', 'flex-col', 'justify-center', 'items-center'
        )}>
          <h2 className={cx(
            'font-semibold', 'text-center', 'text-legacy-purple',
            {'font-sans': locale !== 'en', 'font-serif': locale === 'en'},
          )}>
            {t('memberEnquiry.title')}
          </h2>
          <hr className={cx('hidden', 'sm:block')}/>
        </div>
        <hr className={cx('block', 'sm:hidden')}/>
        <div className={cx('hidden', 'sm:block', 'w-8')}/>
        <form ref={formRef} className={cx('block', 'relative', 'box-border', 'py-4', 'sm:py-6', 'sm:flex-1')}
              onSubmit={handleSubmit(onSubmit)}>
          <fieldset className={cx(
            'w-full', 'h-full', 'space-y-4',
            'flex', 'flex-col', 'justify-center', 'items-stretch'
          )}>
            <div className={cx('space-x-4', 'flex', 'flex-row', 'justify-start', 'items-stretch')}>
              <input type="text" className={cx('flex-1', {[s.error]: errors.firstName})}
                     placeholder={t('memberEnquiry.firstName.placeholder')}
                     maxLength={128}   {...register('firstName')}/>
            </div>
            <div className={cx('space-x-4', 'flex', 'flex-row', 'justify-start', 'items-stretch')}>
              <input type="text" className={cx('flex-1', {[s.error]: errors.lastName})}
                     placeholder={t('memberEnquiry.lastName.placeholder')}
                     maxLength={128}   {...register('lastName')}/>
            </div>
            <div className={cx('space-x-4', 'flex', 'flex-row', 'justify-start', 'items-stretch')}>
              <input type="email" className={cx('flex-1', {[s.error]: errors.email})}
                     placeholder={t('memberEnquiry.email.placeholder')}
                     maxLength={64}   {...register('email')}/>
            </div>
            <div className={cx('space-x-4', 'flex', 'flex-row', 'justify-start', 'items-stretch')}>
              <input type="tel" className={cx('flex-1', {[s.error]: errors.phoneNumber})}
                     placeholder={t('memberEnquiry.phoneNumber.placeholder')}
                     maxLength={10} onInput={validatePhoneNumber}  {...register('phoneNumber')}/>
            </div>
          </fieldset>
        </form>
      </div>
    </ContactLayout>
  );

});

export default NonMemberEnquirySection;