import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import useLocale from '@/hooks/useLocale';
import { getDateByLocale } from '@/utils/locales';
import s from './styles.module.scss';
import { locals } from '@/utils/locales';

const ArticleMetadata = ({ insight }) => {
  const { locale } = useLocale('');
  const { authors = [], guestAuthors = [], contentDate = '' } = insight || {};

  return (
    <div className={cx(
      'py-4', 'border-y', 'border-primary-50', 'gap-y-6',
      'space-y-6', 'sm:space-y-0', 'flex', 'flex-col',
      'items-start', 'sm:items-center', s.container
    )}>
      {authors.length > 0 && (
        <div className={cx('flex', 'flex-row', 'items-start', 'gap-x-4', 'gap-y-2', 'w-full')}>
          <div className={cx(
            'text-legacy-purple', 'text-lg'
          )}>
            {locale === locals.en ? "By" : "โดย"}
          </div>
          <div className={cx(
            'pl-4', 'border-l', 'border-primary-50',
            'flex', 'flex-col', 'sm:flex-row', 'flex-wrap', 'gap-x-6', 'gap-y-4',
            'sm:min-w-0' // Ensure flex items can shrink in row direction
          )}
          style={{ overflowWrap: 'anywhere' }}
          >
            {/* Render all authors */}
            {authors.map((author) => (
              <Link
                key={author.id}
                to={`/${locale}/experts/${author.id}`}
                className={cx(
                  'flex', 'flex-col', 'justify-start', 'items-start', 'text-wrap',
                  'min-w-fit' // Minimum width for each author item
                )}
              >
                <b className={cx('text-primary-900')}>
                  {locale === locals.en ? author.nameEn : author.nameTh}
                </b>
                <span className={cx('text-primary-400', 'text-xs')}>
                  {locale === locals.en ? author.positionEn : author.positionTh}
                </span>
              </Link>
            ))}

            {/* Render all guest authors */}
            {guestAuthors.map((guestAuthor) => (
              guestAuthor.urlLink ? (
                <a
                  key={guestAuthor.id}
                  href={guestAuthor.urlLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={cx(
                    'flex', 'flex-col', 'text-wrap',
                    'min-w-fit' // Minimum width for each guest author item
                  )}
                >
                  <b className={cx('text-primary-900')}>
                    {locale === locals.en ? guestAuthor.nameEn : guestAuthor.nameTh}
                  </b>
                  <span className={cx('text-primary-400', 'text-xs')}>
                    {locale === locals.en ? guestAuthor.positionEn : guestAuthor.positionTh}
                  </span>
                </a>
              ) : (
                <div
                  key={guestAuthor.id}
                  className={cx(
                    'flex', 'flex-col', 'justify-start', 'items-start',
                    'min-w-fit' // Minimum width for each guest author item
                  )}
                >
                  <b className={cx('text-primary-900')}>
                    {locale === locals.en ? guestAuthor.nameEn : guestAuthor.nameTh}
                  </b>
                  <span className={cx('text-primary-400', 'text-xs')}>
                    {locale === locals.en ? guestAuthor.positionEn : guestAuthor.positionTh}
                  </span>
                </div>
              )
            ))}
          </div>
        </div>
      )}
      <div className={cx('flex', 'flex-row', 'self-start', 'items-center', 'space-x-2', 'text-legacy-purple')}>
        <i className={cx('text-2xl', 'icon-clock', 'mt-[2px]')} />
        <span>{getDateByLocale(contentDate, locale)}</span>
      </div>
    </div>
  );
};

export default ArticleMetadata;
