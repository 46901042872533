export default {
  motivations: [], // No motivations data provided

  prospectuses: [
    {
      id: 1,
      title: 'prospectuses.prospectus01.title',
      description: 'prospectuses.prospectus01.description',
    },
    {
      id: 2,
      title: 'prospectuses.prospectus02.title',
      description: 'prospectuses.prospectus02.description',
    },
    {
      id: 3,
      title: 'prospectuses.prospectus03.title',
      description: 'prospectuses.prospectus03.description',
    },
    {
      id: 4,
      title: 'prospectuses.prospectus04.title',
      description: 'prospectuses.prospectus04.description',
    },
  ],

  investors: [
    {
      id: 1,
      title: 'investors.investor01',
    },
    {
      id: 2,
      title: 'investors.investor02',
    },
  ],

  consultings: {
    title: 'consultings.title',
    description: [
      'consultings.description01',
      'consultings.description02',
      'consultings.description03',
      'consultings.description04',
      'consultings.description05',
      'consultings.description06',
      'consultings.description07',
    ]
  }
};
