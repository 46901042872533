import React from 'react';
import cx from 'classnames';

import s from './styles.module.scss';
import useLocale from '@/hooks/useLocale';
import {Link} from 'react-router-dom';


const ProductEntry = (props = {}) => {

  const {product = {}} = props;

  const {t, locale} = useLocale('page-goal');

  return (
    <Link to={`/${locale}${product.url}`} className={cx(
      'space-8', 'h-full', 'w-full', 'box-border', 'p-6',
      'flex', 'flex-col', 'justify-start', 'items-stretch',
      s.container
    )}>
      <div className={cx('flex-1', 'flex-shrink-0', 'flex', 'flex-col', 'justify-center', 'items-start')}>
        <h3 className={cx('text-legacy-purple')}>{t(product.title)}</h3>
      </div>
      <div className={cx('flex-1', 'flex-shrink-0', 'flex', 'flex-col', 'justify-center', 'items-start')}>
        <p className={cx('text-legacy-purple', 'text-xl')}>
          {t(product.description)}
        </p>
      </div>
    </Link>
  );

};

export default ProductEntry;