import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";

import screens from '@/utils/screens';


export const ScreenSizeContext = createContext({});

export const ScreenSizeProvider = (props = {}) => {

  const {children} = props;

  const [screenSize, setScreenSize] = useState(undefined);

  const screenWidthMap = useMemo(() => {
    const map = new Map();
    const widths = Object.keys(screens);
    for (const width of widths) {
      map.set(screens[width], width);
    }
    return map;
  }, []);

  const screenWidths = useMemo(() => {
    return [...screenWidthMap.keys()].sort((a, b) => a - b);
  }, [screenWidthMap]);

  const measureSize = useCallback(() => {
    const {offsetWidth: width} = window.document.body;
    for (const screenWidth of screenWidths) {
      if (width < screenWidth) {
        setScreenSize(screenWidthMap.get(screenWidth));
        return;
      }
    }
    setScreenSize('xxl');
  }, [screenWidths, screenWidthMap]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    measureSize();
    let handler;
    window.addEventListener('resize', handler = () => measureSize());
    return () => {
      if (!handler) return;
      window.removeEventListener('resize', handler);
    };
  }, [measureSize])

  useEffect(() => {
    console.log('>>screen_size<<', screenSize);
  }, [screenSize]);

  return (
    <ScreenSizeContext.Provider value={screenSize}>
      {children}
    </ScreenSizeContext.Provider>
  );

};

export const useScreenSize = () => {
  return useContext(ScreenSizeContext);
};

export const useIsScreenXs = () => {
  const screenSize = useScreenSize();
  const eligibleSizes = ['xs'];
  return eligibleSizes.includes(screenSize);
};

export const useIsScreenSm = () => {
  const screenSize = useScreenSize();
  const eligibleSizes = ['xs', 'sm'];
  return eligibleSizes.includes(screenSize);
};

export const useIsScreenMd = () => {
  const screenSize = useScreenSize();
  const eligibleSizes = ['xs', 'sm', 'md'];
  return eligibleSizes.includes(screenSize);
};

export const useIsScreenLg = () => {
  const screenSize = useScreenSize();
  const eligibleSizes = ['xs', 'sm', 'md', 'lg'];
  return eligibleSizes.includes(screenSize);
};

export const useIsScreenXl = () => {
  const screenSize = useScreenSize();
  const eligibleSizes = ['xs', 'sm', 'md', 'lg', 'xl'];
  return eligibleSizes.includes(screenSize);
};

export const useIsScreen2xl = () => {
  const screenSize = useScreenSize();
  return screenSize === '2xl';
};
