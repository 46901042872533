import Api from "../api-core";

export const getInsightList = async ({
  limit = 100,
  offset = 1,
  tagId = 0,
  displayBanner = false,
  expertId = 0,
}) => {
  try {
    let payload = {
      params: {
        limit,
        offset,
        tag_id: tagId,
        display_banner: displayBanner,
        expert_id: expertId
      }
    }
    
    const result = await Api().get(
      `${process.env.REACT_APP_WEALTH_BASE_API}/Insights`,
      payload
    );
    return result.data;
  } catch (error) {
    throw new ("Error:", error)();
  }
};

export const getInsightTags = async ({
  limit,
  offset
}) => {
  try {
    const payload = {
      params: {
        limit,
        offset
      }
    }
    const result = await Api().get(
      `${process.env.REACT_APP_WEALTH_BASE_API}/Tags`,
      payload
    );
    return result.data;
  } catch (error) {
    throw new ("Error:", error)();
  }
};


export default { 
  getInsightList,
  getInsightTags
 };
