import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";

dayjs.extend(buddhistEra)

export const locals = {
  'th': 'th',
  'en': 'en'
};

export const validLocales = ['en', 'th'];

export function getDateByLocale(date, locale){
  switch (typeof date) {
    case 'string':
      if (dayjs(date, 'YYYY-MM-DD').isValid()) {
        switch (locale) {
          case 'th':
            return dayjs(date, 'YYYY-MM-DD').locale(locale).format('DD MMMM BBBB')
          default:
            return dayjs(date, 'YYYY-MM-DD').locale(locale).format('DD MMMM YYYY')
        }
      } else {
        return dayjs(date, 'YYYY-MM-DD').locale(locale).format('DD MMMM YYYY')
      }
    default:
      return dayjs(date, 'YYYY-MM-DD').locale(locale).format('DD MMMM YYYY')
  }

}