import React, {useCallback} from 'react';
import cx from 'classnames';
import {observer} from 'mobx-react';

import {CONTACT_MODE_UNDER_50M} from '@/views/contact/stores/contact-store';
import {useContactStore} from '@/views/contact/stores/contact-context';
import {splitLines} from '@/utils/html-utils';
import useLocale from '@/hooks/useLocale';
import ContactLayout from '@/views/contact/layouts/ContactLayout';

import s from './styles.module.scss';


const CapitalSection = observer((props = {}) => {

  const {t, locale} = useLocale('dialog-contact');

  const contactStore = useContactStore();
  const {capitalIndex} = contactStore || {};

  const onSelect = useCallback(index => {
    contactStore.setCapitalIndex(index);
    if (index <= 2) {
      contactStore.setMode(CONTACT_MODE_UNDER_50M);
    }
    setTimeout(() => {
      contactStore.next();
    }, 0);
  }, [contactStore])

  return (
    <ContactLayout>
      <div className={cx(
        'flex-1', 'px-4', 'sm:px-8',
        'flex', 'flex-col', 'sm:flex-row', 'justify-center', 'items-stretch',
        s.container
      )}>
        <div
          className={cx(
            'pb-4', 'sm:py-6', 'box-border', 'flex-1', 'space-y-4', 'sm:space-y-6',
            'flex', 'flex-col', 'justify-center', 'items-center'
          )}>
          <h2 className={cx(
            'font-semibold', 'text-center', 'text-legacy-purple',
            {'font-sans': locale !== 'en', 'font-serif': locale === 'en'},
          )}>
            {splitLines(t('capital.title'))}
          </h2>
          <hr className={cx('hidden', 'sm:block')}/>
        </div>
        <hr className={cx('block', 'sm:hidden')}/>
        <div className={cx('hidden', 'sm:block', 'w-8')}/>
        <div className={cx(
          'sm:flex-1', 'box-border', 'py-4', 'sm:py-6', 'space-y-4',
          'flex', 'flex-col', 'justify-center', 'items-stretch',
        )}>
          <button className={cx(
            'uppercase', 'btn',
            {'btn-outlined': capitalIndex !== 0, 'btn-submit': capitalIndex === 0}
          )} onClick={() => onSelect(0)}>
            {t('capital.options.1')}
          </button>
          <button className={cx(
            'uppercase', 'btn',
            {'btn-outlined': capitalIndex !== 1, 'btn-submit': capitalIndex === 1}
          )} onClick={() => onSelect(1)}>
            {t('capital.options.2')}
          </button>
          <button className={cx(
            'uppercase', 'btn',
            {'btn-outlined': capitalIndex !== 2, 'btn-submit': capitalIndex === 2}
          )} onClick={() => onSelect(2)}>
            {t('capital.options.3')}
          </button>
          <button className={cx(
            'uppercase', 'btn',
            {'btn-outlined': capitalIndex !== 3, 'btn-submit': capitalIndex === 3}
          )} onClick={() => onSelect(3)}>
            {t('capital.options.4')}
          </button>
          <button className={cx(
            'uppercase', 'btn',
            {'btn-outlined': capitalIndex !== 4, 'btn-submit': capitalIndex === 4}
          )} onClick={() => onSelect(4)}>
            {t('capital.options.5')}
          </button>
          <button className={cx(
            'uppercase', 'btn',
            {'btn-outlined': capitalIndex !== 5, 'btn-submit': capitalIndex === 5}
          )} onClick={() => onSelect(5)}>
            {t('capital.options.6')}
          </button>
        </div>
      </div>
    </ContactLayout>
  );

});

export default CapitalSection;