import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';
import {Link} from 'react-router-dom';

import imgNext from '../../../../assets/images/common-breadcrumbs/ic-next.svg';


const BreadCrumbs = (props = {}) => {

  const {paths = []} = props;

  return (
    <section className={cx('py-9', 'sm:py-9', 'bg-primary-gradient')}>
      <div className={cx(
        'content', 'space-x-2',
        'flex', 'flex-row', 'justify-start', 'items-center'
      )}>
        {
          paths.map((path, index) => {
            const {id, title, url} = path;
            const lastItem = index === paths.length - 1;
            const classNames = cx(
              'text-light', 'text-xs', 'sm:text-base',
              {'text-gd-grey-light': !lastItem},
              {'text-white': lastItem},
              {'text-ellipsis line-clamp-1': lastItem},
            );

            return (
              <Fragment key={id}>
                {
                  url ? (
                    <Link to={url} className={classNames}>{title}</Link>
                  ) : (
                    <span className={classNames} >{title}</span>
                  )
                }
                {
                  !lastItem ? (
                    <img alt="Next" src={imgNext}/>
                  ) : null
                }
              </Fragment>
            )

          })
        }
      </div>
    </section>
  );

};

BreadCrumbs.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    url: PropTypes.string,
  })),
};

export default BreadCrumbs;