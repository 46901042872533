import React, {useMemo} from 'react';
import cx from 'classnames';

import gridItems from './data/grid-items';
import {PAGE_INVESTING} from '@/views/solution/svgs/SolutionApproachMenu';
import SolutionApproachLayout from '@/views/solution/layouts/SolutionApproachLayout';
import SolutionApproachHeadSection from '@/views/solution/sections/SolutionApproachHeadSection';
import SolutionApproachLinkGridSection from '@/views/solution/sections/SolutionApproachLinkGridSection';
import ContactSection from '@/views/common/section/ContactSection';

import s from './styles.module.scss';

import imgBanner from '@/assets/images/page-solution-approach-investing/banner.png';
import imgMobileBanner from '@/assets/images/page-solution-approach-investment-solution/mobile-banner.png';
import imgChart from '@/assets/images/page-solution-approach-investing/chart.svg';


const SolutionApproachInvestingPage = (props = {}) => {

  const namespace = useMemo(() => 'page-solution-approach-investing', []);

  return (
    <SolutionApproachLayout>
      <SolutionApproachHeadSection namespace={namespace} banner={imgBanner} mobileBanner={imgMobileBanner}
                                   chart={imgChart} page={PAGE_INVESTING}/>
      <SolutionApproachLinkGridSection namespace={namespace} items={gridItems}/>
      <ContactSection/>
    </SolutionApproachLayout>
  );

};

export default SolutionApproachInvestingPage;