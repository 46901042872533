import imgBanner01 from '@/assets/images/page-solution-approach-investing/grid-banner-01.png';
import imgBanner02 from '@/assets/images/page-solution-approach-investing/grid-banner-02.png';
export default [
  {
    id: 1,
    type: 'link',
    theme: 'light',
    title: 'stocks.title',
    description: 'stocks.description',
    url: '/solution/stocks',
  },
  {
    id: 2,
    type: 'link',
    theme: 'dark',
    title: 'fixedIncome.title',
    description: 'fixedIncome.description',
    url: '/solution/fixed-income',
  },
  {
    id: 3,
    type: 'banner',
    banner: imgBanner01,
  },
  {
    id: 4,
    type: 'link',
    theme: 'purple',
    title: 'mutualFunds.title',
    description: 'mutualFunds.description',
    url: '/solution/mutual-funds',
  },
  {
    id: 5,
    type: 'link',
    theme: 'light',
    title: 'mandateService.title',
    description: 'mandateService.description',
    url: '/solution/mandate-service',
  },
  {
    id: 6,
    type: 'link',
    theme: 'grey',
    title: 'structuredProduct.title',
    description: 'structuredProduct.description',
    url: '/solution/structured-product',
  },
  {
    id: 7,
    type: 'banner',
    banner: imgBanner02
  },
  {
    id: 8,
    type: 'link',
    theme: 'grey',
    title: 'privateMarket.title',
    description: 'privateMarket.description',
    url: '/solution/private-market',
  },
  {
    id: 9,
    type: 'link',
    theme: 'purple',
    title: 'cash.title',
    description: 'cash.description',
    url: '/solution/cash',
  },
];