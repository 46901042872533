import React from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';

import useLocale from '@/hooks/useLocale';

import s from './styles.module.scss';

import imgLogoEdge from '@/assets/images/page-why-us-our-services-section-edge/logo-edge.svg';
import imgLogoKkps from '@/assets/images/page-why-us-our-services-section-edge/logo-kkps.svg';


const WhyUsOurServicesEdgeSection = (props = {}) => {

  const {t} = useLocale('page-why-us-our-services-section-edge');

  return (
    <section className={cx('relative', s.container)}>
      <div className={cx(
        'relative', 'content',
        'flex', 'flex-row', 'justify-center', 'items-start',
      )}>
        <div className={cx(
          'w-full', 'sm:max-w-[890px]', 'relative', 'py-[84px]',
          'flex', 'flex-col-reverse', 'sm:flex-row', 'justify-start', 'items-stretch'
        )}>
          <div className={cx(
            'box-border', 'space-y-6', 'p-6', 'flex-[342]', 'flex-shrink-0', 'bg-white',
            'flex', 'flex-col', 'justify-start', 'items-stretch',
          )}>
            <img className={cx('self-start', 'h-[90px]')} alt="Edge" src={imgLogoEdge}/>
            <div className={cx(
              'border-0', 'bg-gd-grey-light', 'h-0.5',
              'flex', 'flex-horizontal', 'justify-between', 'items-center'
            )}>
              <div className={cx(
                'w-0', 'h-0', 'border-solid', 'border-t-[2px]', 'border-l-[3px]',
                'border-t-clear', 'border-r-clear', 'border-b-clear', 'border-l-white'
              )}/>
              <div className={cx(
                'w-0', 'h-0', 'border-solid', 'border-b-[2px]', 'border-l-[3px]',
                'border-t-clear', 'border-r-clear', 'border-b-white', 'border-l-clear',
              )}/>
            </div>
            <p className={cx('mt-4', 'flex-1', 'text-primary-900', 'text-xl')}>
              {t('edgeExcerpt')}
            </p>
            <div className={cx(
              'p-4', 'space-y-3', 'rounded-lg',
              'flex', 'flex-col', 'justify-start', 'items-stretch',
              s.minimum
            )}>
              <span className={cx('text-2xl', 'leading-7', 'font-normal', 'text-primary-900')}>
                {t('edgeAccountSize')}
              </span>
              <span className={cx('text-[2rem]', 'leading-7', 'font-medium', 'text-primary-900')}>
                {t('edgeMinimum')}
              </span>
            </div>
            <a target="_blank" href="https://edge.co.th/" className={cx(
              'btn', 'btn-outlined', 'btn-outlined-light', 'btn-xl',
              'flex', 'flex-row', 'justify-center', 'items-center',
            )}>
              <span className={cx('tracking-wide')}>Learn More</span>
            </a>
          </div>
          <div className={cx(
            'box-border', 'space-y-6', 'p-6', 'flex-[598]', 'flex-shrink-0', 'bg-primary-900',
            'flex', 'flex-col', 'justify-start', 'items-stretch'
          )}>
            <img className={cx('self-start', 'h-[90px]')} alt="Edge" src={imgLogoKkps}/>
            <div className={cx(
              'border-0', 'bg-gd-grey-light', 'h-0.5',
              'flex', 'flex-horizontal', 'justify-between', 'items-center'
            )}>
              <div className={cx(
                'w-0', 'h-0', 'border-solid', 'border-t-[2px]', 'border-l-[3px]',
                'border-t-clear', 'border-r-clear', 'border-b-clear', 'border-l-primary-900'
              )}/>
              <div className={cx(
                'w-0', 'h-0', 'border-solid', 'border-b-[2px]', 'border-l-[3px]',
                'border-t-clear', 'border-r-clear', 'border-b-primary-900', 'border-l-clear',
              )}/>
            </div>
            <p className={cx('mt-4', 'flex-1', 'text-white', 'text-xl')}>
              {t('wealthExcerpt')}
            </p>
            <div className={cx(
              'p-4', 'space-y-3', 'rounded-lg',
              'flex', 'flex-col', 'justify-start', 'items-stretch',
              s.initial
            )}>
              <span className={cx('text-2xl', 'leading-7', 'font-normal', 'text-white')}>
                {t('wealthAccountSize')}
              </span>
              <span className={cx('text-[2rem]', 'leading-7', 'font-medium', 'text-white')}>
                {t('wealthMinimum')}
              </span>
            </div>
            <Link to="#contact" state={{noScroll: true}} className={cx(
              'btn', 'btn-bright', 'btn-bright-light', 'btn-xl',
              'flex', 'flex-row', 'justify-center', 'items-center',
            )}>
              <span className={cx('tracking-wide')}>Join Us</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );

};

export default WhyUsOurServicesEdgeSection;