import React, {useMemo} from 'react';
import cx from 'classnames';

import gridRelatedProducts from '@/views/goal/pages/GoalLifeStylePage/data/grid-related-products';
import premiumLifestyleRelatedProducts
  from '@/views/goal/pages/GoalLifeStylePage/data/premium-lifestyle-related-products';
import useLocale from "@/hooks/useLocale";
import StandardLayout from "@/views/common/layouts/StandardLayout";
import BreadCrumbs from "@/views/common/components/BreadCrumbs";
import GoalLifeStyleHeadSection from "@/views/goal/pages/GoalLifeStylePage/sections/GoalLifeStyleHeadSection";
import GoalQuestionSection from '@/views/goal/sections/GoalQuestionSection';
import GoalHelpSection from '@/views/goal/sections/GoalHelpSection';
import PremiumLifestyleSection from '@/views/goal/pages/GoalLifeStylePage/sections/PremiumLifestyleSection';

import s from './styles.module.scss';
import GoalRelatedProductsGridSection from '@/views/goal/sections/GoalRelatedProductsGridSection';


const GoalLifeStylePage = (props = {}) => {

  const {t, i18n, locale} = useLocale('page-goal-lifestyle');

  const breadcrumbsPaths = useMemo(() => {
    return [
      {id: 1, title: 'Home', url: `/${locale}`},
      {id: 2, title: 'What\'s your goal', url: ''},
      {id: 3, title: t('pageName'), url: ''},
    ];
  }, [t, locale]);

  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <BreadCrumbs paths={breadcrumbsPaths}/>
        <GoalLifeStyleHeadSection/>
        <GoalQuestionSection/>
        <PremiumLifestyleSection relatedProducts={premiumLifestyleRelatedProducts}/>
        <GoalHelpSection/>
        <GoalRelatedProductsGridSection namespace="solutions" relatedProducts={gridRelatedProducts}/>
      </div>
    </StandardLayout>
  );

};

export default GoalLifeStylePage;