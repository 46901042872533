import React from 'react';
import cx from 'classnames';

import useLocale from '@/hooks/useLocale';
import GoalHeadSection from "@/views/goal/sections/GoalHeadSection";

import s from './styles.module.scss';

import imgBanner from '@/assets/images/page-goal-lifestyle-head/banner.png';


const GoalLifeStyleHeadSection = (props = {}) => {

  const {t} = useLocale('page-goal-lifestyle-section-head');

  return (
    <GoalHeadSection title={t('title')} excerpt={t('excerpt')} banner={imgBanner}/>
  );

};

export default GoalLifeStyleHeadSection;