import React, {useCallback} from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';

import NavigationButton, {
  NAVIGATION_BUTTON_MODE_BACK,
  NAVIGATION_BUTTON_MODE_NEXT
} from "@/views/home/pages/HomePage/sections/HomeBannerSection/components/NavigationButton";

import s from './styles.module.scss';


const NavigationPane = (props = {}) => {

  const {swiper} = props;

  const onClickNavigation = useCallback(mode => {
    if (!swiper) return;
    switch (mode) {
      case NAVIGATION_BUTTON_MODE_BACK:
        swiper.slidePrev();
        break;
      case NAVIGATION_BUTTON_MODE_NEXT:
        swiper.slideNext();
        break;
      default:
        break;
    }
  }, [swiper]);

  return (
    <div className={cx(
      'absolute', 'left-0', 'top-0', 'w-full', 'h-full', 'z-20',
      'flex', 'flex-row', 'justify-start', 'items-center', 'pointer-events-none',
      s.container
    )}>
      <NavigationButton mode={NAVIGATION_BUTTON_MODE_BACK} onClick={onClickNavigation}/>
      <div className={cx('flex-1')}/>
      <NavigationButton mode={NAVIGATION_BUTTON_MODE_NEXT} onClick={onClickNavigation}/>
    </div>
  );

};

NavigationPane.propTypes = {
  swiper: PropTypes.object,
};

export default NavigationPane;