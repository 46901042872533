import React, { useState } from "react";
import cx from "classnames";
import goals from "@/views/home/data/goals";
import StandardLayout from "@/views/common/layouts/StandardLayout";
import WaveAnimation from "@/views/home/pages/HomePage/components/WaveAnimation";
import HomeBannerSection from "./sections/HomeBannerSection";
import HomeGoalSection from "./sections/HomeGoalSection";
import InsightSection, { SEE_ALL_POSITION_BOTTOM } from "@/views/common/section/InsightSection";
import ContactSection from "@/views/common/section/ContactSection";
import NewsLetterSection from "@/views/common/section/NewsLetterSection";

import s from "./styles.module.scss";
import useLocale from "@/hooks/useLocale";

const HomePage = (props = {}) => {
  const { locale } = useLocale("");
  const [banners, setBanners] = useState([]);
  const [isError, setIsError] = useState(false);
  const [bannersLoading, setBannersLoading] = useState([
    {
      id: 1,
      title: null,
      description: null,
      showbutton: false,
      buttonText: null,
      banners: {
        mobile: {
          background: null,
          overlay: null,
        },
        desktop: {
          background: null,
          overlay: null,
        },
      },
      mode: null,
      url: null,
    },
  ]);

  return (
    <StandardLayout>
        <HomeBannerSection
          banners={banners}
          setBanners={setBanners}
          setBannersLoading={setBannersLoading}
          setIsError={setIsError}
        />
        <WaveAnimation />
        <HomeGoalSection goals={goals} />
        <InsightSection
          isRedirect={true}
          seeAllPosition={SEE_ALL_POSITION_BOTTOM}
          getInsight={{ displayBanner: true, limit: 3 }}
        />
      <NewsLetterSection />
      <ContactSection />
    </StandardLayout>
  );
};

export default HomePage;
