import React from 'react';
import cx from 'classnames';


import tabs from '@/views/why-us/data/tabs';
import {useIsScreenSm} from '@/providers/ScreenSizeProvider';
import StandardLayout from "@/views/common/layouts/StandardLayout";
import GradientBackground from "./svgs/GradientBackground";
import RippleBackground from './svgs/RippleBackground';
import WhyUsDigitalHeadSection from './sections/WhyUsDigitalHeadSection';
import WhyUsDigitalMobileSection from "./sections/WhyUsDigitalMobileSection";
import WhyUsDigitalLineSection from './sections/WhyUsDigitalLineSection';
import WhyUsDigitalPlatformSection from "./sections/WhyUsDigitalPlatformSection";
import ContactSection from '@/views/common/section/ContactSection';

import s from './styles.module.scss';


const WhyUsDigitalPage = () => {

  const isScreenSm = useIsScreenSm();

  return (
    <StandardLayout>
      <div className={cx('relative', 'flex', 'flex-col', 'justify-start', 'align-stretch', s.container)}>
        {isScreenSm ? null : <GradientBackground/>}
        {isScreenSm ? null : <RippleBackground/>}
        <WhyUsDigitalHeadSection tabs={tabs}/>
        <WhyUsDigitalMobileSection/>
        <WhyUsDigitalLineSection/>
        <WhyUsDigitalPlatformSection/>
        <ContactSection/>
      </div>
    </StandardLayout>
  );

};

export default WhyUsDigitalPage;