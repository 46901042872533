import React from 'react';
import cx from 'classnames';
import s from './styles.module.scss';
import useLocale from '@/hooks/useLocale';
import DOMPurify from 'dompurify';
import { locals } from '@/utils/locales';
import parse from 'html-react-parser';
const KeyTakeaways = ({ insight }) => {
  const { locale } = useLocale('');
  const {
    keyTakeawayEn = '',
    keyTakeawayTh = '',
  } = insight || {};

  const sanitizedContent = locale === locals.en ? DOMPurify.sanitize(keyTakeawayEn) : DOMPurify.sanitize(keyTakeawayTh);
  return (
    <div className={cx(
      'bg-primary-50', 'rounded-lg', 'p-6', 'space-y-4',
      'flex', 'flex-col', 'items-stretch',
      s.container
    )}>
      <h2 className={cx('text-legacy-purple', 'mb-2')}>Key Takeaways</h2>
      <div
        className={cx(
          'list-inside', 'list-outside',
          'text-legacy-purple', 'text-lg', 'font-normal',
          'space-y-6', 'pl-5',
          'flex', 'flex-col', 'flex-start', 'items-stretch'
        )}
      >
        {parse(sanitizedContent)}
      </div>
    </div>
  );
};

export default KeyTakeaways;
