export default [
  {
    id: 1,
    title: 'financialPlanning.title',
    description: 'financialPlanning.description',
    url: '/solution/financial-planning',
  },
  {
    id: 2,
    title: 'insuranceAdvisory.title',
    description: 'insuranceAdvisory.description',
    url: '/solution/insurance',
  },
  {
    id: 3,
    title: 'cash.title',
    description: 'cash.description',
    url: '/solution/cash',
  },
  {
    id: 4,
    title: 'mutualFunds.title',
    description: 'mutualFunds.description',
    url: '/solution/mutual-funds',
  },
  {
    id: 5,
    title: 'mandateService.title',
    description: 'mandateService.description',
    url: '/solution/mandate-service',
  },
  {
    id: 6,
    title: 'privateMarket.title',
    description: 'privateMarket.description',
    url: '/solution/private-market',
  },
];