import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';

import { useIsScreenSm } from '@/providers/ScreenSizeProvider';
import FilterTag from "../FilterTag";
import s from './styles.module.scss';

const FilterList = (props = {}) => {
  const { title, allTagName = "ALL", categories, setFilteredData, dataList = [] } = props;

  const updatedCategories = useMemo(() => {
    const fixedTag = { id: 0, name: allTagName };
    return [fixedTag, ...categories.sort((a, b) => a.name.localeCompare(b.name))];
  }, [categories]);

  const isScreenSm = useIsScreenSm();

  const [currentIndex, setCurrentIndex] = useState(0);

  const filterDataByTagId = (data, tagId) => {
    if (tagId === 0) {
      return data; // Show all items if tagId is 0
    }
    return data.filter(item => 
      Array.isArray(item.tags) && item.tags.some(tag => tag.id === tagId)
    );
  };

  const currentCategory = useMemo(() => {
    return updatedCategories[currentIndex];
  }, [updatedCategories, currentIndex]);

  const onClickTag = useCallback(async index => {
    setCurrentIndex(index);
  }, []);

  useEffect(() => {
    const filteredData = filterDataByTagId(dataList, currentCategory.id);
    setFilteredData(filteredData);
  }, [currentCategory, dataList]);

  return (
    <div className={cx(
      'relative', 'py-4', 'border-y', 'border-primary-50',
      'space-y-3', 'sm:space-y-0', 'space-x-0', 'sm:space-x-4',
      'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'sm:justify-between', 'items-start', 'sm:items-center',
      s.container
    )}>
      <h4 className={cx('text-legacy-purple', 'font-medium', 'self-start', 'text-nowrap','whitespace-nowrap', 'text-ellipsis')}>{title}</h4>
      <div className={cx(
        'sm:w-auto',
        {
          'overflow-auto w-full': isScreenSm, // Mobile specific styles
          'flex': true, // Common styles
          'flex-row': true, // Common styles
          'justify-start': true, // Common styles
          'items-center': true, // Common styles
        },
        s.slide
      )}>
        <ul className={cx(
          {
            'flex-nowrap': isScreenSm, // Apply nowrap for mobile only
            'flex-wrap': !isScreenSm, // Apply wrap for larger screens
          },
          'flex', 'justify-start', 'items-center', 'gap-2'
        )}>
          {
            updatedCategories.map((category, index) => {
              const { id } = category;
              const selected = id === currentCategory.id;
              return (
                <li key={id}>
                  <FilterTag 
                    category={category} 
                    selected={selected} 
                    onClick={() => onClickTag(index)} 
                  />
                </li>
              );
            })
          }
        </ul>
      </div>
    </div>
  );
};

FilterList.propTypes = {
  title: PropTypes.string.isRequired,
  allTagName: PropTypes.string,
  categories: PropTypes.array,
  setFilteredData: PropTypes.func,
  insightsList: PropTypes.array,
};

export default FilterList;
