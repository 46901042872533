import React, {useCallback, useMemo} from 'react';
import cx from 'classnames';
import Calendar from 'react-calendar';
import {observer} from 'mobx-react';
import dayjs from 'dayjs';

import useLocale from '@/hooks/useLocale';
import {splitLines} from '@/utils/html-utils';
import {useContactStore} from '@/views/contact/stores/contact-context';
import ContactLayout from '@/views/contact/layouts/ContactLayout';

import s from './styles.module.scss';


const AppointmentSection = observer((props = {}) => {

  const {t, locale} = useLocale('dialog-contact');

  const contactStore = useContactStore();
  const {appointmentDate, initialAppointmentDate} = contactStore || {};

  const onClickNext = useCallback(() => {
    contactStore.next();
  }, [contactStore])

  const footerComponent = useMemo(() => {
    return (
      <div className={cx('flex', 'flex-row', 'justify-start', 'items-stretch', s.footer)}>
        <button className={cx('flex-1', 'p-4', 'btn', 'btn-submit', 'btn-next')} onClick={onClickNext}>
          <span className={cx('leading-[1.2]', 'tracking-wider')}>
            Next Question
          </span>
        </button>
      </div>
    );
  }, [onClickNext]);

  const onChangeAppointDate = useCallback(date => {
    contactStore.setAppointmentDate(date);
  }, [contactStore]);

  const onDisabled = useCallback(jsDate => {
    const date = dayjs(jsDate.date).startOf('d');
    return date.diff(initialAppointmentDate, 'd') < 0;
  }, [initialAppointmentDate]);

  if (!initialAppointmentDate) return null;

  return (
    <ContactLayout footerComponent={footerComponent}>
      <div className={cx(
        'flex-1', 'px-4', 'sm:px-8', 'overflow-visible',
        'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'sm:justify-center', 'items-stretch',
        s.container
      )}>
        <div className={cx(
          'px-0', 'sm:py-6', 'box-border', 'sm:flex-1',
          'space-y-4', 'sm:space-y-6', 'flex', 'flex-col', 'justify-center', 'items-center'
        )}>
          <h2 className={cx(
            'font-semibold', 'text-center', 'text-legacy-purple',
            {'font-sans': locale !== 'en', 'font-serif': locale === 'en'},
          )}>
            {splitLines(t('appointment.title'))}
          </h2>
          <p className={cx('text-center', 'text-legacy-purple')}>
            {splitLines(t('appointment.excerpt'))}
          </p>
          <hr/>
        </div>
        <div className={cx('w-8')}/>
        <div className={cx(
          'block', 'relative', 'box-border', 'py-6', 'sm:flex-1',
          'flex', 'flex-col', 'items-start', 'sm:justify-center', 'items-center'
        )}>
          <div className={cx('space-x-4', 'flex', 'flex-row', 'justify-start', 'items-stretch')}>
            <Calendar calendarType="gregory" minDetail="month" showNeighboringMonth={false}
                      prevLabel={<i className={cx('link-legacy-purple', 'icon-chevron-left')}/>}
                      nextLabel={<i className={cx('link-legacy-purple', 'icon-chevron-right')}/>}
                      defaultValue={initialAppointmentDate}
                      value={appointmentDate || initialAppointmentDate}
                      onChange={onChangeAppointDate} tileDisabled={onDisabled}/>
          </div>
        </div>
      </div>
    </ContactLayout>
  );

});

export default AppointmentSection;