import React, {useCallback} from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';

import s from './styles.module.scss';


const FilterTag = (props = {}) => {

  const {selected = false, category = {}, onClick} = props;
  const {name} = category;

  const onClickTag = useCallback(() => {
    if (!onClick) return;
    onClick(category);
  }, [category, onClick]);

  return (
    <button className={cx(
      'px-3', 'h-8', 'cursor-pointer', 'box-border', 'outline-none',
      'rounded', 'border', 'border-primary-200', 'hover:bg-white',
      'transition-all', 'duration-300',
      {'border-clear': selected},
      {'bg-primary-gradient': selected},
      s.container
    )} onClick={onClickTag}>
      <span className={cx(
        'block', 'font-medium', 'uppercase',
        'text-base', 'text-primary-900', 'whitespace-nowrap',
        {'text-white': selected},
      )}>
        {name}
      </span>
    </button>
  );

};

FilterTag.propTypes = {
  selected: PropTypes.bool,
  expertise: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string
  }),
};

export default FilterTag;