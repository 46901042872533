import {useEffect} from 'react';
import gsap from "gsap";


const useGoalHorizontalRelatedProductAnimation = (
  triggerRef,
  containerRef,
  slideRef,
  scrollWidth,
  enabled = true,
  scrollFactor = 1
) => {

  useEffect(() => {

    if (scrollWidth === 0 || !enabled) return;

    const ctx = gsap.context(() => {
      gsap.fromTo(
        slideRef.current,
        {
          translateX: '0',
        },
        {
          translateX: -scrollWidth,
          scrollTrigger: {
            trigger: triggerRef.current,
            start: `+200 bottom`,
            end: '+=' + scrollWidth,
            scrub: 1,
          }
        },
      );
      gsap.timeline(
        {
          scrollTrigger: {
            trigger: triggerRef.current,
            start: 'top bottom',
            end: "+=" + scrollWidth * scrollFactor,
            scrub: 1,
            pin: containerRef.current,
            pinSpacing: true,
          },
        },
      );
    });

    return () => ctx?.revert();
  }, [triggerRef, containerRef, slideRef, scrollWidth, enabled]);

};

export default useGoalHorizontalRelatedProductAnimation;