import React, { useEffect, useMemo, useState } from "react";
import cx from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

import experts from "@/data/common/experts";
import useLocale from '@/hooks/useLocale';
import StandardLayout from "@/views/common/layouts/StandardLayout";
import NewsLetterSection from "@/views/common/section/NewsLetterSection";
import ExpertDetails from "@/views/common/components/ExpertDetails";
import BreadCrumbs from "@/views/common/components/BreadCrumbs";
import InsightSection, { SEE_ALL_POSITION_BOTTOM } from "@/views/common/section/InsightSection";
import { getExperts } from "@/apis/experts";
import { get } from "lodash";
import { locals } from "@/utils/locales";
import loadingDetail from '@/assets/images/page-experts/loadingDetail.svg';
import s from './styles.module.scss';
import errImg from '@/assets/images/common/no-content.png'

const ExpertDetailsPage = (props = {}) => {
  const [ expert, setExpert ] = useState({});
  const { t, locale } = useLocale('page-experts-detail');
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getExpert = await getExperts({ id })
        let tmpExpert = get(getExpert, 'data.experts')
        if (tmpExpert && tmpExpert.length > 0) {
          setExpert(tmpExpert[0])
        } else {
          navigate(`/${locale}/404`, { replace: true });
        }
      } catch (error) {
        setIsError(true)
        console.error("Error fetching data:", error);
      }
      setIsLoading(false)
    };

    fetchData();
  }, []);

  const breadcrumbsPaths = useMemo(() => {
    if (!expert) return [];
    return [
      { id: 1, title: 'Home', url: `/${locale}` },
      { id: 2, title: 'Experts', url: `/${locale}/experts/overview` },
      { id: 3, title: locale === locals.en ? expert.nameEn : expert.nameTh, url: '' },
    ];
  }, [expert, locale]);

  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <BreadCrumbs paths={breadcrumbsPaths} />
        {
          isError ?
            <section className={cx('relative', 'pt-4', 'pb-6', s.container)}>
              <div className={cx('content', 'flex', 'flex-col', 'justify-start', 'items-stretch', s.content)}>
                <img className={cx('h-[90px]', 'w-[90px]', 'self-center')} src={errImg} alt='' />
                <span className={cx('text-[#B3ACC4]', 'font-medium', 'whitespace-pre-line', 'text-center', 'mt-7')}>{t('error')}</span>
                <a href="." onClick={(e) => {
                  e.preventDefault(); // Prevent the default anchor behavior
                  navigate(0); // Refresh the page
                }} className={cx(
                  'mt-2', 'sm:mt-8', 'btn', 'btn-outlined', 'btn-outlined-light', 'w-[160px]', 'py-4', 'self-center'
                )}>
                  <span>{t('tryAgain')}</span>
                </a>
              </div>
            </section>
            :
            <>
              {
                isLoading ?
                  <section className={cx(
                    'relative', 'py-9',
                    'bg-bottom', 'bg-cover', 'bg-no-repeat',
                    s.container
                  )}>
                    <div className={cx(
                      'relative', 'content', 'mt-8', 'space-y-6', 'sm:space-y-0', 'sm:space-x-8',
                      'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-start',
                    )}>
                      <img src={loadingDetail} alt="Loading..." />
                    </div>
                  </section>
                  :
                  <ExpertDetails expert={expert} />
              }
              <InsightSection
                isRedirect={false}
                seeAllPosition={SEE_ALL_POSITION_BOTTOM}
                getInsight={{ expertId: id }}
              />
              <NewsLetterSection />
            </>
        }

      </div>
    </StandardLayout>
  )

};

export default ExpertDetailsPage;
