import React, {useEffect, useRef} from 'react'
import PropTypes from "prop-types";
import cx from 'classnames';
import {Link} from 'react-router-dom';

import s from './styles.module.scss';
import {useIsScreenSm} from '@/providers/ScreenSizeProvider';

export const TAB_SIZE_NORMAL = 'tab-size-normal';
export const TAB_SIZE_SMALL = 'tab-size-small';
export const TAB_MODE_NORMAL = 'tab-mode-normal';
export const TAB_MODE_LIGHT = 'tab-mode-light';


const TabList = (props = {}) => {

  const listRef = useRef(undefined);

  const isScreenSm = useIsScreenSm();

  const slideRef = useRef(undefined);

  const {
    size = TAB_SIZE_NORMAL,
    mode = TAB_MODE_NORMAL,
    items = [],
    selectedIndex
  } = props;

  useEffect(() => {
    if (!isScreenSm) return;
    const {current: list} = listRef;
    if (!list) return;
    const item = list.children[selectedIndex + 2];
    if (!item) return;
    setTimeout(() => {
      const {current: slide} = slideRef;
      if (!slide) return;
      const left = item.offsetLeft;
      slide.scroll(left, 0);
    }, 50);
  }, [selectedIndex, isScreenSm]);

  return (
    <section className={cx('relative', 'w-full', 'overflow-visible', 'sm:overflow-hidden', s.container)}>
      <div className={cx(
        {'content': size === TAB_SIZE_NORMAL},
        'flex', 'flex-col', 'justify-start', 'items-stretch'
      )}>
        <hr className={cx(
          'border-0',
          {'h-px': TAB_MODE_NORMAL, 'h-[2px]': TAB_MODE_LIGHT},
          {'bg-primary-50': mode === TAB_MODE_NORMAL},
          {[s.light]: mode === TAB_MODE_LIGHT},
        )}/>
        <div ref={slideRef} className={cx(
          'w-[calc(100%+32px)]', 'sm:w-full', '-ml-4', '-mr-4', 'sm:m-0', 'sm:p-0',
          'overflow-auto', 'sm:overflow-visible',
          s.scroll)}>
          <ul ref={listRef} className={cx(
            'sm:mx-0', 'flex', 'flex-row', 'justify-start', 'items-stretch',
            {'justify-start': size === TAB_SIZE_SMALL, 'sm:justify-center': size === TAB_SIZE_SMALL},
          )}>
            {isScreenSm ? <li className={cx('w-2', 'flex-shrink-0')}/> : null}
            {isScreenSm ? <li className={cx('flex-1')}/> : null}
            {
              items.map((item, index) => {
                const {id, title, url} = item;
                return (
                  <li id={index} key={id} className={cx(
                    {'flex-1': size === TAB_SIZE_NORMAL},
                    'relative',
                    {'p-6': size === TAB_SIZE_NORMAL},
                    {'px-2': size === TAB_SIZE_SMALL, 'py-4': size === TAB_SIZE_SMALL, 'sm:p-4': size === TAB_SIZE_SMALL},
                    'font-medium', 'text-base', 'text-center',
                    {'text-primary-400': selectedIndex !== index && mode === TAB_MODE_NORMAL},
                    {'text-primary-300': selectedIndex !== index && mode === TAB_MODE_NORMAL},
                    {'sm:hover:text-primary-600': selectedIndex !== index && mode === TAB_MODE_NORMAL},
                    {'text-legacy-purple': selectedIndex === index && mode === TAB_MODE_NORMAL},
                    {'text-primary-200': selectedIndex !== index && mode === TAB_MODE_LIGHT},
                    {'sm:hover:text-primary-100': selectedIndex !== index && mode === TAB_MODE_LIGHT},
                    {'text-white': selectedIndex === index && mode === TAB_MODE_LIGHT},
                    {'pl-2': size === TAB_SIZE_SMALL && index !== 0, 'pl-6': size === TAB_SIZE_SMALL && index !== 0},
                    'transition-all', 'duration-300', 'ease-in-out',
                    'uppercase',
                  )}>
                    <Link to={url || '#'} target={(id === 4) ? "_blank" : ""}  state={{noScroll: true}} className={cx(
                      'relative', 'tracking-wider', 'whitespace-nowrap', 'leading-[1.2]',
                      {[s.selected]: selectedIndex === index && size !== TAB_SIZE_SMALL},
                    )}>
                      {title}
                    </Link>
                  </li>
                );
              })
            }
            {isScreenSm ? <li className={cx('flex-1')}/> : null}
            {isScreenSm ? <li className={cx('w-3', 'flex-shrink-0')}/> : null}
          </ul>
        </div>
        <hr className={cx(
          'border-0', 'h-[2px]',
          {'bg-primary-50': mode === TAB_MODE_NORMAL},
          {[s.light]: mode === TAB_MODE_LIGHT},
        )}/>
      </div>
    </section>
  );

};

TabList.propTypes = {
  mode: PropTypes.oneOf([TAB_MODE_NORMAL, TAB_MODE_LIGHT]),
  size: PropTypes.oneOf([TAB_SIZE_NORMAL, TAB_SIZE_SMALL]),
  items: PropTypes.array,
  selectedIndex: PropTypes.number,
};

export default TabList;