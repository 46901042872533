import imgBanner01 from '../../../assets/images/page-why-us-expertise/banner-01.png';
import imgBanner02 from '../../../assets/images/page-why-us-expertise/banner-02.png';
import imgBanner03 from '../../../assets/images/page-why-us-expertise/banner-03.png';
import imgBanner04 from '../../../assets/images/page-why-us-expertise/banner-04.png';
import imgBanner05 from '../../../assets/images/page-why-us-expertise/banner-05.png';
import imgBanner06 from '../../../assets/images/page-why-us-expertise/banner-06.png';
import imgBanner07 from '../../../assets/images/page-why-us-expertise/banner-07.png';
import imgBanner08 from '../../../assets/images/page-why-us-expertise/banner-08.png';
import imgBanner09 from '../../../assets/images/page-why-us-expertise/banner-09.png';
import imgBanner10 from '../../../assets/images/page-why-us-expertise/banner-10.png';

export default [
  {
    year: 2022,
    items: [
      {
        id: 1,
        banner: imgBanner01,
        title: {
          en: 'Asian Private Banker: the Best Domestic Private Bank Thailand',
          th: 'Asian Private Banker: the Best Domestic Private Bank Thailand',
        },
        description: {
          en: 'This accolade recognizes exceptional wealth management practices, including insightful investment strategies and remarkable product offerings that cater to comprehensive domestic and international requirements. These accomplishments are driven by a steadfast commitment to the Philosophy of Wealth, which guides our business operations.',
          th: 'รางวัลแสดงถึงความเป็นเลิศในการบริหารความมั่งคั่งรวมถึงการมีคำตอบทางการลงทุนและผลิตภัณฑ์ที่โดดเด่น ครอบคลุมทั้งในประเทศและต่างประเทศแบบครบวงจร ภายใต้ปรัชญาการดำเนินธุรกิจ Philosophy of Wealth'
        },
      },
      {
        id: 2,
        banner: imgBanner02,
        title: {
          en: 'Alpha Southasia: Best Wealth Management Bank in Thailand',
          th: 'Alpha Southasia: Best Wealth Management Bank in Thailand',
        },
        description: undefined,
      }
    ],
  },
  {
    year: 2021,
    items: [
      {
        id: 1,
        banner: imgBanner03,
        title: {
          en: 'Asian Private Banker: the Best Domestic Private Bank Thailand',
          th: 'Asian Private Banker: the Best Domestic Private Bank Thailand',
        },
        description: {
          en: 'This accolade recognizes exceptional wealth management practices, including insightful investment strategies and remarkable product offerings that cater to comprehensive domestic and international requirements. These accomplishments are driven by a steadfast commitment to the Philosophy of Wealth, which guides our business operations.',
          th: 'รางวัลแสดงถึงความเป็นเลิศในการบริหารความมั่งคั่งรวมถึงการมีคำตอบทางการลงทุนและผลิตภัณฑ์ที่โดดเด่น ครอบคลุมทั้งในประเทศและต่างประเทศแบบครบวงจร ภายใต้ปรัชญาการดำเนินธุรกิจ Philosophy of Wealth',
        }
      },
      {
        id: 2,
        banner: imgBanner04,
        title: {
          en: 'Asiamoney Awards: Best for Asset Management / Discretionary Portfolio Management in Thailand',
          th: 'Asiamoney Awards: Best for Asset Management / Discretionary Portfolio Management in Thailand',
        },
        description: {
          en: 'The accolade represents the outstanding performance of Mandate Service products, which enhance investment services and propel business growth, resulting in a revenue surpassing 57 million US dollars. This has contributed to a remarkable revenue growth of over 32.3% in the year 2021',
          th: 'รางวัลแสดงถึงความเป็นเลิศของผลิตภัณฑ์ Mandate Service ที่ช่วยยกระดับบริการการลงทุนและขับเคลื่อนธุรกิจจนสร้างรายได้กว่า 57 ล้านดอลดาร์สหรัฐฯ เป็นผลให้รายได้เติบโตกว่า 32.3%  ในปี 2021',
        },
      },
    ],
  },
  {
    year: 2020,
    items: [
      {
        id: 1,
        banner: imgBanner05,
        title: {
          en: 'Asian Money: Thailand’s Best Domestic Private Bank',
          th: 'Asian Money: Thailand’s Best Domestic Private Bank',
        },
        description:  {
          en: 'Phatra had a great 2019, generating net new money of Bt98.2 billion ($3.1 billion), about 66% more than in the previous year. It added 1,774 clients, increasing its overall client base to 18,631 people. Its revenues grew Bt1.25 billion, up about 9.45% on the previous year.',
          th: 'บริษัทฯ มีเงินภายใต้การบริหารเพิ่มถึงขึ้นถึง  9.82 หมื่นล้านบาท ในปี 2019 โดยจำนวนลูกค้าเติมโตขึ้นจากปีก่อน 1.774 คน เป็น 18,631 คน และสร้างรายได้เติบโตกว่า 1.25 พันล้านบาท ซึ่งเติบโตจากปีก่อนถึง 9.45%',
        },
      },
    ],
  },
  {
    year: 2019,
    items: [
      {
        id: 1,
        banner: imgBanner06,
        title: {
          en: 'Asian Money:  Thailand’s Best Private Bank for Ultra High Net Worth Clients',
          th: 'Asian Money:  Thailand’s Best Private Bank for Ultra High Net Worth Clients',
        },
        description: {
          en: 'Assets under management hit a high of Bt525 billion ($17 billion), an 11% increase from the previous year. For 2018, Phatra notched up total revenue of Bt1.1 billion, a rise of 19.1%.',
          th: 'เงินภายใต้การบริหารแตะระดับ 5.25 แสนล้านบาท สูงขึ้นกว่า 11% จากปีก่อนหน้า โดยในปี 2018 บริษัทฯ สร้างรายได้ถึง 1.1 พันล้านบาท ปรับเพิ่มขึ้นถึง 19.1% จาปีก่อนหน้า',
        },
      },
    ],
  },
  {
    year: 2017,
    items: [
      {
        id: 1,
        banner: imgBanner07,
        title: {
          en: 'Euromoney: Thailand’s Best Private Bank for High Net Worth Clients',
          th: 'Euromoney: Thailand’s Best Private Bank for High Net Worth Clients',
        },
        description: undefined,
      },
      {
        id: 1,
        banner: imgBanner08,
        title: {
          en: 'Asian Private Banker: the Best Domestic Private Bank Thailand',
          th: 'Asian Private Banker: the Best Domestic Private Bank Thailand',
        },
        description: undefined,
      },
      {
        id: 1,
        banner: imgBanner09,
        title: {
          en: 'Alpha Southasia: Thailand’s Best Private Wealth Management Bank',
          th: 'Alpha Southasia: Thailand’s Best Private Wealth Management Bank',
        },
        description: undefined,
      },
    ],
  },
  {
    year: 2016,
    items: [
      {
        id: 1,
        banner: imgBanner10,
        title: {
          en: 'Finance Asia: 20-year of excellence Platinum Award for Best Domestic Private Bank',
          th: 'Finance Asia: 20-year of excellence Platinum Award for Best Domestic Private Bank',
        },
        description: undefined,
      }
    ]
  }
];