import React from 'react';
import cx from 'classnames';

import StandardLayout from '@/views/common/layouts/StandardLayout';
import EventHeadSection from '@/views/event/pages/EventPage/sections/EventHeadSection';
import EventLocationSection from '@/views/event/pages/EventPage/sections/EventLocationSection';

import s from './styles.module.scss';


const EventPage = (props = {}) => {

  return (
    <StandardLayout>
      <EventHeadSection/>
      <EventLocationSection/>
    </StandardLayout>
  );

};

export default EventPage;