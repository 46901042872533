import Api from "../api-core";

export const getHomePageBanner = async (
  publishDateTime,
  status
) => {
  const formData = {
    PublishDateTime: publishDateTime,
    Status: status,
  };
  try {
    const result = await Api().post(
      `${process.env.REACT_APP_GET_HOME_PAGE_BANNER}`,
      formData
    );
    return result;
  } catch (error) {
    // Handle errors
    console.error("Error:", error);
  }
};

export default { getHomePageBanner };
