import Api from "../api-core";

export const getInsightDetail = async (id) => {
  try {
    const result = await Api().get(
      `${process.env.REACT_APP_WEALTH_BASE_API}/Insights/${id}`
    );
    return result.data;
  } catch (error) {
    // Handle errors
    throw error; 
  }
};

export default {getInsightDetail};
