import React from 'react';
import cx from 'classnames';

import s from './styles.module.scss';

import useLocale from '@/hooks/useLocale';
import ApplicationFeature from "../../components/ApplicationFeature";

import imgMacbook from '../../../../../../assets/images/page-why-us-digital-section-platform/macbook.png';
import imgTablet from '../../../../../../assets/images/page-why-us-digital-section-platform/tablet.png';


const WhyUsDigitalPlatformSection = (props = {}) => {

  const {t, locale} = useLocale('page-why-us-digital-section-platform');

  return (
    <section className={cx('relative', 'pt-0', 'sm:pt-16', s.container)}>
      <div className={cx('content', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <div className={cx('hidden', 'sm:block', 'bg-center', 'bg-contain', 'bg-no-repeat', s.macbook)}
             style={{backgroundImage: `url(${imgMacbook})`}}/>
        <div className={cx(
          'block', 'sm:hidden', 'w-full', 'bg-center', 'bg-contain', 'bg-no-repeat',
          s.tablet
        )} style={{backgroundImage: `url(${imgTablet})`}}/>
        <div className={cx('mt-10', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
          <hr/>
          <h2 className={cx('my-6', 'text-primary-900', 'text-center')}>{t('title')}</h2>
          <hr/>
        </div>
        <div className={cx(
          'relative', 'py-6', 'space-x-0', 'sm:space-x-8',
          'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-start'
        )}>
          <div className={cx(
            'flex-1', 'flex-shrink-0',
            'flex', 'flex-col', 'justify-start', 'items-start'
          )}>
            <h2 className={cx('text-primary-900', 'text-center')}>{t('kpx.title')}</h2>
            <p className={cx('mt-2', 'self-stretch', 'text-xl', 'text-primary-900')}>
              {t('kpx.description')}
            </p>
            <a target="_blank" href="https://phatrax.kkpfg.com/"
               className={cx('mt-4', 'btn', 'btn-outlined', 'btn-outlined-contrast', 'btn-long')}>
              <span className={cx('tracking-wide')}>Learn More</span>
            </a>
            <div className={cx(
              'hidden', 'sm:block',
              'absolute', 'left-0', 'bottom-0', 'pointer-events-none',
              'bg-center', 'bg-contain', 'bg-no-repeat',
              s.tablet
            )} style={{backgroundImage: `url(${imgTablet})`}}/>
            <div className={cx(
              'block', 'sm:hidden',
              'absolute', 'left-0', 'bottom-0', 'pointer-events-none',
              'bg-center', 'bg-contain', 'bg-no-repeat',
              s.macbook
            )} style={{backgroundImage: `url(${imgMacbook})`}}/>
          </div>
          <div className={cx(
            'flex-1', 'flex-shrink-0',
            'flex', 'flex-col', 'justify-start', 'items-start'
          )}>
            <h2 className={cx('text-primary-900', 'text-center')}>{t('offshoreAndEtfs.title')}</h2>
            <p className={cx('mt-2', 'self-stretch', 'text-xl', 'text-primary-900')}>
              {t('offshoreAndEtfs.description')}
            </p>
            <ul className={cx('mt-6', 'self-stretch', 'space-y-4', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
              <li className={cx('relative')}>
                <ApplicationFeature title={t('offshoreAndEtfs.feature01')}/>
              </li>
              <li className={cx('relative')}>
                <ApplicationFeature title={t('offshoreAndEtfs.feature02')}/>
              </li>
              <li className={cx('relative')}>
                <ApplicationFeature title={t('offshoreAndEtfs.feature03')}/>
              </li>
              <li className={cx('relative')}>
                <ApplicationFeature title={t('offshoreAndEtfs.feature04')}/>
              </li>
            </ul>
            <a target="_blank" href="https://gis.kkpfg.com/"
               className={cx('mt-4', 'btn', 'btn-outlined', 'btn-outlined-contrast', 'btn-long')}>
              <span className={cx('tracking-wide')}>Log in</span>
            </a>
          </div>
        </div>
        <div className={cx('flex-1', 'flex-shrink-0')}/>
      </div>
    </section>
  );

};

export default WhyUsDigitalPlatformSection;