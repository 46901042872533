import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider} from 'react-router-dom';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

import '@/utils/i18n';
import reportWebVitals from './reportWebVitals';
import {ScreenSizeProvider} from '@/providers/ScreenSizeProvider';
import Error404Page from '@/views/error/pages/Error404Page';
import LoginPage from '@/views/login/pages/LoginPage';
import LegalAnnouncementPage from '@/views/legal/pages/LegalAnnouncementPage';
import LegalInfoPage from '@/views/legal/pages/LegalInfoPage';
import PrivacyStatementPage from '@/views/legal/pages/PrivacyStatementPage';
import PrivacyNoticePage from '@/views/legal/pages/PrivacyNoticePage';
import SolutionStocksPage from '@/views/solution/pages/SolutionStocksPage';
import HomePage from '@/views/home/pages/HomePage';
import ExpertOverviewPage from '@/views/experts/pages/ExpertOverviewPage';
import ExpertDetailsPage from '@/views/experts/pages/ExpertDetailsPage';
import InsightPage from '@/views/insight/pages/InsightPage';
import InsightDetailsPage from '@/views/insight/pages/InsightDetailsPage';
import WhyUsExpertisePage from '@/views/why-us/pages/WhyUsExpertisePage';
import WhyUsDigitalPage from '@/views/why-us/pages/WhyUsDigitalPage';
import WhyUsOurServicesPage from '@/views/why-us/pages/WhyUsOurServicesPage';
import WhyUsLifestylePrivilegePage from '@/views/why-us/pages/WhyUsLifestylePrivilegePage';
import WhyUsPrivilegeClubPage from '@/views/why-us/pages/WhyUsPrivilegeClubPage';
import GoalAccumulationPage from '@/views/goal/pages/GoalAccumulationPage';
import GoalFamilyPage from '@/views/goal/pages/GoalFamilyPage';
import GoalBusinessPage from '@/views/goal/pages/GoalBusinessPage';
import GoalLifeStylePage from '@/views/goal/pages/GoalLifeStylePage';
import GoalRetirementPage from '@/views/goal/pages/GoalRetirementPage';
import SolutionFixedIncomePage from '@/views/solution/pages/SolutionFixedIncomePage';
import SolutionMutualFundsPage from '@/views/solution/pages/SolutionMutualFundsPage';
import SolutionMandateServicePage from '@/views/solution/pages/SolutionMandateServicePage';
import SolutionStructuredProductPage from '@/views/solution/pages/SolutionStructuredProductPage';
import SolutionPrivateMarketPage from '@/views/solution/pages/SolutionPrivateMarketPage';
import SolutionCashPage from '@/views/solution/pages/SolutionCashPage';
import SolutionLombardLoanPage from '@/views/solution/pages/SolutionLombardLoanPage';
import SolutionMortgageLoanPage from '@/views/solution/pages/SolutionMortgageLoanPage';
import SolutionCommercialLoanPage from '@/views/solution/pages/SolutionCommercialLoanPage';
import SolutionPropertyFinancingPage from '@/views/solution/pages/SolutionPropertyFinancingPage';
import SolutionFamilyWealthPage from '@/views/solution/pages/SolutionFamilyWealthPage';
import SolutionRealEstatePage from '@/views/solution/pages/SolutionRealEstatePage';
import SolutionFinancialPlanningPage from '@/views/solution/pages/SolutionFinancialPlanningPage';
import SolutionInsurancePage from '@/views/solution/pages/SolutionInsurancePage';
import SolutionInvestmentBankingPage from '@/views/solution/pages/SolutionInvestmentBankingPage';
import SolutionApproachInvestingPage from '@/views/solution/pages/SolutionApproachInvestingPage';
import SolutionApproachLendingPage from '@/views/solution/pages/SolutionApproachLendingPage';
import SolutionApproachPlanningPage from '@/views/solution/pages/SolutionApproachPlanningPage';
import SolutionApproachCioOfficePage from '@/views/solution/pages/SolutionApproachCioOfficePage';
import SolutionApproachInvestmentSolutionPage from '@/views/solution/pages/SolutionApproachInvestmentSolutionPage';
import SolutionApproachFinancialConsultantPage from '@/views/solution/pages/SolutionApproachFinancialConsultantPage';

import '@/assets/scss/styles.scss';
import Error404RedirectPage from '@/views/error/pages/Error404RedirectPage';
import EventPage from '@/views/event/pages/EventPage';


gsap.registerPlugin(ScrollTrigger);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Navigate to="/th"/>}/>
      <Route path="/:language" element={<HomePage/>}/>
      <Route path="/:language/404" element={<Error404Page/>}/>
      <Route path="/:language/404/redirect" element={<Error404RedirectPage/>}/>
      <Route path="/:language/login" element={<LoginPage/>}/>
      <Route path="/:language/experts/overview" element={<ExpertOverviewPage/>}/>
      <Route path="/:language/experts/:id" element={<ExpertDetailsPage/>}/>
      <Route path="/:language/insight/overview" element={<InsightPage/>}/>
      <Route path="/:language/insight/:id" element={<InsightDetailsPage/>}/>
      <Route path="/:language/why-us/expertise" element={<WhyUsExpertisePage/>}/>
      <Route path="/:language/why-us/digital" element={<WhyUsDigitalPage/>}/>
      <Route path="/:language/why-us/lifestyle-privilege" element={<WhyUsLifestylePrivilegePage/>}/>
      <Route path="/:language/why-us/privilege-club" element={<WhyUsPrivilegeClubPage/>}/>
      <Route path="/:language/why-us/services" element={<WhyUsOurServicesPage/>}/>
      <Route path="/:language/legal/announcement" element={<LegalAnnouncementPage/>}/>
      <Route path="/:language/legal/info" element={<LegalInfoPage/>}/>
      <Route path="/:language/legal/privacy-statement" element={<PrivacyStatementPage/>}/>
      <Route path="/:language/legal/privacy-notice" element={<PrivacyNoticePage/>}/>
      <Route path="/:language/goal/wealth-accumulation" element={<GoalAccumulationPage/>}/>
      <Route path="/:language/goal/family-wealth-advisory" element={<GoalFamilyPage/>}/>
      <Route path="/:language/goal/retirement-planning" element={<GoalRetirementPage/>}/>
      <Route path="/:language/goal/business-advisory" element={<GoalBusinessPage/>}/>
      <Route path="/:language/goal/premium-lifestyle" element={<GoalLifeStylePage/>}/>
      <Route path="/:language/solution/stocks" element={<SolutionStocksPage/>}/>
      <Route path="/:language/solution/fixed-income" element={<SolutionFixedIncomePage/>}/>
      <Route path="/:language/solution/mutual-funds" element={<SolutionMutualFundsPage/>}/>
      <Route path="/:language/solution/mandate-service" element={<SolutionMandateServicePage/>}/>
      <Route path="/:language/solution/structured-product" element={<SolutionStructuredProductPage/>}/>
      <Route path="/:language/solution/private-market" element={<SolutionPrivateMarketPage/>}/>
      <Route path="/:language/solution/cash" element={<SolutionCashPage/>}/>
      <Route path="/:language/solution/lombard-loan" element={<SolutionLombardLoanPage/>}/>
      <Route path="/:language/solution/mortgage-loan" element={<SolutionMortgageLoanPage/>}/>
      <Route path="/:language/solution/property-financing" element={<SolutionPropertyFinancingPage/>}/>
      <Route path="/:language/solution/commercial-loan" element={<SolutionCommercialLoanPage/>}/>
      <Route path="/:language/solution/family-wealth" element={<SolutionFamilyWealthPage/>}/>
      <Route path="/:language/solution/real-estate" element={<SolutionRealEstatePage/>}/>
      <Route path="/:language/solution/financial-planning" element={<SolutionFinancialPlanningPage/>}/>
      <Route path="/:language/solution/insurance" element={<SolutionInsurancePage/>}/>
      <Route path="/:language/solution/investment-banking" element={<SolutionInvestmentBankingPage/>}/>
      <Route path="/:language/solution/investing" element={<SolutionApproachInvestingPage/>}/>
      <Route path="/:language/solution/lending" element={<SolutionApproachLendingPage/>}/>
      <Route path="/:language/solution/planning" element={<SolutionApproachPlanningPage/>}/>
      <Route path="/:language/solution/cio-office" element={<SolutionApproachCioOfficePage/>}/>
      <Route path="/:language/solution/investment-solution" element={<SolutionApproachInvestmentSolutionPage/>}/>
      <Route path="/:language/solution/financial-consultant"
             element={<SolutionApproachFinancialConsultantPage/>}/>
      <Route path="/:language/event/:id" element={<EventPage/>}/>
      <Route path="/:language/*" element={<Error404RedirectPage/>}/>
      <Route path="*" element={<Error404RedirectPage/>}/>
    </>
  )
  , { basename: process.env.PUBLIC_URL });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ScreenSizeProvider>
      <RouterProvider router={router}/>
    </ScreenSizeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
