import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';

import {useIsScreenSm} from '@/providers/ScreenSizeProvider';
import useLocale from '@/hooks/useLocale';
import solutionMenus from '@/views/common/layouts/StandardLayout/data/solution-menus';

import s from './styles.module.scss';

import imgArrow from '@/assets/images/layout-standard-section-header/chevron-right.svg';
import imgArrowLight from '@/assets/images/layout-standard-section-header/chevron-right-light.svg';


const SolutionMenu = (props = {}) => {

  const {visible, onClose} = props;

  const {locale} = useLocale('');

  const isScreenSm = useIsScreenSm();

  const parentRef = useRef(undefined);

  const [subMenuVisible, setSubMenuVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(undefined);

  const currentItems = useMemo(() => {
    return currentIndex === undefined ? undefined : solutionMenus[currentIndex].items
  }, [currentIndex]);

  useEffect(() => {
    if (visible) {
      setSubMenuVisible(false);
      setCurrentIndex(undefined);
    }
  }, [visible]);

  useEffect(() => {
    if (!visible || !isScreenSm || !onClose) return;
    onClose();
  }, [visible, isScreenSm, onClose]);

  useEffect(() => {
    let handler;
    window.addEventListener('click', handler = event => {
      const {current: parent} = parentRef;
      if (!parent) return;
      const {target} = event;
      if (!target || parent.contains(target) || !onClose) return;
      onClose();
    });
    return () => window.removeEventListener('click', handler);
  }, [parentRef, onClose]);

  const onClickMenu = useCallback((event, index) => {
    event.preventDefault();
    event.stopPropagation();
    if (!subMenuVisible) setSubMenuVisible(true);
    setCurrentIndex(index);
  }, [subMenuVisible]);


  return (
    <div ref={parentRef} className={cx(
      'bg-white', 'overflow-hidden',
      'absolute', 'left-1/2', '-translate-x-1/2', 'bottom-0', 'translate-y-full',
      'flex', 'flex-row', 'justify-start', 'items-stretch',
      {'opacity-0': !visible, 'opacity-1': visible},
      {'pointer-events-none': !visible, 'pointer-events-auto': visible},
      {'w-[392px]': !subMenuVisible}, {'w-[760px]': subMenuVisible},
      {'transition-all': subMenuVisible, 'transition-opacity': !subMenuVisible},
      {'ease-bounce': subMenuVisible}, 'duration-700',
      s.container
    )}>
      <ul className={cx(
        'p-6', 'box-border', 'min-w-[392px]', 'space-y-4',
        'flex-shrink-1', 'flex', 'flex-col', 'justify-start', 'items-stretch',
      )}>
        {
          solutionMenus.map((menu, index) => {
            const {id, title, url} = menu;
            return (
              <li key={id} className={cx(
                'py-2', 'flex', 'flex-col', 'justify-start', 'items-start', 'cursor-pointer',
                {'text-legacy-purple': !subMenuVisible || index === currentIndex},
                {'text-primary-100': subMenuVisible && index !== currentIndex},
              )} onClick={event => onClickMenu(event, index)}>
                <Link to={`/${locale}${url}`} className={cx(
                  'space-x-4', 'flex', 'flex-row', 'justify-start', 'items-center',
                  'transition-all', 'ease-in-out', 'duration-200',
                  'text-sm', 'uppercase',
                  'font-serif', 'font-bold',
                )} onMouseOver={event => onClickMenu(event, index)}>
                  <span>{title[locale]}</span>
                  <i className={cx('icon-chevron-right')}/>
                </Link>
              </li>
            );
          })
        }

      </ul>
      <ul className={cx(
        'pr-6', 'py-6', 'box-border', 'min-w-[368px]', 'space-y-2',
        'flex-shrink-1', 'flex', 'flex-col', 'justify-start', 'items-stretch',
      )}>
        {
          currentItems === undefined ? null : currentItems.map(item => {
            const {id, title, url} = item;
            return (
              <li key={id}>
                <Link to={url ? `/${locale}${url}` : '#'}>
                  <span className={cx(
                    'text-primary-400', 'hover:text-primary-700',
                    'text-sm', 'uppercase', 'font-normal',
                  )}>
                    {title[locale]}
                  </span>
                </Link>
              </li>
            );
          })
        }
      </ul>
    </div>
  );

};

export default SolutionMenu;