import React, {Fragment} from "react";

export const splitLines = (text = '') => {
  if (!text) return text;
  const textParts = (text || '').split('\n');
  return textParts.map((textPart, index, array) => {
    const id = index + 1;
    const isLastItem = index === array.length - 1;
    return (
      <Fragment key={id}>
        <span>{textPart}</span>
        {!isLastItem ? <br/> : null}
      </Fragment>
    );
  });
};

  export const formatDescription = (desc) => {
    let formatted = desc.replace(/\t/g, '\u00A0\u00A0\u00A0\u00A0');
    formatted = formatted
      .split(/\n\s*\n/)
      .map(paragraph => `<p>${paragraph.replace(/\n/g, '<br>')}</p>`)
      .join('');
    return formatted;
  };