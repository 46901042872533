import React, {useCallback, useMemo} from 'react';
import cx from 'classnames';
import {observer} from 'mobx-react';

import {splitLines} from '@/utils/html-utils';
import {useIsScreenSm} from '@/providers/ScreenSizeProvider';
import {useContactStore} from '@/views/contact/stores/contact-context';
import useLocale from '@/hooks/useLocale';
import ContactLayout from '@/views/contact/layouts/ContactLayout';

import s from './styles.module.scss';


const ServicesSection = observer((props = {}) => {

  const {t, locale} = useLocale('dialog-contact');

  const isScreenSm = useIsScreenSm();

  const contactStore = useContactStore();
  const {serviceIndices} = contactStore || {};

  const onClickNext = useCallback(index => {
    contactStore.next();
  }, [contactStore])

  const onSelect = useCallback(index => {
    contactStore.addOrRemoveServiceIndex(index);
  }, [contactStore])

  const footerComponent = useMemo(() => {
    return (
      <div className={cx('flex', 'flex-row', 'justify-start', 'items-stretch', s.footer)}>
        <button className={cx('flex-1', 'btn', 'btn-submit', 'btn-next', 'tracking-wider')}
                disabled={serviceIndices.length === 0}
                onClick={onClickNext}>
          <span>Next Question</span>
        </button>
      </div>
    );
  }, [onClickNext, serviceIndices]);

  return (
    <ContactLayout footerComponent={footerComponent}>
      <div className={cx(
        'flex-1', 'px-4', 'sm:px-8',
        'flex', 'flex-col', 'sm:flex-row', 'justify-center', 'items-stretch',
        s.container
      )}>
        <div className={cx(
          'pb-4', 'sm:py-6', 'box-border', 'flex-1', 'space-y-4', 'sm:space-y-6',
          'flex', 'flex-col', 'justify-center', 'items-center'
        )}>
          <h2 className={cx(
            'font-semibold', 'text-center', 'text-legacy-purple',
            {'font-sans': locale !== 'en', 'font-serif': locale === 'en'},
          )}>
            {splitLines(t('services.title'))}
          </h2>
          {
            !isScreenSm ? null : (
              <p className={cx('text-primary-400', 'font-normal', 'leading-[1.2]', 'text-center')}>
                Please Select
                <br/>
                (You can select more than 1)
              </p>
            )
          }
          <hr className={cx('hidden', 'sm:block')}/>
        </div>
        <hr className={cx('block', 'sm:hidden')}/>
        <div className={cx('hidden', 'sm:block', 'w-8')}/>
        <div className={cx(
          'sm:flex-1', 'box-border', 'py-4', 'sm:py-6', 'space-y-4',
          'flex', 'flex-col', 'justify-center', 'items-stretch',
        )}>
          {
            isScreenSm ? null : (
              <p className={cx('text-primary-400', 'font-normal', 'leading-[1.2]', 'text-center')}>
                Please Select (You can select more than 1)
              </p>
            )
          }
          <button className={cx(
            'uppercase', 'btn', 'sm:mt-0',
            {'btn-outlined': !serviceIndices.includes(0), 'btn-submit': serviceIndices.includes(0)},
          )} onClick={() => onSelect(0)}>
            {t('services.options.1')}
          </button>
          <button className={cx(
            'uppercase', 'btn',
            {'btn-outlined': !serviceIndices.includes(1), 'btn-submit': serviceIndices.includes(1)},
          )} onClick={() => onSelect(1)}>
            {t('services.options.2')}
          </button>
          <button className={cx(
            'uppercase', 'btn',
            {'btn-outlined': !serviceIndices.includes(2), 'btn-submit': serviceIndices.includes(2)},
          )} onClick={() => onSelect(2)}>
            {t('services.options.3')}
          </button>
          <button className={cx(
            'uppercase', 'btn',
            {'btn-outlined': !serviceIndices.includes(3), 'btn-submit': serviceIndices.includes(3)},
          )} onClick={() => onSelect(3)}>
            {t('services.options.4')}
          </button>
        </div>
      </div>
    </ContactLayout>
  );

});

export default ServicesSection;