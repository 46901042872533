import React, {useEffect, useMemo, useState} from 'react';
import cx from 'classnames';
import Select from 'react-select';
import ContentLoader from 'react-content-loader'
import tabs from '@/views/legal/data/tabs';
import useLocale from '@/hooks/useLocale';
import StandardLayout from "@/views/common/layouts/StandardLayout";
import BannerTitleSection from "@/views/common/section/BannerTitleSection";
import TabList from "@/views/common/components/TabList";
import AnnouncementEntry from "./components/AnnouncementEntry";
import getAnnouncement from '@/apis/Announcement/Announcement';
import s from './styles.module.scss';

import imgNoContent from '@/assets/images/common/no-content.png';
import imgBanner from '@/assets/images/page-legal/banner.png';


const LegalAnnouncementPage = (props = {}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const {t, locale} = useLocale('page-legal-announcement');

  const [sortOption, setSortOption] = useState({value: 'latest', label: t('sort.options.latest')});
  const [announcementList, setAnnouncementList] = useState([]);
  const [isHasMore, setIsHasMore] = useState(false);
  const [isNoData, setIsNoData] = useState(false);
  const [isError, setisError] = useState(false);
  const [paramSearch, setParamSearch] = useState({
    pageNo: 1,
    orderBy: null 
  });

  const sortOptions = useMemo(() => {
    let options = [
      {value: 'latest', label: t('sort.options.latest')},
      {value: 'oldest', label: t('sort.options.oldest')},
  ]
    if(sortOption.value === 'latest'){
      setSortOption(options[0])
    }else{
      setSortOption(options[1])
    }
    return options}, [t])

  useEffect(() => {
    const fetchData = async () => {
      try {
        let newParamSearch = paramSearch
        newParamSearch.pageNo = 1
        setParamSearch(newParamSearch)
        // let searchAnnouncement = newParamSearch.orderBy !== 'oldest' ? page1Latest : page1Oldest
        const {data: searchAnnouncement} = await getAnnouncement(paramSearch);
        if(searchAnnouncement.Announcement.length === 0){
          setIsNoData(true)
        }else {
          setAnnouncementList(searchAnnouncement.Announcement)
          if(searchAnnouncement.Pagination.TotalPage === searchAnnouncement.Pagination.PageNo){
            setIsHasMore(false)
          } else {
            setIsHasMore(true)
          }
        }
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setisError(true)
      }
    };
    fetchData();
  }, []); // Run once

  useEffect(() => {
    if (!sortOptions || sortOption) return;
    const [firstSortOption] = sortOptions;
    if (!firstSortOption.label) return;
    setSortOption(firstSortOption);
  }, [sortOptions, sortOption]);

  const localisedTabs = useMemo(() => {
    return tabs.map(tab => {
      let {url = '', ...rest} = tab;
      if (url.indexOf('https://') === 0) {
        url = url.replace('{{locale}}', locale);
      } else {
        url = `/${locale}${url}`;
      }
      return {...rest, url};
    });
  }, [locale]);

  const onChangeSortOption = async (sortOption) => {
    setIsLoading(true)
    setSortOption(sortOption);
    let newParamSearch = paramSearch
    newParamSearch.pageNo = 1
    newParamSearch.orderBy = sortOption.value
    setParamSearch(newParamSearch)
    let {data : searchAnnouncement} = await getAnnouncement(paramSearch)
    // let searchAnnouncement = newParamSearch.orderBy !== 'oldest' ? page1Latest : page1Oldest
    setAnnouncementList(searchAnnouncement.Announcement)
    if(searchAnnouncement.Pagination.TotalPage === searchAnnouncement.Pagination.PageNo){
      setIsHasMore(false)
    } else {
      setIsHasMore(true)
    }
    setIsLoading(false)
  };

  const onClickMore = async () => {
    setIsMoreLoading(true)
    try {
      let newParamSearch = paramSearch
      newParamSearch.pageNo += 1
      let {data : searchAnnouncement} = await getAnnouncement(newParamSearch)
      // let searchAnnouncement = newParamSearch.orderBy !== 'oldest' ? page2Latest : page2Oldest
      console.log(JSON.stringify(searchAnnouncement))
      setAnnouncementList([...announcementList].concat(searchAnnouncement.Announcement))
      if(searchAnnouncement.Pagination.TotalPage === searchAnnouncement.Pagination.PageNo){
        setIsHasMore(false)
      }
      setParamSearch(newParamSearch)
    } catch (error) {
      setisError(true)
    }
    setIsMoreLoading(false)
  }

  const onClickRetry = async () => {
    setIsLoading(true)
    try {
      // let searchAnnouncement = paramSearch.orderBy !== 'oldest' ? page1Latest : page1Oldest
      let {data : searchAnnouncement} = await getAnnouncement(paramSearch)
      if(searchAnnouncement.Announcement.length === 0){
        setIsNoData(true)
      }else {
        setAnnouncementList([...announcementList].concat(searchAnnouncement.Announcement))
        if(searchAnnouncement.Pagination.TotalPage === searchAnnouncement.Pagination.PageNo){
          setIsHasMore(false)
        } else {
          setIsHasMore(true)
        }
      }
      setIsLoading(false)
      setisError(false)
    } catch (error) {
      setIsLoading(false)
      setisError(true)
    }
  }

  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch', s.container)}>
        <BannerTitleSection banner={imgBanner} title="Announcement"/>
        <div className={cx('xl:py-6', 'm:py-4', 'py-2', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
          <TabList items={localisedTabs} selectedIndex={0}/>
        </div>
        <section className={cx('m:pt-12', 'sm:pt-8', 'pt-6', 'overflow-visible')}>
          <div className={cx(
            'content', 'space-x-6',
            'flex', 'flex-row', 'justify-end', 'items-center'
          )}>
            {
              isLoading ? 
              <>
                <ContentLoader viewBox="0 0 400 20">
                  <rect x="320" y="0" rx="4" ry="4" width="80" height="20" />
                </ContentLoader>
              </> : 
              isError ? null :
              <>
                <span className={cx('block', 'text-legacy-purple', 'text-xl', 'font-normal')}>{t('sort.sortBy')}</span>
                <Select
                  options={sortOptions}
                  value={sortOption}
                  isSearchable={false}
                  onChange={onChangeSortOption}
                  className={cx('relative', 'outline-none', 'min-w-[160px]', 'uppercase', 'text-primary-900', 'font-medium')}
                  classNames={{
                    indicatorSeparator: () => cx(s['select-sep']),
                    control: ({isFocused}) => cx(
                      s['select'],
                      {[s['select-focused']]: isFocused},
                    ),
                    option: ({isSelected, isFocused}) => cx(
                      s['select-option'],
                      {[s['select-option-focused']]: isFocused},
                      {[s['select-option-selected']]: isSelected},
                    )
                  }}/>
              </>
            }
          </div>
        </section>
        <section className={cx('relative', 'm:my-12', 'my-8')}>
          <div className={cx(
            'content',
            'flex', 'flex-col'
          )}>
          { 
            isLoading ? <>
            <ContentLoader viewBox="0 0 380 55">
              {/* Only SVG shapes */}
              <rect x="0" y="0" rx="5" ry="5" width="55" height="55" />
              <rect x="70" y="0" rx="4" ry="4" width="150" height="13" />
              <rect x="70" y="20" rx="4" ry="4" width="310" height="13" />
              <rect x="70" y="40" rx="3" ry="3" width="310" height="13" />
            </ContentLoader>
            <br/>
            <ContentLoader viewBox="0 0 380 55">
              {/* Only SVG shapes */}
              <rect x="0" y="0" rx="5" ry="5" width="55" height="55" />
              <rect x="70" y="0" rx="4" ry="4" width="150" height="13" />
              <rect x="70" y="20" rx="4" ry="4" width="310" height="13" />
              <rect x="70" y="40" rx="3" ry="3" width="310" height="13" />
            </ContentLoader>
            <br/>
            <ContentLoader viewBox="0 0 380 55">
              {/* Only SVG shapes */}
              <rect x="0" y="0" rx="5" ry="5" width="55" height="55" />
              <rect x="70" y="0" rx="4" ry="4" width="150" height="13" />
              <rect x="70" y="20" rx="4" ry="4" width="310" height="13" />
              <rect x="70" y="40" rx="3" ry="3" width="310" height="13" />
            </ContentLoader>
            <br/>
            <ContentLoader viewBox="0 0 600 40">
              <rect x="250" y="0" rx="5" ry="5" width="100" height="40" />
            </ContentLoader>
            </> : 
            isNoData ? <>
              <div className={cx('py-20', 'flex', 'flex-col')}>
                <img className={cx('h-[80px]', 'w-[80px]', 'self-center')} src={imgNoContent}></img>
                <h3 className={cx('py-4', 'text-primary-200', 'text-center', 'font-sans', 'font-medium', 'text-xl', 'sm:text-2xl')}>
                  {'No Content To Display.'}
                </h3>
              </div>
            </> :
            isError ? <>
              <div className={cx('py-20', 'flex', 'flex-col')}>
                <img className={cx('h-[80px]', 'w-[80px]', 'self-center')} src={imgNoContent}></img>
                <h3 className={cx('py-2', 'text-primary-200', 'text-center', 'font-sans', 'font-medium', 'text-xl', 'sm:text-2xl')}>
                  {"We're sorry, but an unexpected error occurred."}
                </h3>
                <h3 className={cx('pb-7', 'text-primary-200', 'text-center', 'font-sans', 'font-medium', 'text-xl', 'sm:text-2xl')}>
                  {"Please try again later."}
                </h3>
                <button onClick={onClickRetry} className={cx(
                  'self-center', 'bg-white', 'btn', 'btn-outlined', 'btn-outlined-light', 'btn-m'
                  )}>
                    <span className={cx('font-medium')}>TRY AGAIN</span>
                </button>
              </div>
            </> :
            <><ul className={cx('space-y-8')}>
              {
                announcementList.map(announcement => {
                  const {AnnouncementId} = announcement;
                  return (
                    <li key={AnnouncementId}>
                      <AnnouncementEntry announcement={announcement}/>
                    </li>
                  );
                })
              }
            </ul>
              {
                isMoreLoading ? 
                <>
                  <br/>
                  <ContentLoader viewBox="0 0 380 55">
                    {/* Only SVG shapes */}
                    <rect x="0" y="0" rx="5" ry="5" width="55" height="55" />
                    <rect x="70" y="0" rx="4" ry="4" width="150" height="13" />
                    <rect x="70" y="20" rx="4" ry="4" width="310" height="13" />
                    <rect x="70" y="40" rx="3" ry="3" width="310" height="13" />
                  </ContentLoader>
                  <br/>
                  <ContentLoader viewBox="0 0 380 55">
                    {/* Only SVG shapes */}
                    <rect x="0" y="0" rx="5" ry="5" width="55" height="55" />
                    <rect x="70" y="0" rx="4" ry="4" width="150" height="13" />
                    <rect x="70" y="20" rx="4" ry="4" width="310" height="13" />
                    <rect x="70" y="40" rx="3" ry="3" width="310" height="13" />
                  </ContentLoader>
                  <br/>
                  <ContentLoader viewBox="0 0 380 55">
                    {/* Only SVG shapes */}
                    <rect x="0" y="0" rx="5" ry="5" width="55" height="55" />
                    <rect x="70" y="0" rx="4" ry="4" width="150" height="13" />
                    <rect x="70" y="20" rx="4" ry="4" width="310" height="13" />
                    <rect x="70" y="40" rx="3" ry="3" width="310" height="13" />
                  </ContentLoader>
                  <br/>
                  <ContentLoader viewBox="0 0 380 55">
                    {/* Only SVG shapes */}
                    <rect x="0" y="0" rx="5" ry="5" width="55" height="55" />
                    <rect x="70" y="0" rx="4" ry="4" width="150" height="13" />
                    <rect x="70" y="20" rx="4" ry="4" width="310" height="13" />
                    <rect x="70" y="40" rx="3" ry="3" width="310" height="13" />
                  </ContentLoader>
                  <br/>
                </> : null
              }
              { 
                isHasMore ? <button onClick={onClickMore} className={cx(
            'self-center', 'bg-white', 'btn', 'btn-outlined', 'btn-outlined-light', 'btn-m'
                )}>
                  <span className={cx('font-medium')}>{t('button.more')}</span>
                </button> : null 
              }
            </>
          }
          </div>

        </section>
      </div>
    </StandardLayout>
  );

};

export default LegalAnnouncementPage;