export default [
  {
    id: 1,
    title: 'benefits.benefit01.title',
    description: 'benefits.benefit01.description',
  },
  {
    id: 2,
    title: 'benefits.benefit02.title',
    description: 'benefits.benefit02.description',
  },
  {
    id: 3,
    title: 'benefits.benefit03.title',
    description: 'benefits.benefit03.description',
  }
];