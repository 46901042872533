import React, {useCallback, useEffect, useState} from 'react';
import cx from 'classnames';
import {Link, useLocation, useNavigate} from 'react-router-dom';

import useLocale from '@/hooks/useLocale';
import SolutionMenu from '@/views/common/layouts/StandardLayout/components/SolutionMenu';

import s from './styles.module.scss';

import imgLogo from '@/assets/images/common/logo-long.svg';


const HeaderSection = (props = {}) => {

  const {locale} = useLocale('');

  const [solutionMenuVisible, setSolutionMenuVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const {hash} = location;
    if (hash !== '#menu') {
      setMobileMenuVisible(false);
    }
  }, [location]);

  // Change Locale
  const onChangeLocale = useCallback(() => {
    const newLocale = locale === 'en' ? 'th' : 'en';
    const {pathname: path, hash} = location;
    const localePath = path.replace(new RegExp(`^/${locale}`), `/${newLocale}`);
    navigate(`${localePath}${hash || ''}`, {state: {noScroll: true}});
  }, [locale, location, navigate]);

  const onClickSolutions = useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
    setSolutionMenuVisible(true);
  }, []);

  const onCloseSolutions = useCallback(() => {
    setSolutionMenuVisible(false);
  }, []);

  const onClickHamburger = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    const visible = !mobileMenuVisible;
    const {pathname: path} = location;
    navigate(visible ? '#menu' : path);
    setMobileMenuVisible(!mobileMenuVisible);
  }, [mobileMenuVisible, navigate, location]);

  return (
    <>
      <section className={cx(
        'py-4', 'sm:pt-6', 'sm:pb-5', 'sm:space-y-6', 'z-50',
        'fixed', 'sm:relative', 'left-0', 'top-0', 'w-full',
        'overflow-visible', 'bg-white',
        s.container
      )}>
        <SolutionMenu visible={solutionMenuVisible} onClose={onCloseSolutions}/>
        <div className={cx(
          'content', 'space-y-6',
          'flex', 'flex-col', 'justify-start', 'items-stretch',
        )}>
          <div className={cx('flex', 'flex-row', 'justify-between', 'items-center')}>
            <div className={cx('space-x-3', 'sm:space-x-0', 'flex', 'flex-row', 'justify-start', 'items-center')}>
              <a className={cx(
                'sm:hidden', '-mx-2', 'text-legacy-purple',
                'max-w-6', 'max-h-6', 'overflow-hidden',
                'flex', 'flex-col', 'justify-center', 'items-center',
              )} href="#" onClick={onClickHamburger}>
                <i className={cx(
                  'text-3xl',
                  {'icon-hamburger': !mobileMenuVisible},
                  {'icon-cross': mobileMenuVisible}
                )}/>
              </a>
              <Link to={`/${locale}`}>
                <img className={cx('block', s.logo)} alt="Logo" src={imgLogo}/>
              </Link>
            </div>
            <ul className={cx(
              'flex', 'flex-row', 'justify-start', 'items-center', 'space-x-4', 'sm:space-x-8',
              'text-xs', 'font-normal',
              'text-legacy-purple', 'sm:text-primary-900'
            )}>
              <button className={cx('btn', 'btn-sm', 'btn-submit', 'tracking-wider')}
                      onClick={onChangeLocale}>
                {locale}
              </button>
             {/* <a id="loginheader-btn" className={cx(
                'uppercase', 'sm:normal-case', 'sm:text-base', 'sm:font-serif'
              )} href={`/${locale}/login`}>   */}

               <a id="loginheader-btn" className={cx(
                'uppercase', 'sm:normal-case', 'sm:text-base', 'sm:font-serif'
              )} href={`${ process.env.REACT_APP_LOGIN_URL}`}>
                Login 
              </a>
            </ul>
          </div>
          <ul className={cx(
            'self-center',
            'hidden', 'sm:flex', 'flex-row', 'justify-between', 'items-center',
            'font-serif', 'font-bold', 'tracking-wider', 'uppercase',
            s.menu
          )}>
            <li className={cx('link', 'link-primary-400')}>
              <Link to={`/${locale}`}>Home</Link>
            </li>
            <li className={cx('link', 'link-primary-400')}>
              <Link to={`/${locale}/why-us/expertise`}>Why Us</Link>
            </li>
            <li className={cx('link', 'link-primary-400')}>
              <a href="#" onClick={onClickSolutions}>Our Solutions</a>
            </li>
            <li className={cx('link', 'link-primary-400')}>
              <Link to={`/${locale}/insight/overview`}>Insights</Link>
            </li>
          </ul>
        </div>
      </section>
      <div className={cx('block', 'sm:hidden', 'h-14')}/>
    </>
  );

};

export default HeaderSection;