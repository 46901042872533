import React, {useEffect} from 'react';
import gsap from 'gsap';


const useHomeGoalAnimation = (triggerRef, titleRef) => {

  useEffect(() => {

    const ctx = gsap.context(() => {
      gsap.fromTo(
        titleRef.current,
        {
          opacity: 0,
          translateY: '200%',
        },
        {
          opacity: 1,
          translateY: '0',
          scrollTrigger: {
            trigger: triggerRef.current,
            start: "top center",
            end: "bottom bottom",
            scrub: 1,
          }
        },
      );
    });

    return () => ctx.revert();
  }, [triggerRef, titleRef]);

};

export default useHomeGoalAnimation;