import React from 'react';
import cx from 'classnames';


const CornerGreyBackground = (props = {}) => {

  return (
    <svg className={cx('absolute', 'left-0', 'top-0', 'w-full')} preserveAspectRatio="none"
         viewBox="0 0 1280 303" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="1280" height="303" fill="url(#paint0_linear_109_8435)"/>
      <defs>
        <linearGradient id="paint0_linear_109_8435" x1="765.002" y1="-395.002" x2="622.62" y2="94.062"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#756F8E" stopOpacity="0"/>
          <stop offset="1" stopColor="#EBEAF0"/>
        </linearGradient>
      </defs>
    </svg>
  );

};

export default CornerGreyBackground;