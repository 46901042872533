import React from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';

import s from './styles.module.scss';


const AssetEntry = (props = {}) => {

  const {capital, title} = props;

  return (
    <div className={cx(
      'w-full', 'aspect-asset-card', 'space-y-2',
      'flex', 'flex-col', 'justify-center', 'items-center',
      s.container
    )}>
      <h1 className={cx('text-sm-h1', 'text-primary-900')}>{capital}</h1>
      <b className={cx('text-legacy-purple')}>{title}</b>
    </div>
  );

};

AssetEntry.propTypes = {
  capital: PropTypes.number,
  title: PropTypes.string,
};

export default AssetEntry;