import React, {useMemo} from 'react';
import cx from 'classnames';

import benefits from './data/benefits';
import investment from './data/investment';
import relatedSolutions from './data/related-solutions';
import useInvestingBreadCrumbs from '@/views/solution/hooks/useInvestingBreadCrumbs';
import StandardLayout from "@/views/common/layouts/StandardLayout";
import BreadCrumbs from '@/views/common/components/BreadCrumbs';
import ContactSection from "@/views/common/section/ContactSection";
import SolutionRelatedSolutionsSection from '@/views/solution/sections/SolutionRelatedSolutionsSection';
import SolutionInvestmentSection from '@/views/solution/sections/SolutionInvestmentSection';
import SolutionBenefitsSection from '@/views/solution/sections/SolutionBenefitsSection';
import SolutionHeadSection from '@/views/solution/sections/SolutionHeadSection';
import useLocale from '@/hooks/useLocale';
import file from '@/assets/files/our-solutions/Mandate_Service_Handbook_OffshoreOnshore_v6_2024.09.01.pdf';

const SolutionFixedIncomePage = (props = {}) => {

  const namespace = useMemo(() => 'page-solution-mandate-service', []);

  const localeComponents = useLocale(namespace);
  const {paths} = useInvestingBreadCrumbs(localeComponents);
  const shouldShowDownloadButton = true;
  const fileUrl = file;
  const fileName =  'Mandate_Service_Handbook_OffshoreOnshore.pdf';
  return (
    
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <BreadCrumbs paths={paths}/>
        <SolutionHeadSection namespace={namespace} title="head.title" excerpt="head.excerpt"/>
        <SolutionBenefitsSection namespace={namespace} benefits={benefits}/>
        <SolutionInvestmentSection namespace={namespace} investment={investment} shouldShowDownloadButton={shouldShowDownloadButton} isMandate={true} fileName={fileName}/>
        {/* <SolutionRelatedSolutionsSection namespace={namespace} relatedSolutions={relatedSolutions}/> */}
        <ContactSection/>
      </div>
    </StandardLayout>
  );

};

export default SolutionFixedIncomePage;