import React from 'react';
import cx from 'classnames';

import useLocale from '@/hooks/useLocale';
import {splitLines} from '@/utils/html-utils';
import SolutionApproachContentSection from '@/views/solution/sections/SolutionApproachContentSection';
import AdvisoryMenu, {PAGE_INVESTMENT_SOLUTION} from '@/views/solution/svgs/AdvisoryMenu';
import imgFigure02 from '@/assets/images/page-solution-approach-investment-solution/figure-02.png';
import imgFigure03 from '@/assets/images/page-solution-approach-investment-solution/figure-03.png';


const SolutionApproachInvestmentSolutionContentSection = (props = {}) => {

  const {namespace} = props;

  const {t} = useLocale(namespace);

  return (
    <SolutionApproachContentSection>
      <div className={cx('flex', 'flex-col', 'justify-start', 'item-stretch')}>
        <div className={cx(
          'space-y-16', 'sm:space-y-0', 'space-x-0', 'sm:space-x-16',
          'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch', 'sm:items-center'
        )}>
          <div className={cx('relative', 'flex-1')}>
            <AdvisoryMenu page={PAGE_INVESTMENT_SOLUTION}/>
          </div>
          <div
            className={cx('space-y-4', 'flex-1', 'flex-shrink-0', 'flex', 'flex-col', 'justify-start', 'items-start')}>
            <h2 className={cx('block', 'text-legacy-purple')}>
              {t('investmentSolution.title')}
            </h2>
            <p className={cx('block', 'text-legacy-purple', 'text-base')}>
              {t('investmentSolution.description')}
            </p>
          </div>
        </div>
        <hr className={cx('h-[2px]', 'border-none', 'bg-primary-100', 'my-6', 'sm:my-9')}/>
        <h2 className={cx('text-legacy-purple')}>{t('thoroughAndTimely.title')}</h2>
        <hr className={cx('h-[2px]', 'border-none', 'bg-primary-100', 'my-6', 'sm:my-9')}/>
        <span className={cx('text-2xl', 'text-legacy-purple')}>
          {t('thoroughAndTimely.paragraph01.title')}
        </span>
        <div className={cx(
          'my-0', 'sm:my-6',
          'space-y-8', 'sm:space-y-0', 'space-x-0', 'sm:space-x-4',
          'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch', 'sm:items-center'
        )}>
          <div className={cx(
            'space-y-4', 'flex-1', 'flex-shrink-0',
            'flex', 'flex-col', 'justify-start', 'items-start'
          )}>
            <p className={cx('block', 'text-legacy-purple', 'text-base')}>
              {splitLines(t('thoroughAndTimely.paragraph01.description'))}
            </p>
          </div>
          <img alt="Figure" className={cx('sm:w-1/3', 'sm:max-w-[352px]', 'h-auto')} src={imgFigure02}/>
        </div>
        <hr className={cx('h-[2px]', 'border-none', 'bg-primary-100', 'my-6', 'sm:my-9')}/>
        <span className={cx('text-2xl', 'text-legacy-purple')}>
          {t('thoroughAndTimely.paragraph02.title')}
        </span>
        <div className={cx(
          'my-0', 'sm:my-6', 'space-y-8', 'sm:space-y-0', 'space-x-0', 'sm:space-x-4',
          'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch', 'sm:items-center'
        )}>
          <div
            className={cx('space-y-4', 'flex-1', 'flex-shrink-0', 'flex', 'flex-col', 'justify-start', 'items-start')}>
            <p className={cx('block', 'text-legacy-purple', 'text-base')}>
              {splitLines(t('thoroughAndTimely.paragraph02.description'))}
            </p>
          </div>
          <img alt="Figure" className={cx('sm:w-1/3', 'sm:max-w-[352px]', 'h-auto')} src={imgFigure03}/>
        </div>
      </div>
    </SolutionApproachContentSection>
  );

};

export default SolutionApproachInvestmentSolutionContentSection;