import React, {useEffect, useMemo, useRef, useState} from 'react';
import cx from 'classnames';

import useLocale from '@/hooks/useLocale';
import {chopArray} from '@/utils/array-utils';
import LinkCard from '@/views/goal/sections/GoalRelatedProductsGridSection/components/LinkCard';

import imgTitle from '@/assets/images/page-goal-section-related-products-grid/title.svg';
import {useIsScreenSm} from '@/providers/ScreenSizeProvider';
import useGoalHorizontalRelatedProductAnimation from '@/views/goal/hooks/useGoalHorizontalRelatedProductAnimation';


const GoalRelatedProductsGridSection = (props = {}) => {

  const {namespace, relatedProducts = []} = props;

  const {t} = useLocale(namespace);

  const isScreenSm = useIsScreenSm();

  const cardsPerCol = useMemo(() => isScreenSm ? 1 : 3, [isScreenSm]);

  const containerRef = useRef(undefined);
  const slideRef = useRef(undefined);
  const triggerRef = useRef(undefined);

  const [scrollWidth, setScrollWidth] = useState(0);

  const relatedProductsWithHead = useMemo(() => {
    return [{type: 'head'}, ...relatedProducts];
  }, [relatedProducts]);

  useEffect(() => {
    setTimeout(() => {
      const {current: slide} = slideRef;
      if (!slide) return;
      setScrollWidth(slide.scrollWidth - slide.offsetWidth);
    }, 0);
  }, [slideRef]);

  useGoalHorizontalRelatedProductAnimation(triggerRef, containerRef, slideRef, scrollWidth, !isScreenSm, 3);


  return (
    <section className={cx('relative', 'pt-0', 'sm:pt-[120px]', 'pb-14')}>
      <div ref={triggerRef} className={cx('absolute', 'bottom-0', 'left-0', 'w-full', 'h-px')}/>
      <div ref={containerRef} className={cx('relative', 'content')}>
        <div ref={slideRef} className={cx(
          'relative', 'w-full', 'overflow-visible',
          'space-y-[30px]', 'sm:space-y-0', 'sm:space-x-0', 'sm:space-x-[30px]',
          'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch'
        )}>
          {
            chopArray(relatedProductsWithHead, cardsPerCol).map((items, index) => {
              return (
                <div className={cx(
                  'sm:w-[calc(33.3333%-20px)]', 'sm:max-w-[calc(33.3333%-20px)]', 'space-y-[30px]',
                  'flex-shrink-0', 'flex', 'flex-col', 'flex-start', 'items-stretch',
                )}>
                  {
                    items.map(item => {
                      if (!item) {
                        return null;
                      }
                      const {type} = item;
                      if (type === 'head') {
                        return (
                          <div className={cx('relative', 'w-full', 'aspect-related-products-short')}>
                            <div key={0} className={cx(
                              'w-[280px]', 'sm:w-auto',
                              'max-w-[280px]', 'sm:max-w-none',
                              'self-start', 'sm:self-stretch',
                              'aspect-related-products-short', 'bg-no-repeat', 'bg-cover', 'bg-center',
                            )} style={{backgroundImage: `url(${imgTitle})`}}/>
                          </div>
                        );
                      }
                      const {id, theme, title, description, url} = item;
                      return <LinkCard key={id} {...{theme, title: t(title), description: t(description), url}}/>;
                    })
                  }
                </div>
              );
            })
          }
        </div>
      </div>
    </section>
  );

};

export default GoalRelatedProductsGridSection;