import React from 'react';
import cx from 'classnames';

import s from './styles.module.scss';


const SolutionApproachContentSection = (props = {}) => {

  const {children} = props;

  return (
    <section className={cx('relative', 'pb-[96px]')}>
      <div className={cx('content')}>
        <div className={cx('bg-white', 'p-4', 'sm:py-[64px]', 'sm:px-[84px]')}>
          {children}
        </div>
      </div>
    </section>
  );

};

export default SolutionApproachContentSection;