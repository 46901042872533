import React from 'react';
import cx from "classnames";

const GradientBackground = (props = {}) => {

  return (
    <svg className={cx('absolute', 'left-0', 'top-0', 'w-full', 'h-full')} preserveAspectRatio="none"
         width="1280" height="3024" viewBox="0 0 1280 3024" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="1280" height="3024" fill="url(#paint0_linear_325_357153)"/>
      <defs>
        <linearGradient id="paint0_linear_325_357153" x1="565" y1="-1.71787" x2="2013.36" y2="414.721" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EBEAF0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
      </defs>
    </svg>
  );

};

export default GradientBackground;