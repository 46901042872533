import React from 'react';
import cx from 'classnames';
import {nanoid} from 'nanoid';

import {useIsScreenSm} from '@/providers/ScreenSizeProvider';
import {chopArray} from '@/utils/array-utils';
import useLocale from '@/hooks/useLocale';

import s from './styles.module.scss';

import imgBackground from '@/assets/images/page-solution-section-benefits/background.png';
import imgMobileBackground from '@/assets/images/page-solution-section-benefits/mobile-background.png';
import imgPartner from '@/assets/images/page-solution-section-benefits/ic-parter.svg';
import imgRegister from '@/assets/images/page-solution-section-benefits/ic-register.svg';
import imgFaq from '@/assets/images/page-solution-section-benefits/ic-faq.svg';


const SolutionBenefitsSection = (props = {}) => {

  const {namespace, benefits} = props;

  const isScreenSm = useIsScreenSm();

  const {t} = useLocale(namespace);


  return (
    <section className={cx('relative', '-mt-6', 'mb-6', 'overflow-visible', 'box-border', 'border-10', 'z-50', s.container)}>
      <img className={cx(
        'w-full', 'sm:h-[348px]', 'absolute', 'left-0', 'top-6',
      )} alt="Background" src={isScreenSm ? imgMobileBackground : imgBackground}/>
      <div className={cx('relative', 'content')}>
        <div className={cx(
          'w-full', 'pt-12', 'pb-6', 'px-6', 'space-y-6',
          'flex', 'flex-col', 'justify-start', 'align-stretch',
          'border', 'border-primary-100',
          s.content
        )}>
          <h2 className={cx('text-primary-900', 'font-serif', 'font-bold', 'text-4xl')}>
            Benefits at <br className={cx('block', 'sm:hidden')}/> a glance
          </h2>
          <ul className={cx(
            'space-y-6', 'sm:space-y-0', 'space-x-0', 'sm:space-x-6',
            'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch'
          )}>
            {
              chopArray(benefits, 3)[0].map((benefit, index) => {
                if (!benefit) {
                  return <div key={nanoid()} className={cx('flex-1')}/>
                }

                let icon;
                if (index % 3 === 0) {
                  icon = imgPartner;
                } else if (index % 3 === 1) {
                  icon = imgRegister;
                } else {
                  icon = imgFaq;
                }

                const {id} = benefit;
                return (
                  <li key={id} className={cx(
                    'flex-1', 'p-6', 'bg-white', 'rounded-lg', 'shrink-0',
                    'flex', 'flex-col', 'justify-start', 'items-stretch',
                  )}>
                    <img className={cx('self-start')} alt="Icon" src={icon}/>
                    <h4
                      className={cx('mt-6', 'min-h-14', 'text-legacy-purple', 'text-2xl', 'font-medium', 'leading-7')}>
                      {t(benefit.title)}
                    </h4>
                    <p className={cx('pt-4', 'text-base', 'sm:text-xl', 'font-light', 'text-primary-400')}>
                      {t(benefit.description).split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          {index !== t(benefit.description).split('\n').length - 1 && <br />} {/* Add a line break except for the last line */}
                        </React.Fragment>
                      ))}
                    </p>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </section>
  );

};

export default SolutionBenefitsSection;