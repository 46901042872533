import {restoreObjectFromSessionStorage, saveObjectToSessionStorage} from "@/utils/store-utils";

export default class BaseStore {

  async restore() {
    const session = restoreObjectFromSessionStorage(this.saveKey);
    for (const key of this.observablesToSave) {
      this[key] = session[key];
    }
  }

  async save() {
    const session = {};
    for (const key of this.observablesToSave) {
      session[key] = this[key];
    }
    saveObjectToSessionStorage(this.saveKey, session);
  }

};
