export default [
  {
    id: 1,
    title: 'investmentBanking.title',
    description: 'investmentBanking.description',
    theme: 'purple',
    url: '/solution/investment-banking',
  },
  {
    id: 2,
    title: 'lombardLoan.title',
    description: 'lombardLoan.description',
    theme: 'light',
    url: '/solution/lombard-loan',
  },
  {
    id: 3,
    title: 'propertyFinancing.title',
    description: 'propertyFinancing.description',
    theme: 'dark',
    url: '/solution/property-financing',
  },
  {
    id: 4,
    title: 'commercialLoan.title',
    description: 'commercialLoan.description',
    theme: 'light',
    url: '/solution/commercial-loan',
  },
  {
    id: 5,
    title: 'mutualFunds.title',
    description: 'mutualFunds.description',
    theme: 'dark',
    url: '/solution/mutual-funds',
  },
  {
    id: 6,
    title: 'mandateService.title',
    description: 'mandateService.description',
    theme: 'grey',
    url: '/solution/mandate-service',
  },
  {
    id: 7,
    title: 'privateMarket.title',
    description: 'privateMarket.description',
    theme: 'grey',
    url: '/solution/private-market',
  },
  {
    id: 8,
    title: 'realEstate.title',
    description: 'realEstate.description',
    theme: 'purple',
    url: '/solution/real-estate',
  },
];
