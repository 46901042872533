import React, {useCallback, useMemo, useState} from 'react';
import {nanoid} from "nanoid";
import {Swiper, SwiperSlide} from "swiper/react";
import cx from 'classnames';
import {Link, useNavigate} from 'react-router-dom';

import useLocale from '@/hooks/useLocale';
import {useIsScreenSm} from '@/providers/ScreenSizeProvider';
import {chopArray} from "@/utils/array-utils";
import ExpertSummaryEntry from "@/views/common/components/ExpertSummaryEntry";

import s from './styles.module.scss';

import imgChevronLeft from '@/assets/images/page-why-us-expertise-section-experts/ic-chevron-left.svg';
import imgChevronRight from '@/assets/images/page-why-us-expertise-section-experts/ic-chevron-right.svg';
import imgNext from '@/assets/images/page-why-us-expertise-section-experts/chevron-right.svg';
import { unExpectedError } from '@/assets/images/common-error';
import { Pagination } from 'swiper/modules';
import errImg from '@/assets/images/common/no-content.png'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const WhyUsExpertiseExpertsSection = (props = {}) => {

  const {isLoading, isError, experts = []} = props;

  const {t, locale} = useLocale('page-why-us-expertise-expert-section');
  const isScreenSm = useIsScreenSm();
  const navigate = useNavigate();

  const [swiper, setSwiper] = useState(undefined);
  const [activeIndex, setActiveIndex] = useState(0);

  const cardsPerRow = useMemo(() => isScreenSm ? 2 : 3, [isScreenSm]);

  const onClickBack = useCallback(() => {
    swiper.slidePrev();
  }, [swiper]);

  const onClickNext = useCallback(() => {
    swiper.slideNext();
  }, [swiper]);

  return (
    <section className={cx('relative', s.container)}>
      <div className={cx(
        'content',
        'flex', 'flex-col', 'justify-start', 'items-stretch',
      )}>
        <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch', 'space-y-4')}>
          <div className={cx('flex', 'flex-row', 'justify-between', 'items-center')}>
            <h1 className={cx('text-primary-900')}>Experts</h1>
            { 
              isError ? null : 
                (
                  <Link to={`/${locale}/experts/overview`} className={cx(
                    'px-4', 'py-2', 'rounded-lg', 'border', 'border-primary-100',
                    'btn', 'btn-outlined', 'btn-outlined-light', 'btn-next', 'btn-md',
                  )}>
                    <span>{t('seeAll')}</span>
                  </Link>
                )
              }
            
          </div>
          <hr/>
        </div>
      </div>
      <div className={cx('content', 'relative', 'flex', 'flex-vertical', 'justify-start', 'items-stretch')}>
      { 
        isError ? 
        (
            <div className={cx('content', 'flex', 'flex-col', 'justify-center','items-stretch', 'mt-12')}>
              <img className={cx('h-[90px]', 'w-[90px]', 'self-center')} src={errImg} alt=''/>
              <span className={cx('text-[#B3ACC4]', 'font-medium', 'whitespace-pre-line', 'text-center', 'mt-7')}>{t('error')}</span>
              <a href="." onClick={(e) => {
                  e.preventDefault(); // Prevent the default anchor behavior
                  navigate(0); // Refresh the page
                }} className={cx(
                  'mt-2', 'sm:mt-8', 'btn', 'btn-outlined', 'btn-outlined-light', 'w-[160px]', 'py-4', 'self-center'
                )}>
                <span>{t('tryAgain')}</span>
              </a>
            </div>
        )
        : 
        (
        <>
        <div className={cx('relative', 'w-full')}>
            {
              isScreenSm ? null : (
                <div className={cx(
                  'absolute', 'left-0', 'top-0', 'w-full', 'h-full',
                  'flex', 'flex-row', 'justify-between', 'items-center',
                )}>
                  <button className={cx(
                    'relative', 'w-8', 'h-32', 'mg-1', 'z-10',
                    'btn', 'btn-outlined', 'btn-outlined-light', 'btn-p-0', 'btn-rounded-sm',
                    'flex', 'flex-vertical', 'justify-center', 'items-center',
                    {'opacity-0': activeIndex <= 0},
                    {'opacity-100': activeIndex > 0},
                    'transition-opacity',
                  )} onClick={onClickBack}>
                    <i className={cx('icon-chevron-left', 'text-2xl')}/>
                  </button>
                  <button className={cx(
                    'relative', 'w-8', 'h-32', 'mg-1', 'z-10',
                    'btn', 'btn-outlined', 'btn-outlined-light', 'btn-p-0', 'btn-rounded-sm',
                    'flex', 'flex-vertical', 'justify-center', 'items-center',
                    {'opacity-0': activeIndex >= chopArray(experts, 6).length - 1},
                    {'opacity-100': activeIndex < chopArray(experts, 6).length - 1},
                    'transition-opacity',
                  )} onClick={onClickNext}>
                    <i className={cx('icon-chevron-right', 'text-2xl')}/>
                  </button>
                </div>
              )
            }
            <Swiper modules={[Pagination]} className={cx('w-full')} speed={isScreenSm ? undefined : 1000}
                    slidesPerView={1} spaceBetween={0} pagination={true}
                    onSlideChange={() => setActiveIndex(swiper.activeIndex)}
                    onSwiper={swiper => setSwiper(swiper)}
                    >
              {
                chopArray(experts, 6).map((paginatedExperts, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className={cx(
                        'relative', 'my-12', 'space-y-8',
                        'flex', 'flex-col', 'justify-start', 'items-stretch'
                      )}>
                        {
                          chopArray(paginatedExperts, cardsPerRow).map((rowExperts, index) => {
                            return (
                              <ul key={index} className={cx(
                                'space-x-4', 'sm:space-x-12',
                                'flex', 'flex-row', 'justify-center', 'items-stretch'
                              )}>
                                {
                                  rowExperts.map(expert => {
                                    if (expert === null) {
                                      return <div key={nanoid()} className={cx('w-[calc(50%-16px)]', 'sm:w-[256px]')}/>
                                    }
                                    const {id} = expert;
                                    return (
                                      <li key={id} className={cx(
                                        'relative', 'block',
                                        'flex-shrink-0','w-[calc(50%-16px)]', 'sm:w-[256px]'
                                      )}>
                                        <ExpertSummaryEntry expert={expert} isLoading={isLoading}/>
                                      </li>
                                    );
                                  })
                                }
                              </ul>
                            );
                          })
                        }
                      </div>
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
        </div>
      </>
      )}
      </div>
    </section>
  );

};

export default WhyUsExpertiseExpertsSection;