import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import cx from 'classnames';
import gsap from 'gsap';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/th';

import {useIsScreenSm} from '@/providers/ScreenSizeProvider';
import useLocale from '@/hooks/useLocale';
import {chopArray} from '@/utils/array-utils';
import CornerGreyBackground from './svgs/CornerGreyBackground';
import AssetEntry from './components/AssetEntry';
import AssetCategoryMenu from './components/AssetCategoryMenu';

import s from './styles.module.scss';


const WhyUsExpertiseAssetsSection = (props = {}) => {

  const {assets = []} = props;

  const isScreenSm = useIsScreenSm();

  const assetsRef = useRef(undefined);

  const {t, locale} = useLocale('page-why-us-expertise-section-assets');

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animatedRevenues, setAnimatedRevenues] = useState(undefined);

  const revenues = useMemo(() => {
    const asset = assets[currentIndex];
    const {revenues} = asset;
    return revenues;
  }, [assets, currentIndex]);

  useEffect(() => {
    const {current: assets} = assetsRef;
    if (!assets) return;
    const cards = assets.getElementsByClassName('asset-card');
    if (cards.length === 0) {
      setAnimatedRevenues(revenues);
      return;
    }
    for (let i = 0; i < cards.length; i++) {
      (i => {
        gsap.to(
          cards[i],
          {
            opacity: 0,
            duration: 0.1,
            onComplete: () => {
              if (i !== 0) return;
              setAnimatedRevenues(revenues);
            }
          }
        );
      })(i);
    }
  }, [revenues]);

  useEffect(() => {
    if (!animatedRevenues) return;
    const {current: assets} = assetsRef;
    if (!assets) return;
    const cards = assets.getElementsByClassName('asset-card');
    for (let i = 0; i < cards.length; i++) {
      gsap.to(
        cards[i],
        {
          opacity: 1,
          duration: 0.7,
        }
      );
    }
  }, [animatedRevenues]);

  const onChangeIndex = useCallback(index => {
    setCurrentIndex(index);
  }, []);


  return (
    <section className={cx('relative', 'pt-6', 'pb-10', s.container)}>
      {/*{isScreenSm ? null : <CornerGreyBackground/>}*/}
      <div className={cx('content', 'relative')}>
        <div className={cx('w-full', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
          <div ref={assetsRef} className={cx(
            'space-x-0', 'sm:space-x-8',
            'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-center', 'sm:items-stretch',
          )}>
            <div className={cx('relative', 'w-full', 'sm:w-auto', 'sm:flex-1', 'relative')}>
              <AssetCategoryMenu items={assets} selectedIndex={currentIndex}
                                 onChangeIndex={onChangeIndex}/>
            </div>
            {
              !animatedRevenues ? null : chopArray(animatedRevenues, 3)[0].map(revenue => {
                if (!revenue) {
                  return <div className={cx('asset-card', 'sm:flex-1')}/>;
                }
                const {id, title, value} = revenue;
                return (
                  <div key={id} className={cx(
                    'asset-card', 'w-full', 'sm:w-auto',
                    'max-w-[240px]', 'sm:max-w-none',
                    'sm:flex-1', 'relative'
                  )}>
                    <AssetEntry capital={value[locale]} title={title[locale]}/>
                  </div>
                );
              })
            }
          </div>
          <p className={cx('mt-4', 'sm:mt-0', 'block', 'text-base', 'text-primary-200', 'font-normal', 'text-right')}>
            {t('dataAsOf')} {dayjs('2023-09-30').locale(locale).format('D MMMM YYYY')}
          </p>
        </div>
      </div>
    </section>
  );

};

export default WhyUsExpertiseAssetsSection;