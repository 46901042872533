import React from 'react';
import cx from 'classnames';

import useLocale from '@/hooks/useLocale';

import s from './styles.module.scss';


const ChannelCard = (props = {}) => {

  const {channel = {}} = props;
  const {icon, title} = channel;

  const {locale} = useLocale('');

  return (
    <div className={cx('w-full', 'space-y-4', 'pt-12', 'pb-6', 'flex', 'flex-col', 'justify-start', 'items-center')}>
      <img alt={title[locale]} src={icon}/>
      <span className={cx('text-xl', 'text-legacy-purple')}>{title[locale]}</span>
    </div>
  );

};

export default ChannelCard;