import React, { useMemo } from 'react';
import cx from 'classnames';
import { locals } from '@/utils/locales';
import useLocale from '@/hooks/useLocale';

const SocialShareBar = (props = {}) => {
  const {
    header = '',
  } = props;

  const { locale } = useLocale('');
  const shareUrl = useMemo(() => encodeURIComponent(window.location.href), []);
  const shareText = useMemo(() => encodeURIComponent(`${header} | ${window.location.href}`), [header]);

  return (
    <div className={cx('flex', 'flex-row', 'justify-end', 'items-center', 'space-x-6')}>
      <div className={cx('flex', 'flex-row', 'justify-start', 'items-center', 'space-x-2', 'text-primary-800')}>
        <i className={cx('text-2xl')} />
        <span className={cx('text-primary-800', 'font-normal')}>{locale === locals.en ? "Share" : "แชร์"}</span>
      </div>
      <ul className={cx('flex', 'flex-row', 'justify-start', 'items-center', 'space-x-5')}>
      <li className={cx('link', 'link-primary-900')}>
          <a 
            target="_blank" 
            href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${encodeURIComponent(`${header} | ${window.location.href}`)}`} 
            rel="noopener noreferrer"
          >
            <i className={cx('text-2xl', 'icon-social-facebook')} />
          </a>
        </li>
        <li className={cx('link', 'link-primary-900')}>
          <a 
            target="_blank" 
            href={`https://twitter.com/intent/tweet?text=${shareText}`}
          >
            <i className={cx('text-2xl', 'icon-social-x')} />
          </a>
        </li>
        <li className={cx('link', 'link-primary-900')}>
          <a 
            target="_blank" 
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}&summary=${shareText}`}
          >
            <i className={cx('text-2xl', 'icon-social-linkedin')} />
          </a>
        </li>
        <li className={cx('link', 'link-primary-900')}>
          <a 
            target="_blank" 
            href={`https://line.me/R/share?text=${shareText}`}
          >
            <i className={cx('text-2xl', 'icon-social-line')} />
          </a>
        </li>
        <li className={cx('link', 'link-primary-900')}>
          <a 
            href={`mailto:?subject=${encodeURIComponent(`${header} | KKP Wealth Management`)}&body=${shareText}`}
          >
            <i className={cx('text-2xl', 'icon-mail')} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialShareBar;
