import {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {validLocales} from '@/utils/locales';
import useLocale from '@/hooks/useLocale';


const Error404RedirectPage = (props = {}) => {

  const {locale: originalLocale} = useLocale('', false);

  const navigate = useNavigate();

  const locale = useMemo(() => {
    if (originalLocale && validLocales.includes(originalLocale)) return originalLocale;
    return 'en';
  }, [originalLocale]);

  useEffect(() => {
    if (!locale) return;
    navigate(`/${locale}/404`, {replace: true});
  }, [locale, navigate]);

  return null;

};

export default Error404RedirectPage;