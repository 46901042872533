import React, {useEffect, useMemo, useState} from 'react';
import cx from 'classnames';

import StandardLayout from "@/views/common/layouts/StandardLayout";
import BannerTitleSection from "@/views/common/section/BannerTitleSection";
import NewsLetterSection from "@/views/common/section/NewsLetterSection";
import ExpertListSection from "./sections/ExpertListSection";

import s from './styles.module.scss';

import imgBanner from '@/assets/images/page-experts/banner-01.png';
import { getExperts } from "@/apis/experts";
import { getTags } from '@/apis/tags';
import { get } from 'lodash';
import useLocale from '@/hooks/useLocale';
import errImg from '@/assets/images/common/no-content.png'
import { useNavigate } from 'react-router-dom';


const ExpertOverviewPage = (props = {}) => {
  const [ experts, setExperts] = useState({experts:[{},{},{},{},{},{},{},{},{},{},{},{}]});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [categoriesData, setCategoriesData] = useState(null);
  const bannerTitle = useMemo(() => {
    return 'Investment experts at your side.';
  }, []);
  const {t} = useLocale('page-experts-overview');
  const bannerDescription = useMemo(() => t('excerpt'), [t])
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response, categories] = await Promise.all([
          getExperts({}),
          getTags({})
        ]);

        setExperts(get(response, 'data', []));
        setCategoriesData(get(categories, 'data', []));
      } catch (error) {
        setIsError(true)
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <StandardLayout>
      <div className={cx('relative', 'flex', 'flex-col', 'justify-start', 'items-stretch', s.container)}>
        <div className={cx(s['top-bg'])}>
          <BannerTitleSection banner={imgBanner} title={bannerTitle} description={bannerDescription}/>
          {
          isError ? 
          <section className={cx('relative', 'pt-4', 'pb-6', s.container)}>
            <div className={cx('content', 'flex', 'flex-col', 'justify-start', 'items-stretch', s.content)}>
              <img className={cx('h-[90px]', 'w-[90px]', 'self-center')} src={errImg} alt=''/>
              <span className={cx('text-[#B3ACC4]', 'font-medium', 'whitespace-pre-line', 'text-center', 'mt-7')}>{t('error')}</span>
              <a href="." onClick={(e) => {
                e.preventDefault(); // Prevent the default anchor behavior
                navigate(0); // Refresh the page
              }} className={cx(
                'mt-2', 'sm:mt-8', 'btn', 'btn-outlined', 'btn-outlined-light', 'w-[160px]', 'py-4', 'self-center'
              )}>
                <span>{t('tryAgain')}</span>
              </a>
            </div>
          </section>
          :
          <ExpertListSection experts={get(experts, 'experts')} isLoading={isLoading} isError={isError} categories={categoriesData}/>
          }
        </div>
        <NewsLetterSection/>
      </div>
    </StandardLayout>
  );

};

export default ExpertOverviewPage;