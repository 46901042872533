import React, {createContext, useContext, useEffect} from 'react';

import {createProvider} from '@/utils/store-utils';
import createContactStore from './contact-store';

const ContactContext = createContext(null);

export const ContactStoreProvider = createProvider(ContactContext, createContactStore);

export const useContactStore = () => {
  const contactStore = useContext(ContactContext);

  useEffect(() => {
    if (!contactStore || contactStore.loadingState !== 'idle') return;
    (async () => await contactStore.load())();
  }, [contactStore]);

  return contactStore;
};