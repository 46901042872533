import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {splitLines} from '@/utils/html-utils';
import {useIsScreenSm} from '@/providers/ScreenSizeProvider';

import s from './styles.module.scss';

import imgAlertIcon from '@/assets/images/common-alert/alert.svg';

export const ALERT_THEME_ERROR = 'error';
export const ALERT_THEME_ANNOUNCEMENT = 'announcement';


const Alert = (props = {}) => {

  const {
    theme = ALERT_THEME_ERROR,
    message,
  } = props;

  const isScreenSm = useIsScreenSm();

  const title = useMemo(() => {
    switch (theme) {
      case ALERT_THEME_ERROR:
        return 'Error Message :';
      case ALERT_THEME_ANNOUNCEMENT:
        return 'Announcement'
      default:
        return '';
    }
  }, [theme]);

  return (
    <div className={cx(
      'relative', 'w-full', 'py-3', 'px-6',
      'text-white', {'bg-error': theme === ALERT_THEME_ERROR, 'bg-black': theme === ALERT_THEME_ANNOUNCEMENT},
    )}>
      <i className={cx('mr-2', 'icon-alert')}/>
      <span className={cx('font-normal', 'sm:mr-2')}>{title}</span>
      {!isScreenSm ? null : <br/>}
      <span>{splitLines(message)}</span>
    </div>
  );

};

Alert.propTypes = {
  theme: PropTypes.oneOf([ALERT_THEME_ERROR, ALERT_THEME_ANNOUNCEMENT]),
  title: PropTypes.string,
};

export default Alert;