import React, {useMemo} from 'react';
import cx from 'classnames';

import tabs from "../../data/tabs";
import TabList from "../../../common/components/TabList";
import useLocale from '@/hooks/useLocale';
import StandardLayout from "@/views/common/layouts/StandardLayout";
import BannerTitleSection from "../../../common/section/BannerTitleSection";

import s from './styles.module.scss';

import imgBanner from '../../../../assets/images/page-legal/banner.png';


const PrivacyNoticePage = (props = {}) => {

  const {locale} = useLocale('');

  const localisedTabs = useMemo(() => {
    return tabs.map(tab => {
      const {url, ...rest} = tab;
      return {...rest, url: `/${locale}${url}`};
    });
  }, [locale]);

  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch', s.container)}>
        <BannerTitleSection banner={imgBanner} title="Privacy Notice"/>
        <div className={cx('py-6', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
          <TabList items={localisedTabs} selectedIndex={3}/>
        </div>
      </div>
    </StandardLayout>
  );

};

export default PrivacyNoticePage;