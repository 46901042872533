import React, {useEffect} from 'react';
import cx from 'classnames';
import {unstable_useBlocker, useLocation} from 'react-router-dom';

import {useScreenSize} from '@/providers/ScreenSizeProvider';
import MobileMenu from '@/views/common/layouts/StandardLayout/components/MobileMenu';
import ContactDialog from '@/views/contact/dialogs/ContactDialog';
import HeaderSection from "@/views/common/layouts/StandardLayout/sections/HeaderSection";
import FooterSection from "@/views/common/layouts/StandardLayout/sections/FooterSection";
import Footer2Section from "@/views/common/layouts/StandardLayout/sections/Footer2Section";

import s from './styles.module.scss';


const StandardLayout = (props = {}) => {

  const {children} = props;

  const screenSize = useScreenSize();

  const location = useLocation();

  unstable_useBlocker(({currentLocation, nextLocation}) => {
    // console.log('>>path<<', currentLocation, nextLocation);
    return JSON.stringify({...currentLocation, key: undefined, state: undefined}) ===
      JSON.stringify({...nextLocation, key: undefined, state: undefined});
  });

  useEffect(() => {
    let {state} = location;
    const {noScroll} = state || {};
    if (noScroll) return;
    window.scrollTo(0, 0);
  }, [location]);

  if (!screenSize) return null;

  return (
    <div className={cx(
      'min-h-screen', 'flex', 'flex-col', 'justify-start', 'items-stretch', 'overflow-x-hidden',
      s.container
    )}>
      <header className={cx('block', 'overflow-visible', 'z-[101]')}>
        <HeaderSection/>
      </header>
      <MobileMenu/>
      <main className={cx('relative', 'flex-1', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
        {!screenSize ? null : children}
      </main>
      <footer className={cx('block')}>
        <FooterSection/>
        <Footer2Section/>
      </footer>
      <ContactDialog/>
    </div>
  )

};

export default StandardLayout;