export default [
  {
    id: 1,
    title: {
      en: 'Asset under Management',
      th: 'Asset under Management',
    },
    revenues: [
      {
        id: 1,
        title: {
          en: 'Total client assets (THB)',
          th: 'Total client assets (THB)',
        },
        value: {
          en: '821bn',
          th: '821bn',
        },
      },
      {
        id: 2,
        title: {
          en: 'Total mutual fund (THB)',
          th: 'Total mutual fund (THB)',
        },
        value: {
          en: '139bn',
          th: '139bn',
        },
      },
      {
        id: 3,
        title: {
          en: 'Total equity (THB)',
          th: 'Total equity (THB)',
        },
        value: {
          en: '332bn',
          th: '332bn',
        },
      },
    ],
  },
  {
    id: 2,
    title: {
      en: 'Offshore Investment',
      th: 'Offshore Investment',
    },
    revenues: [
      {
        id: 1,
        title: {
          en: 'Total client assets (THB)',
          th: 'Total client assets (THB)',
        },
        value: {
          en: '57bn',
          th: '57bn',
        },
      },
      {
        id: 2,
        title: {
          en: 'Total mutual fund (THB)',
          th: 'Total mutual fund (THB)',
        },
        value: {
          en: '12bn',
          th: '12bn',
        },
      },
      {
        id: 3,
        title: {
          en: 'Total equity (THB)',
          th: 'Total equity (THB)',
        },
        value: {
          en: '10bn',
          th: '10bn',
        },
      },
    ],
  },
  {
    id: 3,
    title: {
      en: 'Access Opportunities',
      th: 'Access Opportunities',
    },revenues: [
      {
        id: 1,
        title: {
          en: 'Local & Global Fund house',
          th: 'Local & Global Fund house',
        },
        value: {
          en: '60+',
          th: '60+',
        },
      },
      {
        id: 2,
        title: {
          en: 'Fund universe around the world',
          th: 'Fund universe around the world',
        },
        value: {
          en: '10,500+',
          th: '10,500+',
        },
      },
    ],
  }
];