export default [
  {
    id: 1,
    title: 'realEstateAdvisory.title',
    description: 'realEstateAdvisory.description',
    url: '/solution/real-estate',
  },
  {
    id: 2,
    title: 'propertyFinancing.title',
    description: 'propertyFinancing.description',
    url: '/solution/property-financing',
  },
];
