import cx from 'classnames';
import React from 'react';

import s from './styles.module.scss';


const ContactLayout = (props = {}) => {

  const {children, footerComponent} = props;

  return (
    <section className={cx(
      'w-full', 'min-h-[calc(100vh-284px)]', 'sm:min-h-full',
      'flex', 'flex-col', 'justify-start', 'items-stretch', s.container
    )}>
      <div className={cx('flex-1', 'flex', 'flex-col', 'justify-start', 'items-stretch', s.content)}>
        {children}
      </div>
      {
        !footerComponent ? null : (
          <>
            <hr className={cx('border-0', 'h-px')}/>
            <div className={cx('p-4', 'flex', 'flex-col', 'justify-start', 'items-stretch', s.footer)}>
              {footerComponent}
            </div>
          </>
        )
      }
    </section>
  );

};

export default ContactLayout;