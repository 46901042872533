import React, {useCallback, useEffect, useState} from 'react';
import cx from 'classnames';
import { useLocation, useNavigate} from 'react-router-dom';

import useLocale from '@/hooks/useLocale';

import s from './styles.module.scss';



const Footer2Section = (props = {}) => {

  const {locale} = useLocale('');

  const [solutionMenuVisible, setSolutionMenuVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

 

  return (
    
    <section className={cx('bg-white', 'pb-12', s.container)}>
      <div className={cx('content', 'flex', 'flex-col', 'justify-start', 'items-center')}>
        <div className={cx('flex', 'flex-row', 'justify-between')}>
          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px', fontWeight: 'normal', lineHeight: '1.5' }}>
            <br />
            Investor should carefully consider and fully understand the product's features, conditions and investment risks before making investment decision.
            <br />
            <br />
            This website is the online investment portal of Kiatnakin Phatra Securities Public Company Limited providing various services, including but not limitation to online and mobile trading, planning and analyzing tools, investment advisories, trustworthy research, consolidated reports. All under a single platform.
          </div>
        </div>
      </div>
    </section>
  );

};

export default Footer2Section;