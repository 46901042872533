import Api from "../api-core";

export const createNewsLetter = async (
  email,
  ipAddress
) => {
  const formData = {
    Email: email,
    IpAddress: ipAddress,
  };
  try {
    const result = await Api().post(
      `${process.env.REACT_APP_CRATE_NEWS_LETTER}`,
      formData
    );
    return result;
  } catch (error) {
    // Handle errors
    console.error("Error:", error);
  }
};

export default createNewsLetter;
