import React, { useMemo } from 'react';
import cx from 'classnames';

import { chopArray } from '@/utils/array-utils';
import useLocale from '@/hooks/useLocale';
import { useIsScreenSm } from '@/providers/ScreenSizeProvider';
import ThingsToKnowCard from '@/views/solution/sections/SolutionInvestmentSection/components/ThingsToKnowCard';
import ConsultingCard from '@/views/solution/sections/SolutionInvestmentSection/components/ConsultingCard'; 

import s from './styles.module.scss';

const SolutionInvestmentSection = (props = {}) => {
  const { namespace, isMandate, investment = {} } = props;
  const { motivations = [], motivationBanner, prospectuses = [], investors = [], consultings = {} } = investment;
  const shouldShowDownloadButton = props.shouldShowDownloadButton !== false;
  const file = props.file;
  const fileName = props.fileName;
  const isScreenSm = useIsScreenSm();
  const { t } = useLocale(namespace);

  const cardsPerRow = useMemo(() => isScreenSm ? 1 : 2, [isScreenSm]);
  const openPdf = () => {
    const pdfUrl = 'https://wealthmanagement.kkpfg.com/landingpage/api/MapDriveImg/GetFile?filePath=q16UINjarCRCHQ4i6P7jjqtke%2fuBygHA8zY%2fI7wiZUQKVUXg1CbKewwN3BkzUEcouiFwfOyf%2fpLVxpaJ4IEoryx5DU4eBko1a7q9ZFB0eVZorI62m%2fXYw9crnflVIoI%2bhdiPNpr6sDKrmm%2bgBeci%2ff84DwFk9%2bKT'; // Replace with your PDF URL
    window.open(pdfUrl, '_blank');
  };
  function handleDownload() {
    const fileUrl = file;
    if (fileUrl !== null || fileUrl !== undefined) {
      console.log(fileName);
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('File URL is not provided.');
    }
  }

  return (
    <section className={cx('relative', 'pt-8', 'sm:pt-[120px]', 'pb-[96px]', s.container)}>
      <div className={cx('relative', 'content')}>
        <div className={cx(
          'width-100', 'pt-[72px]', 'pb-12', 'px-6', 'space-y-[76px]', 'box-border',
          'flex', 'flex-col', 'justify-start', 'items-stretch',
          'border', 'border-primary-100'
        )}>
          {/* Motivations Section */}
          {motivations.length > 0 && (
            <div className={cx('space-y-6', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
              <div className={cx(
                'flex', 'flex-col', 'justify-start', 'items-stretch', 'space-y-8'
              )}>
                <h2 className={cx('text-4xl', 'font-serif', 'font-bold', 'text-primary-900')}>
                  {t('whyUs') || 'Why Invest with us'}
                </h2>
                <div className={cx('h-px', 'w-full', 'bg-primary-100')}/>
              </div>
              <div className={cx(
                'space-x-0', 'sm:space-x-8',
                'flex', 'flex-col-reverse', 'sm:flex-row', 'justify-start', 'items-center'
              )}>
                <ul className={cx('flex', 'flex-col', 'justify-start', 'items-stretch', 'space-y-8')}>
                  {motivations.map(motivation => {
                    const { id, title, description } = motivation;
                    return (
                      <li key={id} className={cx('flex', 'flex-col', 'justify-start', 'items-stretch', 'space-y-2')}>
                        <h4 className={cx('text-2xl', 'text-legacy-purple')}>{t(title)}</h4>
                        <p className={cx('text-legacy-purple', 'text-light')}>
                          {t(description)}
                        </p>
                      </li>
                    );
                  })}
                </ul>
                {motivationBanner && (
                  <img className={cx('mb-8', 'sm:mb-0', 'w-[400px]', 'h-auto')} alt={t('motivationBannerAlt') || 'Banner'} src={motivationBanner}/>
                )}
              </div>
            </div>
          )}

          {/* Consulting Cards Section */}
          {consultings.title && consultings.description && (
            <div className={cx('space-y-8', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
               <div className={cx(
              'space-y-4', 'flex', 'flex-col', 'justify-start', 'items-stretch',
                )}>
              <h2 className={cx('text-4xl', 'font-serif', 'font-bold', 'text-primary-900')}>
                {t('consultingHeader') || ''}
              </h2>
              <div className={cx('h-px', 'w-full', 'bg-primary-100')}/>
            </div>
                <ConsultingCard title={t(consultings.title)} description={consultings.description.map(desc => t(desc))} />
            </div>
          )}

          {/* Prospectuses Section */}
          <div className={cx('space-y-8', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
            <div className={cx(
              'space-y-4', 'flex', 'flex-col', 'justify-start', 'items-stretch',
            )}>
              <h2 className={cx('text-4xl', 'font-serif', 'font-bold', 'text-primary-900')}>
                {t('thingsToKnow') || 'Things to know before investing'}
              </h2>
              <div className={cx('h-px', 'w-full', 'bg-primary-100')}/>
            </div>
            <div className={cx('space-y-6', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
              {prospectuses.filter(p => p.pinned === 'top').map(prospectus => {
                if (!prospectus) return null; // Ensure prospectus is not null or undefined
                const { id, title, description } = prospectus;
                return (
                  <div key={id} className={cx('flex-1', 'flex-shrink-0')}>
                    <ThingsToKnowCard title={t(title)} description={t(description)}/>
                  </div>
                );
              })}
              {chopArray(prospectuses.filter(p => !(p || {}).pinned), cardsPerRow).map((prospectusesChunk, index) => {
                return (
                  <div key={index} className={cx(
                    'flex', 'flex-row', 'justify-start', 'align-stretch',
                    'justify-start', 'space-x-6',
                  )}>
                    {prospectusesChunk.map(prospectus => {
                      if (!prospectus) {
                        return <div className={cx('flex-1', 'flex-shrink-0', 'p-6')} key={`empty-${index}`} />;
                      }
                      const { id, title, description } = prospectus;
                      return (
                        <div key={id} className={cx('flex-1', 'flex-shrink-0')}>
                          <ThingsToKnowCard title={t(title)} description={t(description)}/>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              {prospectuses.filter(p => p.pinned === 'bottom').map(prospectus => {
                if (!prospectus) return null; // Ensure prospectus is not null or undefined
                const { id, title, description } = prospectus;
                return (
                  <div key={id} className={cx('flex-1', 'flex-shrink-0')}>
                    <ThingsToKnowCard title={t(title)} description={t(description)}/>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Investors Section */}
          <div className={cx('space-y-8', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
            <div className={cx(
              'space-y-4', 'flex', 'flex-col', 'justify-start', 'items-stretch',
            )}>
              <h2 className={cx('text-4xl', 'font-serif', 'font-bold', 'text-primary-900')}>
                {t('suitableInvestors') || 'Suitable Investors'}
              </h2>
              <div className={cx('h-px', 'w-full', 'bg-primary-100')}/>
            </div>
            <ul className={cx(
              'flex', 'flex-col', 'justify-start', 'items-stretch',
              'space-y-4', 'pl-6', 'list-square',
              'text-xl', 'text-legacy-purple'
            )}>
              {investors.map(investor => {
                const { id, title } = investor;
                return <li key={id}>{t(title)}</li>;
              })}
            </ul>
          </div>

          {/* Download Button Section */}
          {shouldShowDownloadButton && (
            <div className={cx('space-y-8', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
              <div className={cx('h-px', 'w-full', 'bg-primary-100')}/>
              <div className={cx('self-center', 'flex', 'flex-row', 'justify-center', 'items-stretch')}>
                <div className={cx(
                  'p-4', 'text-base', 'text-primary-900',
                  'box-border', 'border', 'border-primary-200', 'border-r-0', 'rounded-l-lg',
                )}>
                  <span>{fileName}</span>
                </div>
                {
                  isMandate ? 
                  <button className={cx('btn', 'btn-submit', 'btn-lg-short', 'btn-rounded-right')} onClick={openPdf}>
                    <span className={cx('tracking-wide')}>{t('download') || 'Download'}</span>
                  </button>
                :
                  <button className={cx('btn', 'btn-submit', 'btn-lg-short', 'btn-rounded-right')} onClick={handleDownload}>
                    <span className={cx('tracking-wide')}>{t('download') || 'Download'}</span>
                  </button>
                }
                
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SolutionInvestmentSection;
