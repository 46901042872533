import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useLocale from '../../../../hooks/useLocale';
import { locals } from '@/utils/locales';
import Tag from '../Tag';
import { getDateByLocale } from '@/utils/locales';

import { emptyImg } from '@/assets/images/page-insight';

import s from './styles.module.scss';
import { Link } from 'react-router-dom';

export const DIRECTION_VERTICAL = 'vertical';
export const DIRECTION_VERTICAL_REVERSE = 'vertical-reverse';
export const DIRECTION_HORIZONTAL = 'horizontal';
export const DIRECTION_HORIZONTAL_REVERSE = 'horizontal-reverse';

const ArticleEntry = (props) => {
  const { t, locale } = useLocale('insight-card');

  const {
    coverVisible = true,
    article = null,
    direction = DIRECTION_VERTICAL,
  } = props;

  if (!article) {
    return;
  }

  const {
    id,
    smallImage,
    headerTh,
    headerEn,
    contentDate,
    descTh,
    descEn,
    tags = [],
  } = article;

  const inputBoxes = [];

  if(tags){
    for (let i of tags) {
      inputBoxes.push(<Tag title={i.name} />);
    }
  }
  return (
    <Link
      className={cx(
        'relative',
        'pd-4',
        'bg-white',
        'sm:hover:bg-primary-50',
        'border',
        'border-primary-50',
        'hover:border-primary-100',
        'transition-all',
        'duration-700',
        'ease-in-out',
        'flex',
        'justify-start',
        'items-stretch',
        'flex-col',
        'h-full',
        { 'sm:h-[370px] sm:flex-row': direction === DIRECTION_HORIZONTAL },
        { 'sm:h-[370px] sm:flex-row-reverse': direction === DIRECTION_HORIZONTAL_REVERSE },
        { 'sm:flex-col': direction === DIRECTION_VERTICAL },
        { 'sm:flex-col-reverse': direction === DIRECTION_VERTICAL_REVERSE },
        s.container
      )}
      to={`/${locale}/insight/${id}` || '#'}
    >
      {coverVisible ? (
        <>
          <div
            className={cx(
              'relative',
              'flex-1',
              'aspect-square',
              'bg-primary-50',
              'bg-cover',
              'bg-no-repeat',
              'bg-center'
            )}
            style={{ backgroundImage: `url(${smallImage || emptyImg})` }}
          />
          <div className={cx('w-4', 'aspect-square')} />
        </>
      ) : null}
      <div
        className={cx(
          'relative',
          'flex-1',
          'p-4',
          'flex',
          'flex-col',
          'justify-start',
          'items-stretch',
          { 'aspect-square': !coverVisible }
        )}
      >
        <div
          className={cx('flex', 'flex-wrap', 'gap-1.5', 'py-2', 'min-h-[35px]')}
        >
          {inputBoxes}
        </div>
        <h4
          className={cx(
            'overflow-hidden',
            'mt-2',
            'break-words',
            'line-clamp-2',
            'text-ellipsis'
          )}
        >
          {locale === locals.th ? headerTh : headerEn}
        </h4>
        <hr
          className={cx(
            'block',
            'mt-4',
            'w-full',
            'border-0',
            'h-px',
            'bg-primary-50'
          )}
        />
        <span
          className={cx(
            'mt-2',
            'mb-4',
            'font-light',
            'text-sm',
            'text-primary-400'
          )}
        >
          {getDateByLocale(contentDate, locale)}
        </span>
        <div
          className={cx(
            'overflow-hidden',
            'text-ellipsis',
            'break-words',
            'line-clamp-5'
          )}
        >
          {locale === locals.th ? descTh : descEn}
        </div>
        <a
          className={cx(
            'flex',
            'justify-end',
            'items-center',
            'pt-4',
            'text-sm',
            'font-medium',
            'text-center',
            'text-black',
            'rounded-lg'
          )}
        >
          {t('readMore')}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="3.5"
            stroke="currentColor"
            className={cx('w-3', 'h-3', 'size-3', 'ml-2')}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </a>
      </div>
    </Link>
  );
};

ArticleEntry.propTypes = {
  coverVisible: PropTypes.bool,
  article: PropTypes.object,
  direction: PropTypes.oneOf([
    DIRECTION_HORIZONTAL,
    DIRECTION_HORIZONTAL_REVERSE,
    DIRECTION_VERTICAL,
    DIRECTION_VERTICAL_REVERSE,
  ]),
};

export default ArticleEntry;
