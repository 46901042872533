import {toJS} from "mobx";
import {useLocalStore} from "mobx-react";
import {compressToBase64, decompressFromBase64} from 'lz-string';

export const storeToString = store => {
  return JSON.stringify(toJS(store), null, 2);
};

export const createProvider = (Context, createStore) => {
  return (props = {}) => {
    const {children} = props;
    const store = useLocalStore(createStore);
    return (
      <Context.Provider value={store}>
        {children}
      </Context.Provider>
    );
  };
};

export const combineProviders = (...components) => {
  return components.reduce((AccumulatedComponents, CurrentComponent) => {
    return (props = {}) => {
      const {children} = props;
      return (
        <AccumulatedComponents>
          <CurrentComponent>
            {children}
          </CurrentComponent>
        </AccumulatedComponents>
      );
    };
  });
};

export const compressData = value => {
  return compressToBase64(JSON.stringify(value, null, 2));
};

export const restoreObjectFromSessionStorage = key => {
  try {
    const session = JSON.parse(decompressFromBase64(sessionStorage[key]));
    // const session = JSON.parse(sessionStorage[key]);
    console.log(`>>restore_${key}`, session);
    return session;
  } catch (error) {
    console.log('>>restore_object_from_session_storage_error<<', error);
    return null;
  }
};

export const saveObjectToSessionStorage = (key, value) => {
  sessionStorage[key] = compressData(value);
  // sessionStorage[key] = JSON.stringify(value, null, 2);
};
