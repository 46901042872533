import React from 'react';
import cx from 'classnames';

import imgLocation from '@/assets/images/page-event-section-location/location.svg';
import imgMap from '@/assets/images/page-event-section-location/map.png';


const EventLocationSection = (props = {}) => {

  return (
    <section className={cx('relative', 'py-[120px]')}>
      <div className={cx('relative', 'content')}>
        <div className={cx('space-x-4', 'flex', 'flex-row', 'justify-start', 'items-center')}>
          <img alt="Location" src={imgLocation} className={cx('w-9', 'h-9')}/>
          <h2 className={cx('text-legacy-purple')}>Location</h2>
        </div>
        <hr className={cx('border-0', 'w-full', 'h-px', 'mt-4', 'mb-8', 'bg-primary-50')}/>
        <div className={cx('flex', 'flex-row', 'justify-start', 'items-stretch', 'bg-primary-50')}>
          <div className={cx('relative', 'px-8', 'flex-1')}>
            <div className={cx('space-y-8', 'py-12', 'w-full', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
              <span className={cx('block', 'font-medium', 'text-3.5xl', 'text-primary-500')}>
                Queen Sirikit National Convention Center,
                Plenary Hall 1-4
              </span>
              <p className={cx('block', 'text-2xl', 'text-primary-400')}>
                60 Ratchadaphisek Rd, Khlong Toei, Bangkok 10110
                <br/>
                <a target="_blank" href="https://www.qsncc.com/"
                   className={cx('underline', 'hover:text-primary-600')}>
                  https://www.qsncc.com/
                </a>
                <br/>
                022293000
              </p>
            </div>
          </div>
          <img alt="Map" src={imgMap} className={cx('w-[416px]', 'h-auto')}/>
        </div>
      </div>
    </section>
  );

};

export default EventLocationSection;