import {cloneDeep} from 'lodash';
import {action, computed, makeObservable, observable} from 'mobx';
import dayjs from 'dayjs';

import nonMemberEnquirySchema from '@/views/contact/stores/contact-store/schemas/non-member-enquiry';
import memberEnquirySchema from '@/views/contact/stores/contact-store/schemas/member-enquiry';
import {restoreObjectFromSessionStorage, saveObjectToSessionStorage} from "@/utils/store-utils";
import BaseStore from '@/utils/base-store';

export const CONTACT_MODE_NON_MEMBER = 'non-member';
export const CONTACT_MODE_MEMBER = 'member';

export const CONTACT_MODE_UNDER_50M = 'under-50m';


class ContactStore extends BaseStore {

  // Observable
  nonMemberEnquirySchema = nonMemberEnquirySchema;
  memberEnquirySchema = memberEnquirySchema;
  visible = false;
  mode = CONTACT_MODE_NON_MEMBER;
  step = 0;
  channel = '';
  personalityIndex = undefined;
  capitalIndex = undefined;
  serviceIndices = [];
  appointmentDate = undefined;
  initialAppointmentDate = undefined;
  memberEnquiry = undefined;
  nonMemberEnquiry = undefined;
  additionalMessage = undefined;
  consented = false;
  onClose = undefined;

  // Computed

  get totalSteps() {
    switch (this.mode) {
      case CONTACT_MODE_NON_MEMBER:
        return 8;
      case CONTACT_MODE_MEMBER:
        return 2;
      case CONTACT_MODE_UNDER_50M:
        return 3;
      default:
        return 0;
    }
  }

  constructor() {
    super();
    makeObservable(this, {
      visible: observable,
      mode: observable,
      step: observable,
      channel: observable,
      personalityIndex: observable,
      capitalIndex: observable,
      serviceIndices: observable,
      appointmentDate: observable,
      memberEnquiry: observable,
      nonMemberEnquiry: observable,
      additionalMessage: observable,
      initialAppointmentDate: observable,
      consented: observable,
      onClose: observable,
      totalSteps: computed,
      next: action,
      back: action,
      reset: action,
      setMode: action,
      setVisible: action,
      setOnClose: action,
      setPersonalityIndex: action,
      setCapitalIndex: action,
      addOrRemoveServiceIndex: action,
      setAppointmentDate: action,
      setNonMemberEnquiry: action,
      setMemberEnquiry: action,
      setAdditionalMessage: action,
      setConsented: action,
    });
    this.setInitialAppointmentDate();
  }

  setInitialAppointmentDate() {
    const todayDate = dayjs().startOf('d');
    let initialDate = todayDate.add(8, 'd');
    while (initialDate.day() === 0 || initialDate.day() === 6) {
      initialDate = initialDate.add(1, 'd');
    }
    this.appointmentDate = initialDate.toDate();
    this.initialAppointmentDate = initialDate.toDate();
  }

  back() {
    if (this.step === 0) {
      this.setVisible(false);
      return;
    }
    this.step--;
  }

  reset() {
    this.visible = false;
    this.step = 0;
    this.personalityIndex = undefined;
    this.capitalIndex = undefined;
    this.serviceIndices = [];
    this.channel = '';
    this.memberEnquiry = undefined;
    this.nonMemberEnquiry = undefined;
    this.additionalMessage = undefined;
    this.consented = false;
    this.setInitialAppointmentDate();
  }

  next() {
    if (this.step === this.totalSteps) {
      if (this.onClose) this.onClose();
      this.setVisible(false);
      return;
    }
    this.step++;
    window.dataLayer.push({
      'event': "contactDialog",
      'step': this.step,
      'mode' : this.mode,
      'captialIndex' : this.capitalIndex,
    });
  }

  setMode(mode) {
    this.mode = mode;
  }

  setVisible(visible) {
    this.visible = visible;
  }

  setOnClose(onClose) {
    this.onClose = onClose;
  }

  setChannel(channel) {
    this.channel = channel;
  }

  setPersonalityIndex(index) {
    this.personalityIndex = index;
  }

  setCapitalIndex(index) {
    this.capitalIndex = index;
  }

  addOrRemoveServiceIndex(index) {
    if (this.serviceIndices.includes(index)) {
      this.serviceIndices = this.serviceIndices.filter(si => si !== index);
    } else {
      this.serviceIndices = [...this.serviceIndices, index];
    }
  }

  setAppointmentDate(date) {
    this.appointmentDate = date;
  }

  setNonMemberEnquiry(data) {
    this.nonMemberEnquiry = data;
    console.log('>>non_member_enquiry<<', data);
  }

  setMemberEnquiry(data) {
    this.memberEnquiry = data;
    console.log('>>member_enquiry<<', data);
  }

  setAdditionalMessage(message) {
    this.additionalMessage = message;
    console.log('>>additional_message<<', message);
  }

  setConsented(consented) {
    this.consented = consented;
    console.log('>>consented<<', consented);
  }

}
export default () => new ContactStore();