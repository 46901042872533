import React, {useCallback, useEffect, useMemo, useRef,useState} from 'react';
import cx from 'classnames';
import {observer} from 'mobx-react';

import useLocale from '@/hooks/useLocale';
import {useContactStore} from '@/views/contact/stores/contact-context';
import ContactLayout from '@/views/contact/layouts/ContactLayout';

import s from './styles.module.scss';
import {Trans} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
	apisContactUs
} from '@/apis/ContactUs/index';

const ExpectationSection = observer((props = {}) => {
  const [ipAddress, setIpAddress] = useState('');
  const {t, locale } = useLocale('dialog-contact');
  const contactStore = useContactStore();
  const {visible} = contactStore;

  const formRef = useRef(undefined);
  const formMethods = useForm({
    reValidateMode: 'onChange',
  });


  useEffect(() => {
    // Fetch IP address when the component mounts
    const fetchIpAddress = async () => {
      try {
        const response = await fetch('https://api64.ipify.org?format=json');
        const data = await response.json();
        setIpAddress(data.ip);
        console.log(data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };
  
    fetchIpAddress(); // Call the async function
  
  }, []); // Run once on component mount



  const {
    watch,
    reset,
    register,
    formState: {errors},
    setValue,
    clearErrors,
    handleSubmit,
  } = formMethods;

  const consentWatch = watch('consent');

  useEffect(() => {
    const {current: form} = formRef;
    if (!form || !visible) return;
    clearErrors();
    setTimeout(() => {
      reset();
      form.reset();
      setValue('consent', false);
    });
  }, [visible, reset, clearErrors, setValue]);

  
  const onClickNext = useCallback((value) => {
    const {current: form} = formRef;
    form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }, [contactStore])

  const getValueByIndex = (index,data) => {
    const keys = Object.keys(data.options);
    const keyAtIndex = keys[index];
    //const valueAtIndex = data.options[keyAtIndex];
    return keyAtIndex;
  };

   
  const onSubmit = useCallback(async (value) => {
    const {additionalMessage, consent: consented} = value;
    contactStore.setAdditionalMessage(additionalMessage);
    contactStore.setConsented(consented);
    contactStore.next();
  
    const root_url = process.env.PUBLIC_URL; 
    const response = await  fetch(root_url+`/locales/${locale}/dialog-contact.json`); // Update the path accordingly
    const data =  await response.json();
    //InvestorType personality personalityIndex *********
      const indexToGetpersonality = contactStore.personalityIndex;
      const investorType = getValueByIndex(indexToGetpersonality,data.personality);
      
    //InvestorMoney capital capitalIndex *********
    const indexToGetcapital = contactStore.capitalIndex;
    const investorMoney = getValueByIndex(indexToGetcapital,data.capital);  
    console.log(investorMoney)
    //InvestorPurpose services serviceIndices [] *********
    // const [] indexToGetservice ;
    const indexToGetservice = contactStore.serviceIndices;
    let investorPurpose="";
    for (let i = 0; i < indexToGetservice.length; i++) {
      const currentIndex = indexToGetservice[i];
      const valueAtIndex = getValueByIndex(currentIndex, data.services);
      // Update keyAtServiceIndex with the obtained value
      if(i > 0){
        investorPurpose += `,${valueAtIndex}`;
      }
      else
      {
        investorPurpose += `${valueAtIndex}`;
      }
    }
      
    //  Perform your API call logic here
    // Include the email value in the API call
    // const headers = {'Content-Type':'application/json',
    //                 'Access-Control-Allow-Origin':'*',
    //                 'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS'}
    // fetch(`${process.env.REACT_APP_CRATE_CONTACT_US}`, {
    //   method: 'POST', // or 'GET', 'PUT', etc.
    //   headers: headers,
    //   body: JSON.stringify({ AppLanguage :locale , IsAccount :  contactStore.mode,Name : contactStore.nonMemberEnquiry.firstName,
    //     LastName : contactStore.nonMemberEnquiry.lastName,
    //     Phone : contactStore.nonMemberEnquiry.phoneNumber,Email : contactStore.nonMemberEnquiry.email,AdvisorName : null,InvestDesc : additionalMessage,
    //     IsAccepted : contactStore.consented,InvestorType : investorType,InvestorMoney : investorMoney,InvestorPurpose : investorPurpose.trim(),
    //     ConvenientContactDate : contactStore.appointmentDate,InvestorChannel : contactStore.channel.value,
    //     IpAddress : ipAddress }),
    // })
    //   .then(response => response.text())
    //   .then(data => {
    //     // Handle the API response data
    //     console.log(data);
    //     if (data === "Success") {
    //       // Handle success case
    //       console.log("API call was successful");
    //   } else {
    //       // Handle other cases
    //       console.log("API call was not successful");
    //   }
    //   })
    //   .catch(error => {
    //     // Handle errors
    //     console.error('Error:', error);
    //   })
    //   .finally(() => {
    //   });

      const isAccount = contactStore.mode === null ? 'N' : contactStore.mode === 'non-member' ? 'N' : 'Y'
      const result = await apisContactUs.createContactUs(locale,isAccount,contactStore.nonMemberEnquiry.firstName,
        contactStore.nonMemberEnquiry.lastName,contactStore.nonMemberEnquiry.phoneNumber,contactStore.nonMemberEnquiry.email,
        null,additionalMessage,contactStore.consented,investorType,investorMoney,investorPurpose.trim(),contactStore.appointmentDate,
        contactStore.channel.value,ipAddress
      )
      const { apiData } = result.data;

      if (apiData === "Success") {
        console.log("API call was successful");
      } else {
        console.log("API call was not successful");
      }



  }, [contactStore ,ipAddress, locale]);

  const footerComponent = useMemo(() => {
    return (
      <div className={cx('flex', 'flex-row', 'justify-start', 'items-stretch', s.footer)}>
        <button className={cx('flex-1', 'btn', 'btn-submit')} disabled={!consentWatch} onClick={onClickNext}>
          <span className={cx('tracking-wider')}>
            Submit
          </span>
        </button>
      </div>
    );
  }, [onClickNext, consentWatch]);

  const handleInput = (event) => {
    const inputValue = event.target.value;
    // Regular expression to allow only valid email characters
    const validRegex = /^[a-zA-Z0-9._ก-๙-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}!,() $/;

    // Check if the input value matches the valid email pattern
    if (!validRegex.test(inputValue)) {
      // If not valid, replace the input value without special characters
      const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9._@ก-๙-]/g, '');
      event.target.value = sanitizedValue;
    }
  };

 
  
  return (
    <ContactLayout footerComponent={footerComponent}>
      <div className={cx(
        'flex-1', 'px-4', 'sm:px-8',
        'flex', 'flex-col', 'sm:flex-row', 'justify-center', 'items-stretch',
        s.container
      )}>
        <div className={cx(
          'pb-4', 'sm:py-6', 'box-border', 'sm:flex-1',
          'space-y-4', 'sm:space-y-6', 'flex', 'flex-col', 'justify-center', 'items-center'
        )}>
          <h2 className={cx(
            'font-semibold', 'text-center', 'text-legacy-purple',
            {'font-sans': locale !== 'en', 'font-serif': locale === 'en'},
          )}>
            {t('expectation.title')}
          </h2>
          <hr/>
        </div>
        <div className={cx('w-8')}/>
        <form ref={formRef} className={cx(
          'block', 'relative', 'box-border', 'pb-4', 'sm:py-6', 'flex-1',
          'flex', 'flex-col', 'justify-start', 'items-stretch',
        )} onSubmit={handleSubmit(onSubmit)}>
          <div className={cx(
           'space-y-4', 'flex-1', 'flex', 'flex-col', 'justify-center', 'items-stretch'
          )}>
            <div className={cx('flex-1', 'space-x-4', 'flex', 'flex-row', 'justify-start', 'items-stretch')}>
              <textarea className={cx('flex-1', 'box-border')}  {...register('additionalMessage')}
                        placeholder={t('expectation.additionalMessage.placeholder')}/>
            </div>
            <label className={cx('space-x-2', 'flex', 'flex-row', 'justify-start', 'items-center')}>
              <input type="checkbox" {...register('consent')}/>
              <span className={cx('text-legacy-purple')}>
                <Trans t={t} i18nKey="expectation.consent">
                  <a target="_blank" className={cx('underline', 'link', 'link-legacy-purple')}
                     href={`https://kkpfg.com/${locale}/dataprotection`}/>
                </Trans>
              </span>
            </label>
          </div>
        </form>
      </div>
    </ContactLayout>
  );

});

export default ExpectationSection;