import {useMemo} from 'react';

export default localeComponents => {

  const {t, locale} = localeComponents;

  const paths = useMemo(() => {
    return [
      {id: 1, title: 'Home', url: `/${locale}`},
      {id: 2, title: 'Lending', url: `/${locale}/solution/lending`},
      {id: 3, title: t('head.title'), url: ''},
    ];
  }, [t, locale]);

  return {paths};

};