import React, { useCallback, useEffect, useState } from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import cx from "classnames";

import { useIsScreenSm } from "@/providers/ScreenSizeProvider";
import HomeBannerCard from "@/views/home/pages/HomePage/sections/HomeBannerSection/components/HomeBannerCard";
import NavigationPane from "@/views/home/pages/HomePage/sections/HomeBannerSection/components/NavigationPane";

import s from "./styles.module.scss";

import imgNormalIndicator from "@/assets/images/page-home-section-banner/indicator-normal.svg";
import imgActiveIndicator from "@/assets/images/page-home-section-banner/indicator-active.svg";
import imgBannerdefault from "@/assets/images/page-home-section-banner/banner-default-bg.png";
import imgMobileBannerdefault from "@/assets/images/page-home-section-banner/mobile-banner-default-bg.png";
import imgBannerEmpty from "@/assets/images/page-home-section-banner/banner-empty-bg.png";
import imgMoblieBannerEmpty from "@/assets/images/page-home-section-banner/mobile-banner-empty-bg.png";
import imgBannerLoading from "@/assets/images/page-home-section-banner/banner-loading-bg.png";
import imgMoblieBannerLoading from "@/assets/images/page-home-section-banner/mobile-banner-loading-bg.png";
import { apisHomePageBanner } from '@/apis/HomePageBanner/index';
import { checkImageExistsAsync } from '@/utils/image-utils';
import useLocale from "@/hooks/useLocale";

async function fetchBannersAsync(locale) {
  let banners = [];
  try {
    const result = await apisHomePageBanner.getHomePageBanner(null, null);
    const dataBanner = result.data;

    if (dataBanner && dataBanner.Banner.length > 0) {
      for (const banner of dataBanner.Banner) {
        let mode;
        let smallBannerImage = banner.SmallBannerImage;
        let smallLayoutImage = banner.SmallLayoutImage;
        let largeBannerImage = banner.LargeBannerImage;
        let largeLayoutImage = banner.LargeLayoutImage;

        const smallImageExists = await checkImageExistsAsync(smallBannerImage);
        if (!smallImageExists) {
          smallBannerImage = imgMobileBannerdefault;
          smallLayoutImage = false;
        }

        const largeImageExists = await checkImageExistsAsync(largeBannerImage);
        if (!largeImageExists) {
          largeBannerImage = imgBannerdefault;
          largeLayoutImage = false;
        }

        mode = banner.TextAlignment === "L" ? "ltr" : "rtl";

        const obj = {
          id: banner.BannerId,
          order: banner.BannerOrder,
          title: locale === "en" ? banner.HeaderEn : banner.HeaderTh,
          description: locale === "en" ? banner.DescEn : banner.DescTh,
          showbutton: banner.IsDisplayButton,
          buttonText: locale === "en" ? banner.ButtonNameEn : banner.ButtonNameTh,
          banners: {
            mobile: {
              background: smallBannerImage,
              overlay: smallLayoutImage,
            },
            desktop: {
              background: largeBannerImage,
              overlay: largeLayoutImage,
            },
          },
          mode: mode,
          url: banner.ButtonUrl,
        };
        banners.push(obj);
      }
    } else {
      banners.push({
        id: 1,
        order: 1,
        title: null,
        description: null,
        showbutton: false,
        buttonText: null,
        banners: {
          mobile: {
            background: imgMoblieBannerEmpty,
            overlay: null,
          },
          desktop: {
            background: imgBannerEmpty,
            overlay: null,
          },
        },
        mode: null,
        url: null,
      });
    }
  } catch (error) {
    banners.push({
      id: 1,
      order: 1,
      title: null,
      description: null,
      showbutton: false,
      buttonText: null,
      banners: {
        mobile: {
          background: imgMoblieBannerEmpty,
          overlay: null,
        },
        desktop: {
          background: imgBannerEmpty,
          overlay: null,
        },
      },
      mode: null,
      url: null,
    });
  }
  return banners;
}

const HomeBannerSection = () => {
  const { locale } = useLocale("");
  const isScreenSm = useIsScreenSm();
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [banners, setBanners] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const bannersLoading = [
    {
      id: 1,
      title: null,
      description: null,
      showbutton: false,
      buttonText: null,
      banners: {
        mobile: {
          background: imgMoblieBannerLoading,
          overlay: null,
        },
        desktop: {
          background: imgBannerLoading,
          overlay: null,
        },
      },
      mode: null,
      url: null,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const b = await fetchBannersAsync(locale);
        setBanners(b);
      } catch (error) {
        setIsError(true);
        console.error("Error fetching banners:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [locale]);

  const onClickIndicator = useCallback(
    (event, index) => {
      event.preventDefault();
      event.stopPropagation();
      if (swiper) {
        swiper.slideTo(index);
      }
    },
    [swiper]
  );

  useEffect(() => {
    if (swiper) {
      swiper.autoplay.start();
    }
  }, [swiper, banners]);

  return (
    <section className={cx("relative", s.container)}>
      <Swiper
        className={cx("w-full")}
        slidesPerView={1}
        spaceBetween={0}
        speed={1200}
        loop
        noSwiping
        allowTouchMove={false}
        modules={[Autoplay]}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
        onSwiper={setSwiper}
      >
        {(isLoading ? bannersLoading : banners).map((banner, index) => {
          const { id } = banner;
          return (
            <SwiperSlide key={id}>
              <HomeBannerCard active={activeIndex === index} {...banner} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {isScreenSm ? null : <NavigationPane swiper={swiper} />}
      {banners.length > 1 && (
        <div
          className={cx(
            "mt-2",
            "sm:mt-4",
            "relative",
            "z-10",
            "content",
            "space-x-2",
            "flex",
            "flex-row",
            "justify-center",
            "items-center"
          )}
        >
          {banners.map((banner, index) => {
            const { id } = banner;
            return (
              <a
                key={id}
                href="#"
                onClick={(event) => onClickIndicator(event, index)}
                className={cx(
                  "block",
                  "w-2",
                  "h-2",
                  "bg-contain",
                  "bg-center",
                  "bg-no-repeat"
                )}
                style={{
                  backgroundImage: `url(${
                    activeIndex !== index
                      ? imgNormalIndicator
                      : imgActiveIndicator
                  })`,
                }}
              />
            );
          })}
        </div>
      )}
    </section>
  );
};

export default HomeBannerSection;
