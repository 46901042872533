import React from 'react';
import cx from 'classnames';

import TimelineGroup from "./components/TimelineGroup";

import s from './styles.module.scss';


const PageWhyUsExpertiseTimelineSection = (props = {}) => {

  const {groups = []} = props;

  return (
    <section className={cx('relative', s.container)}>
      <div className={cx(
        'content', 'space-y-10',
        'flex', 'flex-col', 'justify-start', 'items-stretch',
      )}>
        <h1 className={cx('text-primary-900')}>Awards and Ranking</h1>
        <div className={cx('flex', 'flex-col', 'justify-start', 'align-stretch')}>
          {
            groups.map((group,  index) => {
              const {year} = group;
              const firstItem = index === 0;
              const lastItem = index === groups.length - 1;
              return <TimelineGroup key={year} yearIndented={firstItem} active={!lastItem} group={group}/>;
            })
          }
        </div>
      </div>
    </section>
  );

};

export default PageWhyUsExpertiseTimelineSection;