
import React, { useMemo, useEffect, useState } from 'react';
import cx from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';

import StandardLayout from '@/views/common/layouts/StandardLayout';
import useLocale from '@/hooks/useLocale';
import useIsMobile from '@/hooks/useIsMobile'; 
import NewsLetterSection from '@/views/common/section/NewsLetterSection';
import BreadCrumbs from '@/views/common/components/BreadCrumbs';
import InsightDetailsBanner from './sections/InsightDetailsBanner';
import InsightDetailsArticleSection from './sections/InsightDetailsArticleSection';
import { apisInsightDetail } from '@/apis/Insight/index';
import imgNoContent from '@/assets/images/common/no-content.png';
import imgBannerDefault from "@/assets/images/page-home-section-banner/banner-default-bg.png";
import imgMobileBannerDefault from "@/assets/images/page-home-section-banner/mobile-banner-default-bg.png";
import imgBannerLoading from "@/assets/images/page-insight-details-section-banner/section-banner.jpg";
import imgMoblieBannerLoading from "@/assets/images/page-insight-details-section-banner/section-banner.jpg";
import { locals } from '@/utils/locales';
import { checkImageExistsAsync } from '@/utils/image-utils';

async function fetchInsightsAsync(id) {
  if (!id) {
    throw new Error("ID is required to fetch insights");
  }

  try {
    // Fetch the insight detail
    const result = await apisInsightDetail.getInsightDetail(id);
    const dataInsight = result.data.insights;

    if (dataInsight && dataInsight.length > 0) {
      const insight = dataInsight[0]; 

      if (typeof insight !== 'object' || !insight) {
        throw new Error("Invalid insight data");
      }

      const [smallImageExists, largeImageExists] = await Promise.all([
        checkImageExistsAsync(insight.smallImage),
        checkImageExistsAsync(insight.largeImage)
      ]);

      return {
        id: insight.id || null,
        headerEn: insight.headerEn || '',
        headerTh: insight.headerTh || '',
        descEn: insight.descEn || '',
        descTh: insight.descTh || '',
        largeImage: largeImageExists ? insight.largeImage : null,
        smallImage: smallImageExists ? insight.smallImage : null,
        contentDate: insight.contentDate || '',
        keyTakeawayEn: insight.keyTakeawayEn || '',
        keyTakeawayTh: insight.keyTakeawayTh || '',
        contentEn: insight.contentEn || '',
        contentTh: insight.contentTh || '',
        downloadUrl: insight.downloadUrl || '',
        downloadText: insight.downloadText || '',
        tags: Array.isArray(insight.tags) ? insight.tags : [],
        authors: Array.isArray(insight.authors) ? insight.authors : [],
        guestAuthors: Array.isArray(insight.guestAuthors) ? insight.guestAuthors : [],
      };
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching insights:', error);
    throw new Error(`Failed to fetch insights: ${error.message}`);
  }
}


const InsightDetailsPage = () => {
  const { locale } = useLocale('');
  const { id } = useParams(); 
  const isMobile = useIsMobile(); 
  const [isLoading, setIsLoading] = useState(true);
  const [insightDetail, setInsightDetail] = useState(null);
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate()

  // Function to fetch data
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const insightDetail = await fetchInsightsAsync(id);
      if (!insightDetail) {
        setIsError(true);
        navigate(`/${locale}/404`);
      } else {
        setInsightDetail(insightDetail);
        setIsError(false);
      }
    } catch (error) {
      setIsError(true);
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const breadcrumbsPaths = useMemo(() => {
    return [
      { id: 1, title: 'Home', url: `/${locale}` },
      { id: 3, title: 'Insights', url: `/${locale}/insight/overview` },
      { id: 4, title: insightDetail?.headerEn || 'Insights Detail', url: '' }
    ];
  }, [locale, insightDetail]);

  const bannerdLoadingImage = isMobile ? imgMoblieBannerLoading : imgBannerLoading;
  const bannerDefaultImage = isMobile ? imgMobileBannerDefault : imgBannerDefault;

  return (
    <StandardLayout>
      <BreadCrumbs paths={breadcrumbsPaths} />
      {isLoading ? (
        <>
          <InsightDetailsBanner imageUrl={bannerdLoadingImage} isLoading={isLoading} />
          <InsightDetailsArticleSection insight={null} isLoading={isLoading} />
        </>
      ) : isError ? (
        <div className={cx('py-20', 'flex', 'flex-col', 'items-center')}>
          <img className={cx('h-[80px]', 'w-[80px]')} src={imgNoContent} alt="No Content" />
          <h3 className={cx('py-2', 'text-primary-200', 'text-center', 'font-sans', 'font-medium', 'text-xl', 'sm:text-2xl')}>
            {locale === locals.en 
              ? "We're sorry, but an unexpected error occurred." 
              : "ขออภัย มีข้อผิดพลาดที่ไม่คาดคิดเกิดขึ้น"}
          </h3>
          <h3 className={cx('pb-7', 'text-primary-200', 'text-center', 'font-sans', 'font-medium', 'text-xl', 'sm:text-2xl')}>
            {locale === locals.en 
              ? "Please try again later." 
              : "กรุณาลองใหม่อีกครั้งในภายหลัง"}
          </h3>
          <button onClick={fetchData} className={cx('bg-white', 'btn', 'btn-outlined', 'btn-outlined-light', 'btn-m')}>
            <span className={cx('font-medium')}>
              {locale === locals.en ? "TRY AGAIN" : "ลองอีกครั้ง"}
            </span>
          </button>
        </div>
      ) : (
        <>
          <InsightDetailsBanner 
            imageUrl={isMobile ? insightDetail?.smallImage : insightDetail?.largeImage || bannerDefaultImage} 
            isLoading={isLoading} 
          />
          <InsightDetailsArticleSection insight={insightDetail} isLoading={isLoading} />
        </>
      )}
      <NewsLetterSection />
    </StandardLayout>
  );
  
};

export default InsightDetailsPage;
