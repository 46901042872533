import React from 'react';
import cx from 'classnames';

import StandardLayout from '@/views/common/layouts/StandardLayout';

import s from './styles.module.scss';


const SolutionApproachLayout = (props = {}) => {

  const {children} = props;

  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch', s.container)}>
        {children}
      </div>
    </StandardLayout>
  );

};

export default SolutionApproachLayout;