import React from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';

export const TAG_MODE_NORMAL = 'tag-mode-normal';
export const TAG_MODE_LIGHT = 'tag-mode-light';


const Tag = (props = {}) => {

  const {title, mode = TAG_MODE_NORMAL} = props;

  return (
    <div className={cx('flex', 'flex-col', 'justify-center', 'items-center')}>
      <div className={cx(
        'inline-block', 'px-1', 'min-h-5', 'pt-[1px]', 'text-center',
        'rounded', 'border', 'border-primary-400', 'leading-[0]',
        {'border-white': mode === TAG_MODE_LIGHT},
      )}>
        <span className={cx(
          'font-medium', 'uppercase', 'text-xs', 'text-primary-400', 'break-all',
          {'text-white': mode === TAG_MODE_LIGHT},
        )}>
          {title}
        </span>
      </div>
    </div>
  )

};

Tag.propTypes = {
  title: PropTypes.string.isRequired,
  mode: PropTypes.oneOf([
    TAG_MODE_NORMAL,
    TAG_MODE_LIGHT,
  ]),
};

export default Tag;