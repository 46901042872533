import React, {useMemo} from 'react';
import cx from 'classnames';

import gridRelatedProducts from '@/views/goal/pages/GoalFamilyPage/data/grid-related-products';
import familyWealthPlanningRelatedProducts
  from '@/views/goal/pages/GoalFamilyPage/data/family-wealth-planning-related-products';
import supportLovedOnesRelatedProducts
  from '@/views/goal/pages/GoalFamilyPage/data/support-loved-ones-related-products';
import philanthropyRelatedProducts from '@/views/goal/pages/GoalFamilyPage/data/philanthropy-related-products';
import useLocale from "../../../../hooks/useLocale";
import StandardLayout from "@/views/common/layouts/StandardLayout";
import BreadCrumbs from "../../../common/components/BreadCrumbs";
import GoalFamilyHeadSection from "./sections/GoalFamilyHeadSection";
import GoalQuestionSection from '@/views/goal/sections/GoalQuestionSection';
import FamilyWealthPlanningSection from '@/views/goal/pages/GoalFamilyPage/sections/FamilyWealthPlanningSection';
import SupportLovedOnesSection from '@/views/goal/pages/GoalFamilyPage/sections/SupportLovedOnesSection';
import PhilanthropySection from '@/views/goal/pages/GoalFamilyPage/sections/PhilanthropySection';
import GoalHelpSection from '@/views/goal/sections/GoalHelpSection';
import GoalRelatedProductsGridSection from '@/views/goal/sections/GoalRelatedProductsGridSection';

import s from './styles.module.scss';


const GoalFamilyPage = (props = {}) => {

  const {t, i18n, locale} = useLocale('page-goal-family');

  const breadcrumbsPaths = useMemo(() => {
    return [
      {id: 1, title: 'Home', url: `/${locale}`},
      {id: 2, title: 'What\'s your goal', url: ''},
      {id: 3, title: t('pageName'), url: ''},
    ];
  }, [t, locale]);

  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <BreadCrumbs paths={breadcrumbsPaths}/>
        <GoalFamilyHeadSection/>
        <GoalQuestionSection/>
        <FamilyWealthPlanningSection relatedProducts={familyWealthPlanningRelatedProducts}/>
        <SupportLovedOnesSection relatedProducts={supportLovedOnesRelatedProducts}/>
        <PhilanthropySection relatedProducts={philanthropyRelatedProducts}/>
        <GoalHelpSection/>
        <GoalRelatedProductsGridSection namespace="solutions" relatedProducts={gridRelatedProducts}/>
      </div>
    </StandardLayout>
  );

};

export default GoalFamilyPage;