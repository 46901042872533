import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import cx from 'classnames';
import {observer} from 'mobx-react';
import {Trans} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import {splitLines} from '@/utils/html-utils';
import useLocale from '@/hooks/useLocale';
import {useContactStore} from '@/views/contact/stores/contact-context';
import ContactLayout from '@/views/contact/layouts/ContactLayout';

import s from './styles.module.scss';
import {
	apisContactUs
} from '@/apis/ContactUs/index';

const MemberEnquirySection = observer((props = {}) => {
  const [ipAddress, setIpAddress] = useState('');
  const {t, locale} = useLocale('dialog-contact');

  const contactStore = useContactStore();
  const {visible, nonMemberEnquirySchema} = contactStore || {};

  const formRef = useRef(undefined);
  const formMethods = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(nonMemberEnquirySchema),
  });

  useEffect(() => {
    // Fetch IP address when the component mounts
    fetch('https://api64.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        setIpAddress(data.ip);
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });
  }, []); // Run once on component mount


  const {
    watch,
    reset,
    register,
    formState: {errors},
    setValue,
    clearErrors,
    handleSubmit,
  } = formMethods;

  const consentWatch = watch('consent');

  useEffect(() => {
    const {current: form} = formRef;
    if (!form || !visible) return;
    clearErrors();
    setTimeout(() => {
      reset();
      form.reset();
      setValue('consent', false);
    });
  }, [visible, reset, clearErrors, setValue]);

  const onClickNext = useCallback(() => {
    const {current: form} = formRef;
    form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }, [])
  

  const onSubmit = useCallback(async (value) => {
    const {additionalMessage, consent: consented} = value;
    delete value.additionalMessage;
    delete value.consented;

    contactStore.setMemberEnquiry(value);
    contactStore.setAdditionalMessage(additionalMessage);
    contactStore.setConsented(consented);
    contactStore.next();
     // Perform your API call logic here
    // Include the email value in the API call
    // const headers = {'Content-Type':'application/json',
    //                 'Access-Control-Allow-Origin':'*',
    //                 'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS'}
   
    // fetch(`${process.env.REACT_APP_CRATE_CONTACT_US}`, {
    //   method: 'POST', // or 'GET', 'PUT', etc.
    //   headers: headers,
    //   body: JSON.stringify({ AppLanguage :locale , IsAccount : contactStore.mode,Name : value.firstName,LastName : value.lastName,
    //     Phone : value.phoneNumber,Email : value.email,AdvisorName : value.consultantName,InvestDesc : additionalMessage,
    //     IsAccepted : contactStore.consented,InvestorType : null,InvestorMoney : null,InvestorPurpose : null,
    //     ConvenientContactDate : null,InvestorChannel : null,IpAddress : ipAddress }),
    // })
    //   .then(response => response.text())
    //   .then(data => {
    //     // Handle the API response data
    //     console.log(data);
    //     if (data === "Success") {
    //       // Handle success case
    //       console.log("API call was successful");
    //   } else {
    //       // Handle other cases
    //       console.log("API call was not successful");
    //   }
    //   })
    //   .catch(error => {
    //     // Handle errors
    //     console.error('Error:', error);
    //   })
    //   .finally(() => {
    //   });

    const isAccount = contactStore.mode === null ? 'N' : contactStore.mode === 'non-member' ? 'N' : 'Y'
      const result = await apisContactUs.createContactUs(locale,isAccount,value.firstName,
        value.lastName,value.phoneNumber,value.email,
        value.consultantName,additionalMessage,contactStore.consented,null,null,null,null,
        null,ipAddress
      )
      const { apiData } = result.data;

      if (apiData === "Success") {
        console.log("API call was successful");
      } else {
        console.log("API call was not successful");
      }


  }, [contactStore,ipAddress,locale]);

  const handleInput = (event) => {
    const inputValue = event.target.value;
    // Regular expression to allow only valid email characters
    const validRegex = /^[a-zA-Z0-9._ก-๙-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}!,() $/;

    // Check if the input value matches the valid email pattern
    if (!validRegex.test(inputValue)) {
      // If not valid, replace the input value without special characters
      const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9._@ก-๙-]/g, '');
      event.target.value = sanitizedValue;
    }
  };
 
  const validatePhoneNumber = (event) => {
    const inputValue = event.target.value;
    const phoneNumberRegex = /^\d{10}$/;

    if (!phoneNumberRegex.test(inputValue)) {
        // If not a valid phone number, replace the input value with only digits
        const sanitizedValue = inputValue.replace(/\D/g, ''); 
        event.target.value = sanitizedValue;
    }
};

  const footerComponent = useMemo(() => {
    return (
      <div className={cx('flex', 'flex-row', 'justify-start', 'items-stretch', s.footer)}>
        <button className={cx('flex-1', 'btn', 'btn-submit')} disabled={!consentWatch} onClick={onClickNext}>
          <span className={cx('tracking-wider')}>{t('submit')}</span>
        </button>
      </div>
    );
  }, [onClickNext, t, consentWatch]);

  return (
    <ContactLayout footerComponent={footerComponent}>
      <div className={cx(
        'flex-1', 'px-4', 'sm:px-8',
        'flex', 'flex-col', 'sm:flex-row', 'justify-center', 'items-stretch',
        s.container
      )}>
        <div className={cx(
          'pb-4', 'sm:py-6', 'box-border', 'flex-1',
          'space-y-4', 'sm:space-y-6',
          'flex', 'flex-col', 'justify-center', 'items-center'
        )}>
          <h2 className={cx(
            'font-sans', 'font-semibold', 'text-center', 'text-legacy-purple',
            {'font-serif': locale === 'en'}
          )}>
            {splitLines(t('nonMemberEnquiry.title'))}
          </h2>
          <hr/>
        </div>
        <div className={cx('sm:w-8')}/>
        <form ref={formRef} className={cx(
          'block', 'relative', 'box-border', 'pb-4', 'sm:py-6', 'flex-1',
          'max-h-[calc(100vh - 100px)]','sm:max-h-auto',
          'overflow-auto', 'sm:overflow-hidden',
        )} onSubmit={handleSubmit(onSubmit)}>
          <fieldset className={cx(
            'w-full', 'h-full', 'space-y-4',
            'flex', 'flex-col', 'justify-start', 'items-stretch'
          )}>
            <div className={cx(
              'space-y-4', 'sm:space-y-0', 'space-x-0', 'sm:space-x-4',
              'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch'
            )}>
              <input type="text" className={cx('flex-1', {[s.error]: errors.firstName})}
                     placeholder={t('nonMemberEnquiry.firstName.placeholder')}
                     maxLength={128}  {...register('firstName')}/>
              <input type="text" className={cx('flex-1', {[s.error]: errors.lastName})}
                     placeholder={t('nonMemberEnquiry.lastName.placeholder')}
                     maxLength={128}  {...register('lastName')}/>
            </div>
            <div className={cx(
              'space-y-4', 'sm:space-y-0', 'space-x-0', 'sm:space-x-4',
              'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch'
            )}>
              <input type="tel" className={cx('flex-1', {[s.error]: errors.phoneNumber})}
                     placeholder={t('nonMemberEnquiry.phoneNumber.placeholder')}
                     minLength={10} maxLength={10} onInput={validatePhoneNumber} {...register('phoneNumber')}/>
              <input type="email" className={cx('flex-1', {[s.error]: errors.email})}
                     placeholder={t('nonMemberEnquiry.email.placeholder')}
                     maxLength={64} 
                     
                     {...register('email')}/>
            </div>
            <div className={cx(
              'space-y-4', 'sm:space-y-0', 'space-x-0', 'sm:space-x-4',
              'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch'
            )}>
              <input type="text" className={cx('flex-1', {[s.error]: errors.consultantName})}
                     placeholder={t('nonMemberEnquiry.consultant.placeholder')}
                     maxLength={128}  {...register('consultantName')}/>
            </div>
            <div className={cx('flex-1', 'space-x-4', 'flex', 'flex-row', 'justify-start', 'items-stretch')}>
              <textarea className={cx('flex-1', 'box-border')}  {...register('additionalMessage')}
                        placeholder={t('nonMemberEnquiry.additionalMessage.placeholder')}/>
            </div>
            <label className={cx('space-x-2', 'flex', 'flex-row', 'justify-start', 'items-center')}>
              <input type="checkbox" {...register('consent')}/>
              <span className={cx('text-legacy-purple')}>
                <Trans t={t} i18nKey="nonMemberEnquiry.consent">
                  <a target="_blank" className={cx('underline', 'link', 'link-legacy-purple')}
                     href={`https://kkpfg.com/${locale}/dataprotection`}/>
                </Trans>
              </span>
            </label>
          </fieldset>
        </form>
      </div>
    </ContactLayout>
  );

});

export default MemberEnquirySection;