import React from 'react';
import cx from 'classnames';

import useLocale from "../../../../../../hooks/useLocale";
import GoalHeadSection from "../../../../sections/GoalHeadSection";

import s from './styles.module.scss';

import imgBanner from '../../../../../../assets/images/page-goal-business-section-head/banner.png';


const GoalBusinessHeadSection = (props = {}) => {

  const {t} = useLocale('page-goal-business-section-head');

  return (
    <GoalHeadSection title={t('title')} excerpt={t('excerpt')} banner={imgBanner}/>
  );

};

export default GoalBusinessHeadSection;