import React, {Fragment, useEffect, useRef, useState} from 'react';
import cx from 'classnames';

import {splitLines} from '@/utils/html-utils';
import useGoalDoubleColumnAnimation
  from "@/views/goal/sections/GoalDoubleColumnSection/hooks/useGoalDoubleColumnAnimation";
import useGoalHorizontalRelatedProductAnimation from '@/views/goal/hooks/useGoalHorizontalRelatedProductAnimation';
import ProductEntry from '@/views/goal/sections/GoalDoubleColumnSection/components/ProductEntry';
import {useIsScreenSm} from '@/providers/ScreenSizeProvider';

import s from './styles.module.scss';


export const GOAL_DOUBLE_SECTION_MODE_LTR = 'ltr';
export const GOAL_DOUBLE_SECTION_MODE_RTL = 'rtl';

const GoalDoubleColumnSection = (props = {}) => {

  const {
    mode = GOAL_DOUBLE_SECTION_MODE_LTR,
    banner, title, excerpt, description,
    products = [],
  } = props;

  const isScreenSm = useIsScreenSm();

  const containerRef = useRef(undefined);
  const contentRef = useRef(undefined);
  const slideRef = useRef(undefined);
  const trigger01Ref = useRef(undefined);
  const trigger02Ref = useRef(undefined);

  const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const {current: slide} = slideRef;
      if (!slide) return;
      setScrollWidth(slide.scrollWidth - slide.offsetWidth);
    }, 0);
  }, [slideRef]);

  useGoalDoubleColumnAnimation(trigger01Ref, contentRef);
  useGoalHorizontalRelatedProductAnimation(trigger02Ref, containerRef, slideRef, scrollWidth, !isScreenSm);

  return (
    <div className={cx('relative', 'pt-[72px]', 'sm:pt-[120px]', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
      <div ref={trigger01Ref} className={cx('absolute', 'top-[300px]', 'left-0', 'w-full', 'h-1/2')}/>
      <div ref={containerRef}>
        <section className={cx('relative', 'pb-[56px]', s.container)}>
          <div ref={contentRef} className={cx(
            'content',
            'flex', 'flex-col', 'justify-start', 'items-stretch',
            {'sm:flex-row': mode === GOAL_DOUBLE_SECTION_MODE_LTR},
            {'sm:flex-row-reverse': mode === GOAL_DOUBLE_SECTION_MODE_RTL},
          )}>
            <div className={cx(
              'flex-1', 'flex-shrink-0', 'aspect-square',
              'bg-legacy-purple', 'bg-cover', 'bg-no-repeat', 'bg-center',
              s.container
            )} style={{backgroundImage: `url(${banner})`}}/>
            <div className={cx('w-8')}/>
            <div className={cx(
              'relative', 'mt-[56px]', 'sm:mt-0',
              'flex-1', 'flex-shrink-0', 'flex', 'flex-col', 'justify-center', 'items-start'
            )}>
              <h1 className={cx('text-legacy-purple', 'leading-[1.1]')}>{splitLines(title)}</h1>
              <h4 className={cx('mt-4', 'text-2xl', 'text-primary-400')}>{splitLines(excerpt)}</h4>
              <hr className={cx('max-w-[90%]', 'sm:max-w-[316px]', 'w-full', 'border-0', 'my-4')}/>
              <p className={cx('text-xl', 'text-primary-400', 'leading-[1.2]', 'sm:leading-normal')}>
                {splitLines(description)}
              </p>
            </div>
          </div>
        </section>
        <section className={cx('relative', 'py-0', 'sm:py-8', s.container)}>
          <div ref={slideRef} className={cx(
            'content', 'flex', 'flex-row', 'justify-start', 'items-stretch',
            'overflow-x-auto', 'sm:overflow-x-visible',
            s.slide
          )}>
            <div className={cx(
              'min-w-[200px]', 'sm:min-w-[calc(33.333%-32px)]', 'sm:max-w-[calc(33.333%-32px)]', 'sm:min-h-[200px]',
              'flex', 'flex-row', 'justify-center', 'items-center',
              s.title
            )}>
              <h2 className={cx('text-legacy-purple')}>Related Product</h2>
            </div>
            {
              products.map(product => {
                const {id} = product;
                return (
                  <Fragment key={id}>
                    <div className={cx('min-w-[32px]', 'max-w-[32px]', 'flex-shrink-0')}></div>
                    <div className={cx(
                      'relative',
                      'min-w-[350px]', 'sm:min-w-[calc(33.333%-32px)]', 'sm:max-w-[calc(33.333%-32px)]',
                      'min-h-[200px]',
                    )}>
                      <ProductEntry product={product}/>
                    </div>
                  </Fragment>
                );
              })
            }
          </div>
        </section>
      </div>
      <div ref={trigger02Ref} className={cx('absolute', 'bottom-0', 'left-0', 'w-full', 'h-px')}/>
    </div>
  );

};

export default GoalDoubleColumnSection;