import React, { useRef } from 'react';
import cx from 'classnames';
import { useIsScreenSm } from '@/providers/ScreenSizeProvider';
import useWaveAnimation from '@/views/home/pages/HomePage/components/WaveAnimation/hooks/useWaveAnimation';

import imgAnimMiddle from '@/assets/images/page-home/background-anim-middle.png';
import imgAnimTop from '@/assets/images/page-home/background-anim-top.png';
import imgMobileAnimTop from '@/assets/images/page-home/mobile-background-anim-top.png';
import imgAnimBottom from '@/assets/images/page-home/background-anim-bottom.png';
import imgMobileAnimBottom from '@/assets/images/page-home/mobile-background-anim-bottom.png';

const WaveAnimation = (props = {}) => {
  const isScreenSm = useIsScreenSm();

  const triggerRef = useRef(null);
  const animTopRef = useRef(null);
  const animBottomRef = useRef(null);

  useWaveAnimation(triggerRef, animTopRef, animBottomRef);

  return (
    <>
      <div ref={triggerRef} className={cx('z-50', 'absolute', 'left-0', 'top-0', 'w-full', 'h-px')} />
      <div
        className={cx(
          'absolute', 'left-0', 'top-[120px]',
          'aspect-wave-anim', 'sm:aspect-auto',
          'w-full', 'sm:h-full',
          'bg-no-repeat', 'bg-expanded', 'bg-top'
        )}
        style={{ backgroundImage: `url(${imgAnimMiddle})` }}
      />
      <div
        ref={animTopRef}
        className={cx(
          'absolute', 'left-0', 'top-[120px]',
          'aspect-wave-anim', 'sm:aspect-auto',
          'w-full', 'sm:h-full',
          'bg-no-repeat', 'bg-expanded', 'bg-top'
        )}
        style={{ backgroundImage: `url(${isScreenSm ? imgMobileAnimTop : imgAnimTop})` }}
      />
      <div
        ref={animBottomRef}
        className={cx(
          'absolute', 'left-0', 'top-[120px]',
          'aspect-wave-anim', 'sm:aspect-auto',
          'w-full', 'sm:h-full',
          'bg-no-repeat', 'bg-expanded', 'bg-top'
        )}
        style={{ backgroundImage: `url(${isScreenSm ? imgMobileAnimBottom : imgAnimBottom})` }}
      />
    </>
  );
};

export default WaveAnimation;
