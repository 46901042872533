import imgBanner01 from '@/assets/images/page-solution-approach-planning/grid-banner-01.png';


export default [
  {
    id: 1,
    type: 'link',
    theme: 'dark',
    title: 'familyWealth.title',
    description: 'familyWealth.description',
    url: '/solution/family-wealth',
  },
  {
    id: 2,
    type: 'link',
    theme: 'grey',
    title: 'realEstate.title',
    description: 'realEstate.description',
    url: '/solution/real-estate',
  },
  {
    id: 3,
    type: 'link',
    theme: 'light',
    title: 'financialPlanning.title',
    description: 'financialPlanning.description',
    url: '/solution/financial-planning',
  },
  {
    id: 4,
    type: 'banner',
    banner: imgBanner01,
  },
  {
    id: 5,
    type: 'link',
    theme: 'grey',
    title: 'insurance.title',
    description: 'insurance.description',
    url: '/solution/insurance',
  },
  {
    id: 6,
    type: 'link',
    theme: 'purple',
    title: 'investmentBanking.title',
    description: 'investmentBanking.description',
    url: '/solution/investment-banking',
  },
];