import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useLocale from '@/hooks/useLocale';
import FilterList from "@/views/common/components/FilterList";
import ArticleEntry, { DIRECTION_HORIZONTAL, DIRECTION_HORIZONTAL_REVERSE } from '@/views/common/components/ArticleEntry';
import HighlightArticleEntry from '@/views/common/components/HighlightArticleEntry';
import { loadingTags, loadingImgHighlight, loadingNoImg, loadingImgRight, loadingImgLeft, errorList } from '@/assets/images/page-insight';
import { get, isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { useIsScreenSm } from '@/providers/ScreenSizeProvider';

import s from './styles.module.scss';

const InsightListSection = (props = {}) => {
  const { categories, insightsList = [], isLoading, error } = props;

  const navigate = useNavigate();
  const isScreenSm = useIsScreenSm(); // Check if screen size is small
  const [filteredData, setFilteredData] = useState(insightsList);
  const [displayCount, setDisplayCount] = useState(7); // Initially show the first 7 items

  const { t } = useLocale('page-insight-section-list');

  useEffect(() => {
    if (error) {
      return;
    }
    if (!isEqual(filteredData, insightsList)) {
      setFilteredData(insightsList);
    }
  }, [insightsList, error]);

  const handleSeeMore = () => {
    setDisplayCount(prevCount => Math.min(prevCount + 4, filteredData.length));
  };

  const renderArticleEntries = (startIndex, endIndex) => {
    const entries = [];
    for (let i = startIndex; i < endIndex; i += 4) {
      entries.push(
        <React.Fragment key={i}>
          <div className={cx('relative', 'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch', 'space-y-4', 'sm:space-y-0', 'sm:space-x-8')}>
            <div className={cx(s['one-third'])}>
              {isLoading ? <img src={loadingNoImg} alt="Loading..." /> : get(filteredData, `${i}`) && <ArticleEntry coverVisible={isScreenSm || false} article={get(filteredData, `${i}`)} />}
            </div>
            <div className={cx(s['two-thirds'])}>
              {isLoading ? <img src={loadingImgRight} alt="Loading..." /> : get(filteredData, `${i + 1}`) && <ArticleEntry direction={DIRECTION_HORIZONTAL_REVERSE} article={get(filteredData, `${i + 1}`)} />}
            </div>
          </div>
          <div className={cx('relative', 'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch', 'space-y-4', 'sm:space-y-0', 'sm:space-x-8')}>
            <div className={cx(s['two-thirds'])}>
              {isLoading ? <img src={loadingImgLeft} alt="Loading..." /> : get(filteredData, `${i + 2}`) && <ArticleEntry direction={DIRECTION_HORIZONTAL} article={get(filteredData, `${i + 2}`)} />}
            </div>
            <div className={cx(s['one-third'])}>
              {isLoading ? <img src={loadingNoImg} alt="Loading..." /> : get(filteredData, `${i + 3}`) && <ArticleEntry coverVisible={isScreenSm || false} article={get(filteredData, `${i + 3}`)} />}
            </div>
          </div>
        </React.Fragment>
      );
    }
    return entries;
  };

  if (error) {
    return (
      <section className={cx('relative', 'pt-4', 'pb-6', s.container)}>
        <div className={cx('content', 'flex', 'flex-col', 'justify-start', 'items-stretch', s.content)}>
          <img className={cx('h-[90px]')} src={errorList} alt=''/>
          <span className={cx('text-[#B3ACC4]', 'font-medium', 'whitespace-pre-line', 'text-center', 'mt-7')}>{t('error')}</span>
          <a href="." onClick={(e) => {
            e.preventDefault(); // Prevent the default anchor behavior
            navigate(0); // Refresh the page
          }} className={cx(
            'mt-2', 'sm:mt-8', 'btn', 'btn-outlined', 'btn-outlined-light', 'w-[160px]', 'py-4', 'self-center'
          )}>
            <span>{t('tryAgain')}</span>
          </a>
        </div>
      </section>
    );
  }

  return (
    <section className={cx('relative', 'pt-4', 'pb-6', s.container)}>
      <div className={cx('content', 'flex', 'flex-col', 'justify-start', 'items-stretch', s.content)}>
        <div className={cx('mb-12', 'flex', 'flex-col', 'justify-start', 'items-stretch',)}>
          {isLoading ? <img src={loadingTags} alt="Loading..." /> : <FilterList dataList={insightsList} setFilteredData={setFilteredData} title={t('filter')} allTagName="ALL Insights" categories={categories} />}
        </div>
        <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch', 'space-y-8')}>
          {
            filteredData.length > 0 || isLoading ?
            <>
            <div className={cx('relative', 'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch', 'space-y-4', 'sm:space-y-0', 'sm:space-x-8')}>
              <div className={cx(s['two-thirds'], 'sm:aspect-square')}>
                {isLoading ? <img src={loadingImgHighlight} alt="Loading..." /> : get(filteredData, '0') && <HighlightArticleEntry key={0} article={get(filteredData, '0')} />}
              </div>
              <div className={cx(s['one-third'], 'flex-row', 'justify-start', 'items-stretch', 'space-y-8')}>
                {isLoading ?
                  <>
                    <img src={loadingNoImg} alt="Loading..." />
                    <img src={loadingNoImg} alt="Loading..." />
                  </> :
                  <>
                    {get(filteredData, '1') && <div className={cx('flex-1')}>
                      <ArticleEntry coverVisible={isScreenSm || false} article={get(filteredData, '1')} />
                    </div>}
                    {get(filteredData, '2') && <div className={cx('flex-1')}>
                      <ArticleEntry coverVisible={isScreenSm || false} article={get(filteredData, '2')} />
                    </div>}
                  </>
                }
              </div>
            </div>
            {renderArticleEntries(3, displayCount)}
            </>
          :
          <section className={cx('relative', 'pt-4', 'pb-6', s.container)}>
            <div className={cx('content', 'flex', 'flex-col', 'justify-start', 'items-stretch', s.content)}>
              <img className={cx('h-[90px]')} src={errorList} alt=''/>
              <span className={cx('text-[#B3ACC4]', 'font-medium', 'whitespace-pre-line', 'text-center', 'mt-7')}>{t('noContent')}</span>
            </div>
          </section>
          }
        </div>
        {displayCount < filteredData.length && !isLoading &&
          <a onClick={handleSeeMore} className={cx(
            'mt-4', 'sm:mt-8', 'btn', 'btn-outlined', 'btn-outlined-light', 'btn-md', 'btn-next', 'cursor-pointer'
          )}>
            <span>{t('seeMore')}</span>
          </a>
        }
      </div>
    </section>
  );
};

InsightListSection.propTypes = {
  categories: PropTypes.array,
  insightsList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default InsightListSection;
