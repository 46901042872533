import React from 'react';
import cx from 'classnames';

import SolutionApproachContentSection from '@/views/solution/sections/SolutionApproachContentSection';
import useLocale from '@/hooks/useLocale';
import AdvisoryMenu, {PAGE_CIO_OFFICE} from '@/views/solution/svgs/AdvisoryMenu';
import imgFigure02 from '@/assets/images/page-solution-approach-cio-office/figure-02.png';
import imgFigure03 from '@/assets/images/page-solution-approach-cio-office/figure-03.png';
import imgFigure04 from '@/assets/images/page-solution-approach-cio-office/figure-04.png';


const SolutionApproachCioOfficeContentSection = (props = {}) => {

  const {namespace} = props;

  const {t} = useLocale(namespace);

  return (
    <SolutionApproachContentSection>
      <div className={cx('flex', 'flex-col', 'justify-start', 'item-stretch')}>
        <div className={cx(
          'space-y-16', 'sm:space-y-0', 'space-x-0', 'sm:space-x-16',
          'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch', 'sm:items-center'
        )}>
          <div className={cx('relative', 'flex-1')}>
            <AdvisoryMenu page={PAGE_CIO_OFFICE}/>
          </div>
          <div
            className={cx('space-y-4', 'flex-1', 'flex-shrink-0', 'flex', 'flex-col', 'justify-start', 'items-start')}>
            <h2 className={cx('block', 'text-legacy-purple')}>
              {t('cioOffice.title')}
            </h2>
            <p className={cx('block', 'text-legacy-purple', 'text-base')}>
              {t('cioOffice.description')}
            </p>
          </div>
        </div>
        <hr className={cx('h-[2px]', 'border-none', 'bg-primary-100', 'my-9')}/>
        <div className={cx('space-y-4', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
          <h2 className={cx('text-legacy-purple')}>{t('strategicallyTailored.title')}</h2>
          <p className={cx('block', 'text-legacy-purple')}>
            {t('strategicallyTailored.paragraph01')}
          </p>
          <img className={cx('self-center', 'max-w-[692px]', 'w-full')} alt="Figure" src={imgFigure02}/>
          <p className={cx('block', 'text-legacy-purple')}>
            {t('strategicallyTailored.paragraph02')}
          </p>
          <img className={cx('self-center', 'w-full')} alt="Figure" src={imgFigure04}/>
        </div>
      </div>
    </SolutionApproachContentSection>
  );

};

export default SolutionApproachCioOfficeContentSection;