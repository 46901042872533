import React, {useMemo} from 'react';
import cx from 'classnames';

import benefits from './data/benefits';
import investment from './data/investment';
import relatedSolutions from './data/related-solutions';
import useInvestingBreadCrumbs from '@/views/solution/hooks/useInvestingBreadCrumbs';
import StandardLayout from "@/views/common/layouts/StandardLayout";
import BreadCrumbs from '@/views/common/components/BreadCrumbs';
import ContactSection from "@/views/common/section/ContactSection";
import SolutionRelatedSolutionsSection from '@/views/solution/sections/SolutionRelatedSolutionsSection';
import SolutionInvestmentSection from '@/views/solution/sections/SolutionInvestmentSection';
import SolutionBenefitsSection from '@/views/solution/sections/SolutionBenefitsSection';
import SolutionHeadSection from '@/views/solution/sections/SolutionHeadSection';
import useLocale from '@/hooks/useLocale';


const SolutionStructuredProductPage = (props = {}) => {

  const namespace = useMemo(() => 'page-solution-structured-product', []);

  const localeComponents = useLocale(namespace);
  const {paths} = useInvestingBreadCrumbs(localeComponents);
  const shouldShowDownloadButton = false;
  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <BreadCrumbs paths={paths}/>
        <SolutionHeadSection namespace={namespace} title="head.title" excerpt="head.excerpt"/>
        <SolutionBenefitsSection namespace={namespace} benefits={benefits}/>
        <SolutionInvestmentSection namespace={namespace} investment={investment} shouldShowDownloadButton={shouldShowDownloadButton}/>
        {/* <SolutionRelatedSolutionsSection namespace={namespace} relatedSolutions={relatedSolutions}/> */}
        <ContactSection/>
      </div>
    </StandardLayout>
  );

};

export default SolutionStructuredProductPage;