import React, {useCallback} from 'react';
import cx from 'classnames';

import useLocale from '@/hooks/useLocale';

import s from "./styles.module.scss";


const AssetCategoryMenu = (props = {}) => {

  const {items = [], selectedIndex = 0, onChangeIndex} = props;

  const {locale} = useLocale('');

  const onClickItem = useCallback((event, index) => {
    event.stopPropagation();
    event.preventDefault();
    if (!onChangeIndex) return;
    onChangeIndex(index);
  }, [onChangeIndex]);

  return (
    <ul className={cx(
      'max-w-[256px]', 'sm:max-w-none', 'w-full',
      'mt-[60px]', 'mb-5', 'sm:m-0',
      'mx-auto', 'sm:mx-none',
      'sm:aspect-asset-card', 'space-y-2',
      'flex', 'flex-col', 'justify-center', 'items-stretch',
      s.container
    )}>
      {
        items.map((item, index) => {
          const {id, title} = item;
          const localisedTitle = title[locale];
          return (
            <li key={id} className={cx('flex', 'flex-col', 'justify-start', 'align-stretch')}>
              <a href="#" className={cx(
                'uppercase',
                {'text-primary-200': selectedIndex !== index, 'text-primary-900': selectedIndex === index},
                'transition-all', 'ease-in-out', 'duration-200',
              )} onClick={event => onClickItem(event, index)}>
                <b>{localisedTitle}</b>
              </a>
              <hr className={cx(
                'w-1/2', 'border-0',
                {'mt-0': selectedIndex !== index, 'mt-2': selectedIndex === index},
                {'h-0': selectedIndex !== index, 'h-0.5': selectedIndex === index},
                'transition-all', 'ease-in-out', 'duration-200',
                s.selected
              )}/>
            </li>
          );
        })
      }
    </ul>
  );

};

export default AssetCategoryMenu;