import axios, { AxiosInstance } from 'axios';

const Api = (endpoint) => {
	const baseURL = endpoint;
	const headers = {'Content-Type':'application/json',
  'Access-Control-Allow-Origin':'*',
  'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS'};

	const api = axios.create({
		baseURL,
		headers,
	});

	api.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			
			return Promise.reject(error);
		}
	);
	return api;
};

export default Api;
