import {useEffect} from 'react';
import gsap from "gsap";


const useWaveAnimation = (triggerRef, animTopRef, animBottomRef) => {

  useEffect(() => {
    const ctx = gsap.context(() => {
      const duration = 1.4;
      gsap.fromTo(
        animTopRef.current,
        {
          translateY: '0',
        },
        {
          translateY: '-7.5%',
          duration,
          scrollTrigger: {
            trigger: triggerRef.current,
            start: "top center",
            end: "bottom center",
          }
        },
      );
      gsap.fromTo(
        animBottomRef.current,
        {
          translateY: '0',
        },
        {
          translateY: '7.5%',
          duration,
          scrollTrigger: {
            trigger: triggerRef.current,
            start: "top center",
            end: "bottom center",
          }
        },
      );
    });

    return () => ctx.revert();
  }, [triggerRef, animTopRef, animBottomRef]);

  return {};

};

export default useWaveAnimation;