import React, {useMemo} from 'react';
import cx from 'classnames';

import benefits from './data/benefits';
import investment from './data/investment';
import relatedSolutions from './data/related-solutions';
import useLocale from '@/hooks/useLocale';
import useLendingBreadCrumbs from '@/views/solution/hooks/useLendingBreadCrumbs';
import StandardLayout from "@/views/common/layouts/StandardLayout";
import BreadCrumbs from '@/views/common/components/BreadCrumbs';
import ContactSection from "@/views/common/section/ContactSection";
import SolutionHeadSection from '@/views/solution/sections/SolutionHeadSection';
import SolutionBenefitsSection from '@/views/solution/sections/SolutionBenefitsSection';
import SolutionInvestmentSection from '@/views/solution/sections/SolutionInvestmentSection';
import SolutionRelatedSolutionsSection from '@/views/solution/sections/SolutionRelatedSolutionsSection';
import file from '@/assets/files/our-solutions/Lombard_Loan_Brochure_20240109.pdf';

const SolutionLombardLoanPage = (props = {}) => {

  const namespace = useMemo(() => 'page-solution-lombard-loan', []);

  const localeComponents = useLocale(namespace);
  const {paths} = useLendingBreadCrumbs(localeComponents);
  const shouldShowDownloadButton = true;
  const fileUrl = file;
  const fileName =  'Lombard_Loan_Brochure.pdf';
  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <BreadCrumbs paths={paths}/>
        <SolutionHeadSection namespace={namespace} title="head.title" excerpt="head.excerpt"/>
        <SolutionBenefitsSection namespace={namespace} benefits={benefits}/>
        <SolutionInvestmentSection namespace={namespace} investment={investment} shouldShowDownloadButton={shouldShowDownloadButton} file={fileUrl} fileName={fileName}/>
        {/* <SolutionRelatedSolutionsSection namespace={namespace} relatedSolutions={relatedSolutions}/> */}
        <ContactSection/>
      </div>
    </StandardLayout>
  );

};

export default SolutionLombardLoanPage;