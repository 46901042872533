import React, {useMemo, useState} from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';

import {splitLines} from "@/utils/html-utils";
import { locals } from '@/utils/locales';
import useLocale from '@/hooks/useLocale';
import Tag from "../Tag";

import loadingProfile from '@/assets/images/page-why-us-expertise/Profile.svg';
import s from './styles.module.scss';


const ExpertSummaryEntry = (props = {}) => {

  const {expert = {}, isLoading} = props;
  const {id, nameEn, nameTh, shortName, positionEn, positionTh, profileImage, experienceEn, experienceTh, tags} = expert;
  const {locale} = useLocale('');
  const [isImgFail, setIsImgFail] = useState(false);

  const inputBoxes = [];
  if(tags){
    for (let i of tags) {
      inputBoxes.push(<Tag title={i.name} />);
    }
  }

  const url = useMemo(() => `/${locale}/experts/${id}`, [locale]);

  const onImgError = () => {
    if(profileImage && profileImage != ""){
      setIsImgFail(true)
    }
  }

  return (
    <Link to={url}>
      {
        isLoading ? <img src={loadingProfile} /> : (
      <div className={cx(
        'w-full', 'max-w-full', 'h-full', 'relative',
        'transition-all', 'duration-300', 'ease-in-out', 'bg-white',
        'border', 'border-primary-50', 'sm:hover:border-primary-100',
        'flex', 'flex-col', 'justify-start', 'items-stretch',
        s.container
      )}>
        {
          profileImage && profileImage != "" && !isImgFail?
             <div className={cx('aspect-square', 'bg-primary-50', 'bg-cover', 'bg-center', 'bg-no-repeat')}>
              <img className={cx('flex','h-full','w-full')} src={profileImage} onError={onImgError}></img>
             </div>
          : 
          <div className={cx('aspect-square', 'bg-primary-50', 'bg-cover', 'bg-center', 'bg-no-repeat', 'content-center')}>
              <p className={cx('text-center', 'font-serif', 'font-bold', 'text-8xl', 'text-secondary-100')}>{shortName}</p>
          </div>
        }
        <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch', 'p-4')}>
          <div className={cx('flex', 'flex-wrap', 'justify-center', 'gap-1.5', 'py-2', 'min-h-[35px]')}>
            {inputBoxes}
          </div>
          <h4 className={cx('text-center', 'text-legacy-purple', 'font-normal', 'break-words')}>
            {locale === locals.th ? nameTh : nameEn}
          </h4>
          <div className={cx('mt-1', 'text-center', 'text-light', 'text-sm', 'text-primary-400')}>
            {locale === locals.th ? positionTh : positionEn}
          </div>
        </div>
      </div>
      )}
    </Link>
  );

};

export default ExpertSummaryEntry;