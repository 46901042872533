export default {
  motivations: [],
  prospectuses: [
    {
      id: 1,
      title: 'prospectuses.prospectus01.title',
      description: 'prospectuses.prospectus01.description',
    },
    {
      id: 2,
      title: 'prospectuses.prospectus02.title',
      description: 'prospectuses.prospectus02.description',
    },
  ],
  investors: [
    {
      id: 1,
      title: 'investors.investor01',
    },
    {
      id: 2,
      title: 'investors.investor02',
    },
  ]
};