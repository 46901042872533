export default [
  {
    id: 1,
    title: 'Announcement',
    url: '/legal/announcement',
  },
  {
    id: 2,
    title: 'Legal Info',
    url: '/legal/info',
  },
  // {
  //   id: 3,
  //   title: 'Privacy Statement',
  //   url: '/legal/privacy-statement',
  // },
  {
    id: 4,
    title: 'Privacy Notice',
    url: 'https://kkpfg.com/{{locale}}/dataprotection',
  },
]