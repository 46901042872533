import React from "react";
import cx from 'classnames';

import {splitLines} from '@/utils/html-utils';
import useLocale from '@/hooks/useLocale';

import s from './styles.module.scss';


const PrivilegeCard = (props = {}) => {

  const {privilege = {}} = props;
  const {title, cover, items,url} = privilege;

  const {locale} = useLocale('');

  return (
    <div className={cx('relative', 'w-full', 'space-y-8', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
    <a href={url} target="_blank" className={cx('block')}>
      <div className={cx(
        'aspect-square', 'bg-cover', 'bg-center', 'bg-no-repeat', 'bg-primary-100',
        'p-6', 'box-border'
      )} style={{backgroundImage: `url(${cover})`}}>
        <h2 className={cx('text-sm-h2', 'text-white')}>{splitLines(title[locale])}</h2>
      </div>
    </a>
    <ul className={cx(
      {'list-disc': items.length > 1},
      {'list-outside': items.length > 1},
      {'pl-6': items.length > 1},
      'flex', 'flex-col', 'justify-start', 'items-stretch'
    )}>
      {
        items.map((item, index) => {
          const {id, description} = item;
          return (
            <li key={id} className="text-primary-900 flex items-center">
              {index !== 0 && <span className="mr-2">&#8226;</span>}
              <span>{description[locale]}</span>
            </li>
          )
        })
      }
    </ul>

  </div>
  

  );

};

export default PrivilegeCard;