import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import cx from 'classnames';
import {nanoid} from 'nanoid';
import {useLocation, useNavigate} from 'react-router-dom';
import gsap from 'gsap';

import {chopArray} from "@/utils/array-utils";
import {useIsScreenSm} from '@/providers/ScreenSizeProvider';
import useLocale from '@/hooks/useLocale';
import PrivilegeCard from "./components/PrivilegeCard";
import ChannelCard from "./components/ChannelCard";

import s from './styles.module.scss';


const WhyUsBenefitsPrivilegeSection = (props = {}) => {

  const {privilege = {}} = props;
  const {
    id,
    banner = {},
    type,
    url,
    summary,
    description,
    items = [],
    channels = []
  } = privilege;

  const {t, locale} = useLocale('page-why-us-benefits-section-privilege');

  const isScreenSm = useIsScreenSm();

  const headerRef = useRef(undefined);
  const contentRef = useRef(undefined);
  const channelsRef = useRef(undefined);

  const tabComponentsRef = useMemo(() => {
    return [headerRef, contentRef, channelsRef];
  }, [headerRef, contentRef, channelsRef]);

  const {state} = useLocation() || {};
  const {fadeIn = false} = state || {};
  const navigate = useNavigate();

  const cardsPerRow = useMemo(() => isScreenSm ? 1 : 3, [isScreenSm]);

  useEffect(() => {
    if (!fadeIn) return;
    const ctx = gsap.context(() => {
      for (const componentRef of tabComponentsRef) {
        gsap.fromTo(
          componentRef.current,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.7,
          },
        );
      }
    });
    return () => ctx.revert();
  }, [tabComponentsRef, fadeIn]);

  const fadeOut = useCallback(async () => {
    return new Promise(resolve => {
      let count = 0;
      for (const componentRef of tabComponentsRef) {
        (index => {
          gsap.fromTo(
            componentRef.current,
            {
              opacity: 1,
            },
            {
              opacity: 0,
              duration: 0.2,
              onComplete: () => {
                if (index !== 0) return;
                resolve();
              }
            },
          );
        })(count);
        count++;
      }
    })
  }, [tabComponentsRef]);

  const onClickBack = useCallback(async event => {
    event.preventDefault();
    event.stopPropagation();
    await fadeOut();
    navigate(`/${locale}/why-us/lifestyle-privilege`, {state: {noScroll: true, fadeIn: true}});
  }, [fadeOut, locale, navigate]);

  const onClickNext = useCallback(async event => {
    event.preventDefault();
    event.stopPropagation();
    await fadeOut();
    navigate(`/${locale}/why-us/privilege-club`, {state: {noScroll: true, fadeIn: true}});
  }, [fadeOut, locale, navigate]);

  return (
    <>
      <section ref={headerRef} className={cx('relative')}>
        <div className={cx('content')}>
          <p className={cx('text-xl', 'text-primary-900', 'py-[84px]')}>
            {summary[locale]}
          </p>
        </div>
      </section>
      <section className={cx('relative', 'pb-16', s.container)}>
        {/* <div className={cx(
          'relative', 'w-full', 'aspect-square', 'sm:aspect-short-banner',
          'sm:max-h-[360px]', 'bg-center', 'bg-cover', 'bg-no-repeat',
          'flex', 'flex-row', 'justify-start', 'items-stretch',
        )} style={{backgroundImage: `url(${isScreenSm ? banner.mobile : banner.desktop})`}}>
          <a href="#" onClick={onClickBack}
             className={cx({'flex-[3]': id === 1}, {'flex-[2]': id === 2})}/>
          <a href="#" onClick={onClickNext}
             className={cx({'flex-[2]': id === 1}, {'flex-[3]': id === 2})}/>
        </div> */}
        <div ref={contentRef} className={cx('content', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
          {/* <div className={cx('pt-16')}>
            <p className={cx('text-legacy-purple', 'text-xl', 'text-center')}>
              {description[locale]}
            </p>
          </div> */}
          <hr className={cx('my-12', 'border-0', 'h-px')}/>
          <div className={cx('space-y-6', 'sm:space-y-12', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
            {
              chopArray(items, cardsPerRow).map(items => {
                return (
                  <ul className={cx('sm:space-x-8', 'flex', 'flex-row', 'justify-start', 'items-start')}>
                    {
                      items.map(item => {
                        if (!item) {
                          return <li key={nanoid()} className={cx('flex-1', 'flex-shrink-0')}/>
                        }
                        const {id} = item;
                        return (
                          <li key={id} className={cx('flex-1', 'flex-shrink-0')}>
                            <PrivilegeCard privilege={item}/>
                          </li>
                        );
                      })
                    }
                  </ul>
                );
              })
            }
            {/* <a target="_blank" href={url} className={cx(
              'flex', 'flex-row', 'justify-center', 'items-center',
              'btn', 'btn-outlined', 'btn-outlined-contrast', 'btn-long', 'btn-md',
            )}>
              <span className={cx('tracking-wide')}>{t(`${type}.callToAction`)}</span>
            </a> */}
          </div>
        </div>
      </section>
      <section ref={channelsRef} className={cx('relative', 'py-12', 'bg-white', s.channels)}>
        <div className={cx('content', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
          <h4 className={cx('py-6', 'text-center', 'text-legacy-purple')}>
            {t(`${type}.channelTitle`)}
          </h4>
          <hr className={cx('border-0', 'mg-4', 'h-px')}/>
          <ul className={cx('sm:space-x-8', 'flex', 'flex-col', 'sm:flex-row', 'justify-center', 'items-stretch')}>
            {
              channels.map(channel => {
                const {id} = channel;
                return (
                  <li key={id} className={cx('flex-1', 'flex-shrink-0', s.item)}>
                    <ChannelCard channel={channel}/>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </section>
    </>
  );

};

export default WhyUsBenefitsPrivilegeSection;