import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import cx from 'classnames';
import gsap from 'gsap';
import {Link, useLocation, useNavigate} from 'react-router-dom';

import solutionMenus from '@/views/common/layouts/StandardLayout/data/solution-menus';
import {useIsScreenSm, useScreenSize} from '@/providers/ScreenSizeProvider';
import useLocale from '@/hooks/useLocale';

import s from './styles.module.scss';

import imgExpand from '@/assets/images/layout-standard/chevron-down.svg';


const MobileMenu = (props = {}) => {

  const isScreenSm = useIsScreenSm();

  const location = useLocation();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [animationVisible, setAnimationVisible] = useState(false);
  const [currentSolutionIndex, setCurrentSolutionIndex] = useState(undefined);

  const dialogRef = useRef(undefined);

  const {locale} = useLocale('');

  const {hash} = useLocation();

  useEffect(() => {
    const duration = 0.3;

    document.documentElement.style.overflow = visible ? 'hidden' : 'auto';

    if (visible) {
      setCurrentSolutionIndex(undefined);
      gsap.fromTo(dialogRef.current, {opacity: 0}, {opacity: 1, duration, ease: 'circ.out' });
      setAnimationVisible(true);
    } else {
      gsap.fromTo(
        dialogRef.current,
        {opacity: 1},
        {
          opacity: 0,
          duration,
          ease: 'circ.out',
          onComplete: () => {
            setAnimationVisible(false);
          },
        },
      );
    }
  }, [visible]);

  useEffect(() => {
    setVisible(hash === '#menu');
  }, [hash]);

  useEffect(() => {
    if (!visible || isScreenSm) return;
    const {pathname: path} = location;
    navigate(path);
  }, [isScreenSm, visible, location, navigate]);

  const onClickSolution = useCallback((event, index) => {
    event.preventDefault();
    event.stopPropagation();
    if (index === currentSolutionIndex) {
      setCurrentSolutionIndex(undefined);
      return;
    }
    setCurrentSolutionIndex(index);
  }, [currentSolutionIndex]);

  return (
    <div ref={dialogRef} id="mobilemenu" className={cx(
      'fixed', 'left-0', 'top-0', 'w-screen', 'h-screen', 'z-[100]', 'bg-white',
      'box-border', 'py-[96px]', 'px-4', 'pb-4', 'overflow-y-auto',
      {'hidden': !animationVisible, 'block': animationVisible},
      s.container, {[s.visible]: visible}
    )}>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <Link to="/" className={cx('py-4')}>
          <h2 className={cx('text-legacy-purple', 'uppercase')}>Home</h2>
        </Link>
        <hr className={cx('border-0', 'bg-grey-0.7', 'h-px')}/>
        <Link to={`/${locale}/why-us/expertise`} className={cx('py-4')}>
          <h2 className={cx('text-legacy-purple', 'uppercase')}>Why us</h2>
        </Link>
        <hr className={cx('border-0', 'bg-grey-0.7', 'h-px')}/>
        <a href="#" className={cx('py-4')} onClick={event => event.preventDefault()}>
          <h2 className={cx('text-legacy-purple', 'uppercase')}>Our Solutions</h2>
        </a>
        {
          solutionMenus.map((menu, index) => {
            const {id, title, url, items} = menu;
            return (
              <div key={id} className={cx(
                'flex', 'flex-col', 'justify-start', 'items-stretch', 'overflow-hidden',
                'transition-all', 'duration-700', 'ease-in-out',
                {'max-h-[44px]': index !== currentSolutionIndex, 'max-h-[300px]': index === currentSolutionIndex},
              )}>
                <hr className={cx('flex-shrink-0', 'border-0', 'bg-grey-0.7', 'h-px')}/>
                <a href="#" className={cx(
                  'block', 'text-sm', 'font-serif', 'text-legacy-purple',
                  'flex-shrink-0', 'flex', 'flex-row', 'justify-between', 'items-center',
                )} onClick={event => onClickSolution(event, index)}>
                  <Link to={`/${locale}${url}`} className={cx('block', 'py-3', 'font-bold')}>{title[locale]}</Link>
                  <i className={cx(
                    'text-2xl', 'icon-chevron-down',
                    'transform', {'rotate-180': index === currentSolutionIndex},
                    'transition-transform', 'duration-300', 'ease-in-out',
                  )}/>
                </a>
                <ul className={cx('flex-shrink-0', 'pb-6', 'space-y-2', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
                  {
                    items.map(item => {
                      const {id, title, url} = item;
                      return (
                        <li key={id}>
                          <Link to={`/${locale}${url}`} className={cx('text-sm', 'text-primary-400', 'leading-[1.2]')}>
                            {title[locale]}
                          </Link>
                        </li>
                      );
                    })
                  }
                </ul>
              </div>
            )
          })
        }
        <hr className={cx('border-0', 'bg-grey-0.7', 'h-px')}/>
        <Link to={`/${locale}/insight/overview`} className={cx('py-4')}>
          <h2 className={cx('text-legacy-purple', 'uppercase')}>Insights</h2>
        </Link>
        <hr className={cx('border-0', 'bg-grey-0.7', 'h-px')}/>
      </div>
      <div className={cx('h-[100px]')}/>
    </div>
  )

};

export default MobileMenu;