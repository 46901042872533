import React from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';

import useLocale from "../../../../hooks/useLocale";

import s from './styles.module.scss';


const ContactSection = (props = {}) => {

  const {t} = useLocale('section-contact');

  return (
    <section className={cx(
      'relative', 'w-full', 'sm:max-h-[480px]',
      'aspect-contact-mobile', 'sm:aspect-title-banner',
      s.container
    )}>
      <div className={cx(
        'absolute', 'left-0', 'top-0', 'w-full', 'h-full', 'content',
        'flex', 'flex-col', 'justify-center', 'items-center'
      )}>
        <h1 className={cx('font-serif', 'font-bold', 'text-white', 'text-center', 'sm:text-left')}>
          Ready to start working toward your goals?
        </h1>
        <p className={cx(
          'mt-4', 'text-center', 'sm:text-left',
          'text-white', 'text-base', 'sm:text-2xl', 'font-light'
        )}>
          {t('excerpt')}
        </p>
        <Link to={'#contact'} state={{noScroll: true}} className={cx(
          'mt-6', 'btn', 'btn-bright', 'btn-bright-light', 'btn-xl',
          'w-full', 'sm:max-w-[556px]',
        )}>
          <span className={cx('font-medium')}>Contact Us</span>
        </Link>
      </div>
    </section>
  );

};

export default ContactSection;