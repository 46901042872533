import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import tabs from '../../data/tabs';
import assets from '../../data/assets';
import timelineGroups from '../../data/timeline-groups';
import StandardLayout from "@/views/common/layouts/StandardLayout";
import LinearWhiteBackground from "../../../common/svgs/backgrounds/LinearWhiteBackground";
import WhyUsExpertiseHeadSection from "./sections/WhyUsExpertiseHeadSection";
import WhyUsExpertiseAssetsSection from "./sections/WhyUsExpertiseAssetsSection";
import WhyUsExpertiseExpertsSection from "./sections/WhyUsExpertiseExpertsSection";
import WhyUsExpertiseTimelineSection from "./sections/WhyUsExpertiseTimelineSection";
import ContactSection from "../../../common/section/ContactSection";
import { getExperts } from "@/apis/experts";

import s from './styles.module.scss';
import { get } from 'lodash';


const WhyUsExpertisePage = (props = {}) => {
  
  const [ experts, setExperts] = useState([{},{},{},{},{},{}]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const expertLists = await getExperts({});
        setExperts(get(expertLists, 'data.experts'));
      } catch (error) {
        setIsError(true)
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <StandardLayout>
      <div className={cx(
        'relative',
        'flex', 'flex-col', 'justify-start', 'align-stretch', 'bg-linear-white',
        s.container
      )}>
        <LinearWhiteBackground/>
        <WhyUsExpertiseHeadSection tabs={tabs}/>
        <WhyUsExpertiseAssetsSection assets={assets}/>
        {
          experts.length > 0 ? <WhyUsExpertiseExpertsSection isLoading={isLoading} isError={isError} experts={experts}/> : null
        }
        
        <WhyUsExpertiseTimelineSection groups={timelineGroups}/>
        <ContactSection/>
      </div>
    </StandardLayout>
  );

};

export default WhyUsExpertisePage;