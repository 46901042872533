import React, {useEffect, useMemo, useRef} from 'react';
import cx from 'classnames';
import gsap from "gsap";

import useLocale from "@/hooks/useLocale";
import GoalEntry from "./components/GoalEntry";

import s from './styles.module.scss';
import {chopArray} from "@/utils/array-utils";
import {useIsScreenSm} from '@/providers/ScreenSizeProvider';
import useHomeGoalAnimation from '@/views/home/pages/HomePage/sections/HomeGoalSection/hooks/useHomeGoalAnimation';

const HomeGoalSection = (props = {}) => {

  const {goals = []} = props;

  const {t} = useLocale('page-home-section-goals');

  const titleRef = useRef(undefined);
  const triggerRef = useRef(undefined);

  const isScreenSm = useIsScreenSm();

  useHomeGoalAnimation(triggerRef, titleRef);

  const entriesPerRow = useMemo(() => !isScreenSm ? 3 : 1, [isScreenSm]);

  const localizedGoals = useMemo(() => {
    return goals.map(goal => {
      const {title, excerpt, ...rest} = goal;
      return {...rest, title: t(title), excerpt: t(excerpt)};
    });
  }, [goals, t]);

  return (
    <section className={cx('relative', 'pt-[104px]', 'sm:pt-[128px]', s.container)}>
      <div ref={triggerRef} className={cx('absolute', 'left-0', '-top-[280px]', 'w-full', 'h-[800px]')}/>
      <div className={cx('content', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <div className={cx(
          'flex', 'flex-col', 'justify-start', 'items-stretch', 'overflow-hidden', s.title
        )}>
          <h1 ref={titleRef} className={cx('text-center', 'text-primary-900', 'whitespace-nowrap')}>
            What's your goal?
          </h1>
        </div>
        <hr className={cx('self-center', 'mt-5', 'border-0', s['title-rule'])}/>
        <div className={cx(
          'space-y-6', 'sm:space-y-8',
          'pt-[80px]', 'pb-0', 'sm:pt-[228px]', 'sm:pb-[96px]',
          'flex', 'flex-col', 'justify-start', 'items-stretch', s.goals
        )}>
          {
            chopArray(localizedGoals, entriesPerRow).map((goals, index,array) => {
              return (
                <div key={index} className={cx(
                  'space-x-0', 'space-y-6', 'sm:space-x-8', 'sm:space-y-0',
                  'flex', 'flex-col', 'sm:flex-row', 'justify-stretch', 'sm:justify-center', 'items-start'
                )}>
                  {
                    goals.map(goal => {
                      if (!goal) return null;
                      const {id} = goal;
                      return <GoalEntry key={id} goal={goal}/>;
                    })
                  }
                  {index === array.length - 1 && <br/>}
                </div>
              );
            })
          }
        </div>
      </div>
    </section>
  )

};

export default HomeGoalSection;