import React from 'react';
import cx from 'classnames';

import {splitLines} from '@/utils/html-utils';
import useLocale from '@/hooks/useLocale';
import StandardLayout from '@/views/common/layouts/StandardLayout';


const Error404Page = (props = {}) => {

  const {t} = useLocale('error');

  return (
    <StandardLayout>
      <div className={cx('flex-1', 'min-h-[500px]', 'flex', 'flex-col', 'justify-center', 'items-center')}>
        <h2 className={cx('text-sm-h2', 'text-legacy-purple')}>{t('404.title')}</h2>
        <p className={cx('mt-3', 'text-legacy-purple', 'text-center')}>{splitLines(t('404.description'))}</p>
      </div>
    </StandardLayout>
  );

};

export default Error404Page;