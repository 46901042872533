import React, {useCallback, useEffect, useMemo, useState} from 'react';
import cx from 'classnames';
import {nanoid} from 'nanoid';

import {chopArray} from "@/utils/array-utils";
import ExpertSummaryEntry from "@/views/common/components/ExpertSummaryEntry";
import FilterList from "@/views/common/components/FilterList";

import {useIsScreenSm} from '@/providers/ScreenSizeProvider';

import s from './styles.module.scss';

import { loadingTags } from '@/assets/images/page-insight';
import imgNoContent from '@/assets/images/common/no-content.png';
import useLocale from '@/hooks/useLocale';


const ExpertListSection = (props = {}) => {

  const {experts = [], isLoading, categories} = props;
  const {t} = useLocale('page-experts-overview');
  const isScreenSm = useIsScreenSm();

  const [filteredData, setFilteredData] = useState(experts);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (experts.length > 0) {
      setFilteredData(experts);
    }
  }, [experts]);

  const itemsPerRow = useMemo(() => isScreenSm ? 2 : 4, [isScreenSm]);

  const itemsPerPage = useMemo(() => isScreenSm ? 8: 12, [isScreenSm]);

  const currentItems = useMemo(() => currentPage * itemsPerPage, [currentPage, itemsPerPage]);

  const pageExperts = useMemo(() => {
    return filteredData.slice(0, currentItems);
  }, [filteredData, currentItems]);

  const seeMoreVisible = useMemo(() => {
    return pageExperts.length !== filteredData.length;
  }, [pageExperts, filteredData]);


  const onClickMore = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage]);


  return (
    <section className={cx('pt-0', 'sm:pt-[44px]', 'pb-[96px]', 'relative', s.container)}>
      <div className={cx(
        'content', 'space-y-6',
        'flex', 'flex-col', 'justify-start', 'items-stretch'
      )}>
      <div className={cx('mb-12', 'flex', 'flex-col', 'justify-start', 'items-stretch',)}>
        {isLoading ? <img src={loadingTags} alt="Loading..." /> : <FilterList  dataList={experts} setFilteredData={setFilteredData} title={t('filter')} allTagName="ALL Experts" categories={categories} />}
      </div>
        <div className={cx(
          'space-y-4', 'sm:space-y-8',
          'flex', 'flex-col', 'justify-start', 'items-stretch'
        )}>
          {
            !isLoading && (experts.length == 0 || pageExperts.length == 0) ? (
            <div className={cx('content', 'flex', 'flex-col', 'justify-center')}>
            <img className={cx('h-[90px]', 'w-[90px]', 'self-center')} src={imgNoContent} alt=''/>
            <span className={cx('text-[#B3ACC4]', 'font-medium', 'whitespace-pre-line', 'text-center', 'mt-7')}>{t('noContent')}</span>
            </div>
            ) :
            chopArray(pageExperts, itemsPerRow).map((experts, index) => {
              return (
                <ul key={index} className={cx(
                  'relative', 'space-x-4', 'sm:space-x-8',
                  'flex', 'flex-row', 'justify-start', 'items-stretch'
                )}>
                  {
                    experts.map(expert => {
                      if (!expert) {
                        return <li key={nanoid()} className={cx('flex-1')}/>;
                      }
                      const {id} = expert;
                      return (
                        <li key={id} className={cx(
                          'relative', 'block',
                          'flex-shrink-0','w-[calc(50%-16px)]', 'sm:w-[256px]'
                        )}>
                          <ExpertSummaryEntry key={id} expert={expert} isLoading={isLoading}/>
                        </li>
                      );
                    })
                  }
                </ul>
              );
            })
          }
        </div>
        {
          !seeMoreVisible ? null : (
            <a onClick={onClickMore} className={cx(
              'mt-4', 'sm:mt-8', 'btn', 'btn-outlined', 'btn-outlined-light', 'btn-md', 'btn-next', 'cursor-pointer'
            )}>
              <span>{t('seeMore')}</span>
            </a>
          )
        }
      </div>
    </section>
  );

};

export default ExpertListSection;