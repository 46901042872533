export default [
  {
    id: 1,
    title: 'Expertise',
    url: '/why-us/expertise',
  },
  {
    id: 2,
    title: 'Platforms',
    url: '/why-us/digital',
  },
  {
    id: 3,
    title: 'Ultimate Benefits',
    url: '/why-us/lifestyle-privilege',
  },
  {
    id: 4,
    title: 'Our Services',
    url: '/why-us/services',
  },
]