import React from 'react';
import cx from 'classnames';


const LinearWhiteBackground = () => {

  return (
    <svg className={cx('absolute', 'left-0', 'top-0', 'w-full', 'h-full')}
         width="1280" height="1" viewBox="0 0 1280 1" fill="none" preserveAspectRatio="none"
         xmlns="http://www.w3.org/2000/svg">
      <rect width="1280" height="5650" fill="url(#paint0_linear_109_8398)"/>
      <defs>
        <linearGradient id="paint0_linear_109_8398" x1="565" y1="-3.20965" x2="2096.82" y2="232.521"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#EBEAF0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
      </defs>
    </svg>
  );

};

export default LinearWhiteBackground;