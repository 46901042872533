export default [
  {
    id: 1,
    title: 'wealthAccumulation.title',
    excerpt: 'wealthAccumulation.excerpt',
    url: '/goal/wealth-accumulation'
  },
  {
    id: 2,
    title: 'familyWealthAdvisory.title',
    excerpt: 'familyWealthAdvisory.excerpt',
    url: '/goal/family-wealth-advisory'
  },
  {
    id: 3,
    title: 'retirementPlanning.title',
    excerpt: 'retirementPlanning.excerpt',
    url: '/goal/retirement-planning'
  },
  {
    id: 4,
    title: 'businessAdvisory.title',
    excerpt: 'businessAdvisory.excerpt',
    url: '/goal/business-advisory'
  },
  {
    id: 5,
    title: 'premiumLifeStyle.title',
    excerpt: 'premiumLifeStyle.excerpt',
    url: '/goal/premium-lifestyle'
  },
];