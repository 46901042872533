import React from 'react';
import cx from 'classnames';

import {useIsScreenSm} from '@/providers/ScreenSizeProvider';
import {splitLines} from '@/utils/html-utils';
import useLocale from '@/hooks/useLocale';
import SolutionApproachMenu from '@/views/solution/svgs/SolutionApproachMenu';

import imgDivider from '@/assets/images/page-solution-layout-solution-approach/divider.svg';


const SolutionApproachHeadSection = (props = {}) => {

  const {namespace, banner, mobileBanner, page} = props;

  const {t} = useLocale(namespace);

  const isScreenSm = useIsScreenSm();

  return (
    <div>
      <section className={cx(
        'relative', 'h-[480px]',
        'bg-no-repeat', 'bg-cover', 'bg-bottom', 'overflow-visible'
      )} style={{backgroundImage: `url(${isScreenSm ? mobileBanner : banner})`}}>
        <div className={cx(
          'content', 'h-full',
          'space-x-0', 'sm:space-x-16',
          'flex', 'flex-col-reverse', 'sm:flex-row', 'justify-start', 'items-stretch', 'sm:items-end'
        )}>
          <div className={cx('relative', 'flex-1', 'sm:flex-[458]', 'flex-shrink-0')}>
            <div className={cx(
              'h-[328px]', 'sm:h-[420px]', 'w-auto',
              'absolute', '-right-4', 'sm:right-0', '-bottom-[50%]', 'sm:-bottom-[80px]',
            )}>
              <SolutionApproachMenu page={page}/>
            </div>
          </div>
          <div className={cx(
            'sm:pb-[72px]', 'flex-1', 'sm:flex-[651]', 'flex-shrink-0',
            'flex', 'flex-col', 'justify-end', 'sm:justify-start', 'items-start'
          )}>
            <h4 className={cx('text-white', 'font-normal', 'text-base', 'sm:text-xl')}>
              {t('head.excerpt')}
            </h4>
            <img alt="divider" src={imgDivider} className={cx('my-4', 'h-[2px]')}/>
            <h1 className={cx('block', 'text-white')}>{t('head.title')}</h1>
            <p className={cx('mt-4', 'block', 'font-light', 'text-xl', 'sm:text-2xl', 'text-primary-100')}>
              {t('head.description')}
            </p>
          </div>
        </div>
      </section>
      <section className={cx('pt-[172px]', 'sm:pt-[120px]', 'pb-12')}>
        <div className={cx('content', 'space-y-4', 'flex', 'flex-col', 'justify-start', 'items-center')}>
          <h2 className={cx('block', 'text-legacy-purple', 'text-center')}>{t('introduction.title')}</h2>
          <p className={cx('block', 'text-legacy-purple', 'text-center', 'text-xl')}>
            {splitLines(t('introduction.description'))}
          </p>
        </div>
      </section>
    </div>
  );

};

export default SolutionApproachHeadSection;