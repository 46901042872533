import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import cx from 'classnames';
import gsap from 'gsap';
import {observer} from 'mobx-react';
import {useLocation, useNavigate} from 'react-router-dom';

import {useIsScreenSm} from '@/providers/ScreenSizeProvider';
import {ContactStoreProvider, useContactStore} from '@/views/contact/stores/contact-context';
import {
  CONTACT_MODE_MEMBER,
  CONTACT_MODE_NON_MEMBER,
  CONTACT_MODE_UNDER_50M
} from '@/views/contact/stores/contact-store';
import ThankYouSection from '@/views/contact/sections/ThankYouSection';
import MemberScreeningSection from 'src/views/contact/sections/MemberScreeningSection';
import MemberEnquirySection from 'src/views/contact/sections/MemberEnquirySection';
import PersonalitySection from '@/views/contact/sections/PersonalitySection';
import CapitalSection from '@/views/contact/sections/CapitalSection';
import ServicesSection from '@/views/contact/sections/ServicesSection';
import NonMemberEnquirySection from 'src/views/contact/sections/NonMemberEnquirySection';
import ExpectationSection from '@/views/contact/sections/ExpectationSection';
import ChannelSection from '@/views/contact/sections/ChannelSection';
import AppointmentSection from '@/views/contact/sections/AppointmentSection';

import s from './styles.module.scss';


const ContactDialog = observer((props = {}) => {

  const [swiper, setSwiper] = useState(undefined);
  const [animationVisible, setAnimationVisible] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const isScreenSm = useIsScreenSm();

  const {hash, pathname: path} = useLocation();
  const navigate = useNavigate();

  const contactStore = useContactStore();
  const {visible, mode, step, totalSteps} = contactStore || {};

  const dialogRef = useRef(undefined);
  const contentRef = useRef(undefined);

  useEffect(() => {
    const duration = 0.3;
    if (visible) {
      gsap.fromTo(dialogRef.current, {opacity: 0}, {opacity: 1, duration, ease: 'circ.out'});
      setAnimationVisible(true);
    } else {
      gsap.fromTo(
        dialogRef.current,
        {opacity: 1},
        {
          opacity: 0,
          duration,
          ease: 'circ.out',
          onComplete: () => {
            setAnimationVisible(false);
          },
        },
      );
    }
  }, [animationVisible, visible]);

  useEffect(() => {
    console.log('>>update_autoheight<<', isScreenSm, swiper);
    if (!isScreenSm || !swiper || !swiper.initialized) {
      return;
    }
    setTimeout(() => {
      swiper.update();
      swiper.updateAutoHeight();
    }, 0);
  }, [isScreenSm, swiper, totalSteps, currentPageIndex]);

  const onClickClose = useCallback(event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setTimeout(() => {
      navigate(path, {state: {noScroll: true}});
    }, 300);
    contactStore.setVisible(false);
  }, [contactStore, navigate, path]);

  useEffect(() => {
    if (!onClickClose) return;
    contactStore.setOnClose(onClickClose);
  }, [onClickClose, contactStore]);

  useEffect(() => {
    if (!visible) return;
    let handler;
    const eventType = 'mouseup';
    document.body.addEventListener(eventType, handler = event => {
      const {target} = event;
      const {current: content} = contentRef;
      if (!content || content.contains(target)) return;
      onClickClose(null);
    });

    return () => document.body.removeEventListener(eventType, handler);
  }, [visible, onClickClose]);

  useEffect(() => {
    let handler;
    window.addEventListener('keydown', handler = event => {
      if (!event) return;
      const {keyCode} = event;
      if (keyCode !== 27) return;
      onClickClose();
    });

    return () => window.removeEventListener('keydown', handler);
  }, [onClickClose]);

  useEffect(() => {
    if (hash === '#contact') {
      contactStore.setVisible(true);
    } else if (!hash) {
      contactStore.reset();
    }
  }, [hash, contactStore]);

  useEffect(() => {
    if (visible && swiper && swiper.initialized) {
      try {
        swiper.enabled = true;
        swiper.update();
        swiper.slideTo(0);
      } catch (error) {
        console.error('>>error<< 01', error);
      }
    }
  }, [visible, swiper, path, hash, navigate, contactStore]);

  useEffect(() => {
    document.documentElement.style.overflow = visible ? 'hidden' : 'auto';
  }, [visible]);

  useEffect(() => {
    if (!swiper || !swiper.initialized) return;
    try {
      swiper.enabled = true;
      swiper.slideTo(step);
    } catch (error) {
      console.error('>>error<< 02', error);
    }
  }, [step, swiper]);

  const onSlideChange = useCallback(() => {
    setCurrentPageIndex(swiper.realIndex);
    swiper.enabled = false;
  }, [swiper]);

  const onClickBack = useCallback(() => {
    contactStore.back();
  }, [contactStore]);

  const slides = useMemo(() => {
    return (
      <>
        <SwiperSlide key={1001}>
          <MemberScreeningSection/>
        </SwiperSlide>
        {
          mode !== CONTACT_MODE_MEMBER ? null : (
            <>
              <SwiperSlide key={2001}>
                <MemberEnquirySection/>
              </SwiperSlide>
            </>
          )
        }
        {
          mode !== CONTACT_MODE_UNDER_50M ? null : (
            <>
              <SwiperSlide key={5001}>
                <PersonalitySection/>
              </SwiperSlide>
              <SwiperSlide key={5002}>
                <CapitalSection/>
              </SwiperSlide>
            </>
          )
        }
        {
          mode !== CONTACT_MODE_NON_MEMBER ? null : (
            <>
              <SwiperSlide key={3001}>
                <PersonalitySection/>
              </SwiperSlide>
              <SwiperSlide key={3002}>
                <CapitalSection/>
              </SwiperSlide>
              <SwiperSlide key={3003}>
                <ServicesSection/>
              </SwiperSlide>
              <SwiperSlide key={3004}>
                <NonMemberEnquirySection/>
              </SwiperSlide>
              <SwiperSlide key={3005}>
                <AppointmentSection/>
              </SwiperSlide>
              <SwiperSlide key={3006}>
                <ChannelSection/>
              </SwiperSlide>
              <SwiperSlide key={3007}>
                <ExpectationSection/>
              </SwiperSlide>
            </>
          )
        }
        <SwiperSlide key={4001}>
          <ThankYouSection/>
        </SwiperSlide>
      </>
    );
  }, [mode]);

  return (
    <div ref={dialogRef} className={cx(
      'dialog', 'fixed', 'left-0', 'top-0',
      'w-full', 'h-screen',
      'bg-black-0.6', 'z-[200]',
      'flex-col', 'justify-start', 'items-center',
      s.container, {[s.visible]: animationVisible}
    )}>
      <div className={cx('sm:flex-1', 'flex-shrink-0', 'min-h-[16px]', 'max-h-[16px]', 'sm:min-h-[48px]', 'sm:max-h-[unset]')}/>
      <div ref={contentRef} className={cx(
        'relative', 'content',
        'max-h-[calc(100%-120px)]',
        'sm:h-[calc(100%-96px)]', 'sm:max-h-[720px]'
      )}>
        <div className={cx(
          'w-full', 'h-full', 'bg-white', 'overflow-hidden',
          'flex', 'flex-col', 'justify-start', 'items-stretch',
        )}>
          <div className={cx(
            'py-2', 'px-4',
            'flex', 'flex-row', 'justify-end', 'items-center',
            s.head
          )}>
            <a className={cx('outline-none')} href="#" onClick={onClickClose}>
              <i className={cx('text-3xl', 'icon-cross', 'text-legacy-purple')}/>
            </a>
          </div>
          <hr className={cx('border-0', 'h-px')}/>
          <div className={cx(
            'relative', 'flex-1',
            'flex', 'flex-col', 'justify-start', 'items-stretch',
            'overflow-y-auto', 'overflow-x-hidden',
          )}>
            <div className={cx(
              'relative', 'flex-1',
              'flex', 'flex-col', 'justify-start', 'item-stretch',
              s.content, {[s['bg-blank']]: step !== 0 && step !== totalSteps},
            )}>
              <div className={cx(
                'flex', 'flex-col', 'justify-start', 'items-stretch',
                s.steps, {[s.visible]: step > 0}
              )}>
                <ul className={cx('space-x-5', 'flex', 'flex-row', 'justify-center', 'items-center')}>
                  {
                    [...Array(totalSteps)].map((_, index) => {
                      const currentStep = index + 1;
                      return (
                        <li key={currentStep} className={cx({[s.active]: currentStep <= step})}>
                          {currentStep}
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className={cx(
                'flex', 'flex-col', 'justify-start', 'items-start', s.back,
                {[s.visible]: step > 0 && step < totalSteps})}
              >
                <button className={cx(
                  'box-border', 'rounded-lg', 'py-1', 'pl-2', 'pr-4', 'border', 'border-primary-100',
                  'uppercase', 'font-medium', 'text-base', 'text-legacy-purple', 'leading-[1.2]',
                  'flex', 'flex-row', 'justify-center', 'items-center'
                )} onClick={onClickBack}>
                  <i className={cx('icon-chevron-left', 'text-2xl')}/>
                  <span>Back</span>
                </button>
              </div>
              <Swiper className={cx('relative', 'w-full', 'flex-1')}
                      style={{overflow: isScreenSm ? 'hidden' : 'visible'}}
                      observer={isScreenSm} observeSlideChildren={isScreenSm}
                      slidesPerView={1} spaceBetween={0} speed={isScreenSm ? 500 : 800} autoHeight={isScreenSm}
                      noSwiping allowTouchMove={false}
                      onSlideChangeTransitionEnd={onSlideChange}
                      onSwiper={swiper => setSwiper(swiper)}>
                {slides}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('sm:flex-1', 'sm:flex-shrink-0', 'min-h-[84px]', 'sm:min-h-[48px]')}/>
    </div>
  );

});

export default () => (
  <ContactStoreProvider>
    <ContactDialog/>
  </ContactStoreProvider>
);