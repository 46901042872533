import React from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';
import {Link} from 'react-router-dom';

import s from './styles.module.scss';
import { useIsScreenSm } from '@/providers/ScreenSizeProvider';
import { splitLines } from '@/utils/html-utils';
import useLocale from '@/hooks/useLocale';

const BannerTitleSection = (props = {}) => {

  const {banner, errBanner, title, description, hasSeemore = false, linkTo} = props;

  const { t } = useLocale('banner-title')

  const isScreenSm = useIsScreenSm();

  return (
    <section className={cx(
      'relative', 'w-full', 'aspect-square', 'sm:aspect-title-banner', 'mb-4', 'sm:mb-8',
      'bg-no-repeat', 'bg-cover', 'bg-center', 
      s.container
    )} style={{ backgroundImage: `url(${banner}), url(${errBanner})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className={cx(
        'absolute', 'inset-x-0', 'inset-y-0', 'w-full', 'h-full', 'space-y-4',
        'p-4', 'sm:p-0', 'flex', 'flex-col', 'justify-center', 'items-center', 'max-w-[1110px]', 'justify-self-center'
      )}>
        <h1 className={cx('font-serif', 'font-bold', 'text-white', 'text-center', 'break-words', 'line-clamp-2', 'text-ellipsis', s.title)}>
          {isScreenSm ? title : splitLines(title)}
        </h1>
        {
          !description ? null : (
            <>
              <p className={cx('font-light', 'text-primary-100', 'text-center', 'break-words', 'line-clamp-5', 'text-ellipsis', s.description)}>
                {description}
              </p>
              {
                hasSeemore && (
                  <Link to={linkTo} className={cx(
                    'self-center', 'btn', 'btn-outlined', 'btn-outlined-superlight', 'btn-md'
                  )}>
                    <span className={cx('tracking-wide')}>{t('readMore')}</span>
                  </Link>
                )
              }
            </>
          )
        }
      </div>
    </section>
    
  );

};

BannerTitleSection.propTypes = {
  banner: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default BannerTitleSection;