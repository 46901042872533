import {cloneDeep} from 'lodash';

export const chopArray = (input = [], itemsPerChunk = 3) => {
  if (input.length === 0) return [[]];
  const array = cloneDeep(input);
  const output = [];

  let chunk;
  while ((chunk = array.splice(0, itemsPerChunk)).length !== 0) {
    output.push(chunk);
  }

  const lastChunk = output[output.length - 1];
  if (lastChunk) {
    const lastChunkLength = lastChunk.length;
    if (lastChunkLength && lastChunkLength < itemsPerChunk) {
      for (let i = 0; i < itemsPerChunk - lastChunkLength; i++) {
        lastChunk.push(null);
      }
    }
  }

  return output;
};