
import { getInsightList, getInsightTags } from './getInsight';
import { getInsightDetail } from './getInsightDetail';
export const apisHomePageBanner = {
	getInsightList,
	getInsightTags
};
export const apisInsightDetail = {
	getInsightDetail,
};
