export default [
  {
    id: 1,
    title: {
      en: 'Investing',
      th: 'Investing',
    },
    url: '/solution/investing',
    items: [
      {
        id: 1,
        title: {
          en: 'Stocks & ETFs',
          th: 'Stocks & ETFs',
        },
        url: '/solution/stocks',
      },
      {
        id: 2,
        title: {
          en: 'Fixed Income',
          th: 'Fixed Income',
        },
        url: '/solution/fixed-income',
      },
      {
        id: 3,
        title: {
          en: 'Mutual Funds',
          th: 'Mutual Funds',
        },
        url: '/solution/mutual-funds',
      },
      {
        id: 4,
        title: {
          en: 'Mandate Service',
          th: 'Mandate Service',
        },
        url: '/solution/mandate-service',
      },
      {
        id: 5,
        title: {
          en: 'Structured Products',
          th: 'Structured Products',
        },
        url: '/solution/structured-product',
      },
      {
        id: 6,
        title: {
          en: 'Private Market',
          th: 'Private Market',
        },
        url: '/solution/private-market',
      },
      {
        id: 7,
        title: {
          en: 'Cash',
          th: 'Cash',
        },
        url: '/solution/cash'
      },
    ]
  },
  {
    id: 2,
    title: {
      en: 'Lending',
      th: 'Lending',
    },
    url: '/solution/lending',
    items: [
      {
        id: 1,
        title: {
          en: 'Lombard Loan',
          th: 'Lombard Loan',
        },
        url: '/solution/lombard-loan',
      },
      {
        id: 2,
        title: {
          en: 'Mortgage Loan',
          th: 'Mortgage Loan',
        },
        url: '/solution/mortgage-loan',
      },
      {
        id: 3,
        title: {
          en: 'Portfolio for Property Financing',
          th: 'Portfolio for Property Financing',
        },
        url: '/solution/property-financing',
      },
      {
        id: 4,
        title: {
          en: 'Commercial Loan',
          th: 'Commercial Loan',
        },
        url: '/solution/commercial-loan',
      },
    ],
  },
  {
    id: 3,
    title: {
      en: 'Planning and Advice',
      th: 'Planning and Advice',
    },
    url: '/solution/planning',
    items: [
      {
        id: 1,
        title: {
          en: 'Family Office & Wealth Planning Advisory',
          th: 'Family Office & Wealth Planning Advisory',
        },
        url: '/solution/family-wealth',
      },
      {
        id: 2,
        title: {
          en: 'Real Estate Advisory',
          th: 'Real Estate Advisory',
        },
        url: '/solution/real-estate',
      },
      {
        id: 3,
        title: {
          en: 'Financial Planning Advisory',
          th: 'Financial Planning Advisory',
        },
        url: '/solution/financial-planning',
      },
      {
        id: 4,
        title: {
          en: 'Insurance Advisory',
          th: 'Insurance Advisory',
        },
        url: '/solution/insurance',
      },
      {
        id: 5,
        title: {
          en: 'Investment Banking Service',
          th: 'Investment Banking Service',
        },
        url: '/solution/investment-banking',
      },
    ],
  },
  {
    id: 4,
    title: {
      en: 'Advisory Approach',
      th: 'Advisory Approach',
    },
    url: '/solution/cio-office',
    items: [
      {
        id: 1,
        title: {
          en: 'CIO Office',
          th: 'CIO Office',
        },
        url: '/solution/cio-office',
      },
      {
        id: 2,
        title: {
          en: 'Investment solution',
          th: 'Investment solution',
        },
        url: '/solution/investment-solution',
      },
      {
        id: 3,
        title: {
          en: 'Financial Consultant',
          th: 'Financial Consultant',
        },
        url: '/solution/financial-consultant',
      },
    ],
  },
];