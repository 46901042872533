import React from 'react';
import cx from 'classnames';

import useLocale from '@/hooks/useLocale';
import ApplicationFeature from "../../components/ApplicationFeature";

import s from './styles.module.scss';

import imgMobile from "../../../../../../assets/images/page-why-us-digital-section-line/mobile.png";


const WhyUsDigitalLineSection = (props = {}) => {

  const {t} = useLocale('page-why-us-digital-section-line');

  return (
    <section className={cx('relative', 'overflow-visible', s.container)}>
      <div className={cx(
        'relative', 'content',
        'flex', 'flex-col-reverse', 'sm:flex-row', 'justify-start', 'items-start'
      )}>
        <div className={cx('flex-1', 'flex', 'flex-col', 'justify-start', 'items-start', 'sm:items-end')}>
          <h2 className={cx('text-legacy-purple')}>{t('title')}</h2>
          <p className={cx('mt-2', 'text-legacy-purple', 'text-left', 'sm:text-right', 'text-xl')}>
            {t('description')}
          </p>
          <ul className={cx('mt-6', 'self-stretch', 'space-y-4', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
            <li className={cx('relative')}>
              <ApplicationFeature mode="light" title={t('feature01')}/>
            </li>
            <li className={cx('relative')}>
              <ApplicationFeature mode="light" title={t('feature02')}/>
            </li>
            <li className={cx('relative')}>
              <ApplicationFeature mode="light" title={t('feature03')}/>
            </li>
          </ul>
          <div className={cx('mt-4', 'space-x-4', 'flex', 'flex-row', 'justify-start', 'items-stretch')}>
            <a target="_blank" href="https://lin.ee/dFcW2y0"
               className={cx('btn', 'btn-outlined', 'btn-outlined-dark', 'btn-long')}>
              <span className={cx('text-primary-900', 'hover:text-black', 'tracking-wide')}>Add Friend</span>
            </a>
          </div>
        </div>
        <div className={cx('h-[200px]', 'sm:h-auto', 'sm:height-auto', 'sm:flex-1')}>
          <div className={cx(
            'absolute', 'top-0', 'right-0', 'pointer-events-none',
            'bg-contain', 'bg-no-repeat', 'bg-right', 'mt-4',
            s.mobile
          )} style={{backgroundImage: `url(${imgMobile})`, right: 0}}/>
        </div>
      </div>
    </section>
  );

};

export default WhyUsDigitalLineSection;