import React from 'react';
import cx from 'classnames';

import s from './styles.module.scss';

import { useParams } from 'react-router-dom';
import { getDateByLocale } from '@/utils/locales';


const AnnouncementEntry = (props = {}) => {
  const {language} = useParams();
  const {announcement = {}} = props;
  const {AnnouncementImage, TopicTh, TopicEn, DescTh, DescEn, ContentDate, IsDisplayButton, ButtonUrl, ButtonNameEn, ButtonNameTh} = announcement;

  const buttonClicked = () => {
    console.log(ButtonUrl)
    window.open(ButtonUrl, "_blank") //to open new page
 }

  return (
    <div className={cx(
      'flex', 'flex-row', 'justify-start', 'items-start', 'sm:items-stretch',
      'space-x-4', 'sm:space-x-8',
      s.container
    )}>
      <div className={cx(
        'aspect-square', 'rounded-lg', 'flex-shrink-0',
        'w-[72px]', 'sm:w-[160px]',
        'bg-no-repeat', 'bg-center', 'bg-cover', 'bg-primary-50',
      )} style={{backgroundImage: `url(${AnnouncementImage})`}}/>
      <div className={cx('flex-auto', 'flex-col', 'justify-center', 'items-start')}>
        <h4 className={cx('text-xl', 'flex-col', 'sm:text-2xl', 'text-primary-900', 'font-medium')}>{language === 'th' ? TopicTh : TopicEn}</h4>
        <p className={cx('mt-1', 'text-sm', 'sm:text-base', 'font-light', 'text-primary-900')}>{language === 'th' ? DescTh : DescEn}</p>
        <div className={cx(
          'py-2', 'mt-2', 'sm:mt-5', 'border-top', 'self-stretch',
          'font-light', 'text-primary-400', 'text-sm',
          'border-t', 'border-primary-50',
          'flex', 'flex-row', 'justify-between', 'items-center'
        )}>
          {<span>{getDateByLocale(ContentDate, language)}</span>}
          { IsDisplayButton ?
           <button onClick={buttonClicked} className={cx(
            'self-center', 'bg-white', 'btn', 'btn-outlined', 'btn-outlined-light', 'btn-sm'
                )}>
             {language === 'th' ? ButtonNameTh : ButtonNameEn}
           </button>: null }
          
        </div>
      </div>
    </div>
  );

};

export default AnnouncementEntry;