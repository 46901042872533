import React from 'react';
import cx from "classnames";

const RippleBackground = (props = {}) => {

  return (
    <svg
      className={cx('absolute', 'left-0', 'top-0', 'w-full', 'h-full')}
      width="1280" height="3024" viewBox="0 0 1280 3024" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <g clipPath="url(#clip0_325_357152)" style={{transform: 'translateY(-5.5%)'}}>
        <path d="M1294.42 1558.59C1458.8 1394.27 1611.96 942.397 1668 737.001C1506.12 836.587 1179.76 1035.76 678.024 1035.76L-1414 1035.76L-1414 1820L211.054 1820C659.345 1820 1088.96 1763.98 1294.42 1558.59Z" fill="url(#paint0_linear_325_357152)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_325_357152" x1="-1897.64" y1="-1998.8" x2="-2507.31" y2="1240.86" gradientUnits="userSpaceOnUse">
          <stop stopColor="#756F8E"/>
          <stop offset="1" stopColor="#EBEAF0"/>
        </linearGradient>
        <clipPath id="clip0_325_357152">
          <rect width="1280" height="3024" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );

};

export default RippleBackground;