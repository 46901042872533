import React, {useMemo} from 'react';
import cx from 'classnames';

import gridRelatedProducts from '@/views/goal/pages/GoalRetirementPage/data/grid-related-products';
import retirementPlanningRelatedProducts
  from '@/views/goal/pages/GoalRetirementPage/data/retirement-planning-related-products';
import useLocale from '@/hooks/useLocale';
import StandardLayout from "@/views/common/layouts/StandardLayout";
import BreadCrumbs from '@/views/common/components/BreadCrumbs';
import GoalRetirementHeadSection from './sections/GoalRetirementPage';
import GoalQuestionSection from '@/views/goal/sections/GoalQuestionSection';
import RetirementPlanningSection from '@/views/goal/pages/GoalRetirementPage/sections/RetirementPlanningSection';
import GoalHelpSection from '@/views/goal/sections/GoalHelpSection';
import GoalRelatedProductsGridSection from '@/views/goal/sections/GoalRelatedProductsGridSection';

import s from './styles.module.scss';


const GoalRetirementPage = (props = {}) => {

  const {t, i18n, locale} = useLocale('page-goal-retirement');

  const breadcrumbsPaths = useMemo(() => {
    return [
      {id: 1, title: 'Home', url: `/${locale}`},
      {id: 2, title: 'What\'s your goal', url: ''},
      {id: 3, title: t('pageName'), url: ''},
    ];
  }, [t, locale]);

  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <BreadCrumbs paths={breadcrumbsPaths}/>
        <GoalRetirementHeadSection/>
        <GoalQuestionSection/>
        <RetirementPlanningSection relatedProducts={retirementPlanningRelatedProducts}/>
        <GoalHelpSection/>
        <GoalRelatedProductsGridSection namespace="solutions" relatedProducts={gridRelatedProducts}/>
      </div>
    </StandardLayout>
  );

};

export default GoalRetirementPage;