import {useEffect, useState} from "react";
import gsap from "gsap";

const useGoalDoubleColumnAnimation = (triggerRef, containerRef, enabled = true) => {

  const [ctx, setCtx] = useState(undefined);

  useEffect(() => {
    if (enabled || !ctx) return;
    ctx.revert();
  }, [ctx, enabled]);

  useEffect(() => {

    if (!enabled) return;

    const ctx = gsap.context(() => {
      gsap.fromTo(
        containerRef.current,
        {
          opacity: 0,
          translateY: '100%',
        },
        {
          opacity: 1,
          translateY: '0',
          scrollTrigger: {
            trigger: triggerRef.current,
            start: "top bottom",
            end: "+=200",
            scrub: 1,
          }
        },
      );
    });
    setCtx(ctx);

    return () => ctx?.revert();

  }, [triggerRef, containerRef, enabled]);

};

export default useGoalDoubleColumnAnimation;