import Api from "../api-core";

export const createContactUs = async (
  locale,
  isAccount,
  name,
  lastName,
  phone,
  email,
  advisorName,
  investDesc,
  isAccepted,
  investorType,
  investorMoney,
  investorPurpose,
  convenientContactDate,
  investorChannel,
  ipAddress
) => {
  const formData = {
    AppLanguage: locale,
    IsAccount: isAccount,
    Name: name,
    LastName: lastName,
    Phone: phone,
    Email: email,
    AdvisorName: advisorName,
    InvestDesc: investDesc,
    IsAccepted: isAccepted,
    InvestorType: investorType,
    InvestorMoney: investorMoney,
    InvestorPurpose: investorPurpose,
    ConvenientContactDate: convenientContactDate,
    InvestorChannel: investorChannel,
    IpAddress: ipAddress,
  };
  try {
    const result = await Api().post(
      `${process.env.REACT_APP_CRATE_CONTACT_US}`,
      formData
    );
    return result;
  } catch (error) {
    // Handle errors
    console.error("Error:", error);
  }
};

export default createContactUs;
