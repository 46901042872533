import React from 'react';
import cx from 'classnames';

import useLocale from '@/hooks/useLocale';


const TimelineItem = (props = {}) => {

  const {locale} = useLocale('');

  const {item = {}} = props;
  const {title, description, banner} = item;

  return (
    <div className={cx(
      'space-y-4', 'sm:space-y-0', 'space-x-0', 'sm:space-x-9',
      'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch', 'sm:items-start'
    )}>
      <div className={cx(
        'sm:w-64', 'flex-shrink-0', 'aspect-small-banner', 'bg-white',
        'bg-center', 'bg-cover', 'bg-no-repeat'
      )} style={{backgroundImage: `url(${banner})`}}/>
      <div className={cx(
        'py-1', 'space-y-4', 'sm:space-y-3',
        'flex', 'flex-col', 'justify-center', 'items-stretch'
      )}>
        <h4 className={cx('text-primary-900', 'text-xl', 'sm:text-2xl')}>
          {title ? title[locale] : null}
        </h4>
        <p className={cx('text-primary-400', 'leading-[1.2]', 'sm:leading-normal')}>
          {description ? description[locale] : null}
        </p>
      </div>
    </div>
  );

};

export default TimelineItem;