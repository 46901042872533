import React, {useEffect, useRef} from 'react';
import cx from 'classnames';
import gsap from "gsap";
import useLocale from '@/hooks/useLocale';
import {splitLines} from '@/utils/html-utils';


const GoalHelpSection = (props = {}) => {

  const {t} = useLocale('page-goal-section-help');

  const containerRef = useRef(undefined);
  const triggerRef = useRef(undefined);

  useEffect(() => {

    const ctx = gsap.context(() => {
      gsap.fromTo(
        containerRef.current,
        {
          opacity: 0,
          translateY: '100%',
        },
        {
          opacity: 1,
          translateY: '0',
          scrollTrigger: {
            trigger: triggerRef.current,
            start: "top bottom",
            end: "bottom center",
            scrub: 1,
          }
        },
      );
    });

    return () => ctx.revert();
  });

  return (
    <section className={cx('relative', 'py-[120px]')}>
      <div ref={containerRef} className={cx('relative', 'content')}>
        <div className={cx('relative', 'px-4', 'py-12', 'bg-legacy-purple')}>
          <div className={cx(
            'space-y-4', 'mx-auto', 'max-w-[732px]', 'w-full',
            'flex', 'flex-col', 'justify-start', 'items-stretch'
          )}>
            <h1 className={cx('leading-[1.1]', 'text-center', 'text-white')}>
              {splitLines(t('title'))}
            </h1>
            <p className={cx('block', 'text-white', 'text-center')}>
              {splitLines(t('description'))}
            </p>
          </div>
        </div>
      </div>
      <div ref={triggerRef} className={cx('absolute', '-top-[100px]', 'left-0', 'w-full', 'h-full')}/>
    </section>
  );

};

export default GoalHelpSection;