import imgBanner01 from '@/assets/images/page-solution-approach-lending/grid-banner-01.png';
import imgBanner02 from '@/assets/images/page-solution-approach-lending/grid-banner-02.png';
export default [
  {
    id: 1,
    type: 'link',
    theme: 'light',
    title: 'lombardLoan.title',
    description: 'lombardLoan.description',
    url: '/solution/lombard-loan',
  },
  {
    id: 2,
    type: 'banner',
    banner: imgBanner01,
  },
  {
    id: 3,
    type: 'link',
    theme: 'purple',
    title: 'mortgageLoan.title',
    description: 'mortgageLoan.description',
    url: '/solution/mortgage-loan',
  },
  {
    id: 4,
    type: 'banner',
    banner: imgBanner02
  },
  {
    id: 5,
    type: 'link',
    theme: 'grey',
    title: 'propertyFinancing.title',
    description: 'propertyFinancing.description',
    url: '/solution/property-financing',
  },
  {
    id: 6,
    type: 'link',
    theme: 'dark',
    title: 'commercialLoan.title',
    description: 'commercialLoan.description',
    url: '/solution/commercial-loan',
  },
];