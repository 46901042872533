import React from 'react';
import cx from 'classnames';

import useLocale from '@/hooks/useLocale';
import GoalHeadSection from "@/views/goal/sections/GoalHeadSection";

import s from './styles.module.scss';

import imgBanner from '@/assets/images/page-goal-retirement-section-head/banner.png';


const GoalRetirementHeadSection = (props = {}) => {

  const {t} = useLocale('page-goal-accumulation-section-head');

  return (
    <GoalHeadSection title={t('title')} excerpt={t('excerpt')} banner={imgBanner}/>
  );

};

export default GoalRetirementHeadSection;