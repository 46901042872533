export default {
  motivations: [],
  prospectuses: [
    {
      id: 1,
      pinned: 'top',
      description: 'prospectuses.prospectus01.description',
    },
    {
      id: 2,
      title: 'prospectuses.prospectus02.title',
      description: 'prospectuses.prospectus02.description',
    },
    {
      id: 3,
      title: 'prospectuses.prospectus03.title',
      description: 'prospectuses.prospectus03.description',
    },
    {
      id: 4,
      title: 'prospectuses.prospectus04.title',
      description: 'prospectuses.prospectus04.description',
    },
    {
      id: 5,
      title: 'prospectuses.prospectus05.title',
      description: 'prospectuses.prospectus05.description',
    },
  ],
  investors: [
    {
      id: 1,
      title: 'investors.investor01',
    },
    {
      id: 2,
      title: 'investors.investor02',
    },
  ]
};