import React, {useCallback, useMemo} from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';

import imgNavigationBackground from "@/assets/images/page-home-section-banner/navigation-background.svg";
import imgBack from "@/assets/images/page-home-section-banner/ic-chevron-left.svg";
import imgNext from "@/assets/images/page-home-section-banner/ic-chevron-right.svg";

import s from './styles.module.scss';

export const NAVIGATION_BUTTON_MODE_BACK = 'back';
export const NAVIGATION_BUTTON_MODE_NEXT = 'next';


const NavigationButton = (props = {}) => {

  const {mode = NAVIGATION_BUTTON_MODE_BACK, onClick} = props;

  const iconImage = useMemo(() => {
    switch (mode) {
      case NAVIGATION_BUTTON_MODE_BACK:
        return imgBack;
      case NAVIGATION_BUTTON_MODE_NEXT:
        return imgNext;
      default:
        return undefined;
    }
  }, [mode]);

  const onClickNavigation = useCallback(() => {
    if (!onClick) return;
    onClick(mode);
  }, [mode, onClick]);

  return (
    <button onClick={onClickNavigation} className={cx(
      'w-9', 'h-[120px]', 'outline-none', 'bg-no-repeat', 'bg-contain', 'bg-center',
      'opacity-0', 'hover:opacity-50', 'transition', 'ease', 'duration-300',
      'flex', 'flex-row', 'justify-center', 'items-center', 'pointer-events-auto'
    )} style={{backgroundImage: `url(${imgNavigationBackground})`}}>
      <img alt="Back" src={iconImage}/>
    </button>
  );

};

NavigationButton.propTypes = {
  mode: PropTypes.oneOf([NAVIGATION_BUTTON_MODE_BACK, NAVIGATION_BUTTON_MODE_NEXT]),
  onClick: PropTypes.func
};

export default NavigationButton;