import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { splitLines, formatDescription } from '@/utils/html-utils';

import s from './styles.module.scss';

const ThingsToKnowCard = ({ title, description }) => {
  return (
    <div
      className={cx(
        'box-border', 'w-full', 'h-full', 'flex-shrink-0', 'p-6', 'space-y-4',
        'flex', 'flex-col', 'justify-start', 'align-stretch', 'rounded-lg',
        s.container
      )}
    >
      {title && (
        <h4 className={cx({'h-12': !!description}, 'text-xl', 'sm:text-2xl', 'font-medium', 'text-legacy-purple')}>
          {splitLines(title)}
        </h4>
      )}
      {description && (
        <p className={cx('text-legacy-purple', 'text-light', 'text-xl')}>
          <span dangerouslySetInnerHTML={{ __html: formatDescription(description) }} />
        </p>
      )}
    </div>
  );
};

ThingsToKnowCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

ThingsToKnowCard.defaultProps = {
  title: '',
  description: '',
};

export default ThingsToKnowCard;
