import React from 'react';
import cx from 'classnames';

import ServiceCard from "./components/ServiceCard";

import s from './styles.module.scss';


const WhyUsOurServicesWealthManagementSection = (props = {}) => {

  const {services = []} = props;

  return (
    <section className={cx('relative', 'py-16', s.container)}>
      <div className={cx('content', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <ul className={cx(
          'space-y-6', 'sm:space-y-0', 'space-x-0', 'sm:space-x-8',
          'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch'
        )}>
        {
          services.map(service => {
            const {id} = service;
            return (
              <li key={id} className={cx('flex-1', 'flex-shrink-0')}>
                <ServiceCard service={service}/>
              </li>
            )
          })
        }
        </ul>
      </div>
    </section>
  );

};

export default WhyUsOurServicesWealthManagementSection;