import React from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';

import {splitLines} from "@/utils/html-utils";
import useLocale from "@/hooks/useLocale";


const ServiceCard = (props = {}) => {

  const {service = {}} = props;
  const {title, cover, description, items = [], callToAction, url} = service;

  const {locale} = useLocale('');

  return (
    <div
      className={cx('bg-white', 'relative', 'w-full', 'h-full', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
      <div className={cx('p-6')}>
        <h2 className={cx('text-h1', 'sm:text-h2', 'text-primary-900')}>{splitLines(title)}</h2>
      </div>
      <div className={cx('block', 'aspect-square', 'bg-cover', 'bg-center', 'bg-no-repeat')}
           style={{backgroundImage: `url(${cover})`}}/>
      <div className={cx('p-6', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <p className={cx('text-primary-900', 'text-xl')}>{description[locale]}</p>
        <ul
          className={cx('space-y-2', 'py-6', 'pl-6', 'flex', 'flex-col', 'justify-start', 'items-stretch', 'list-disc')}>
          {
            items.map(item => {
              const {id, description} = item;
              return (
                <li key={id} className={cx('text-primary-900', 'text-xl')}>{description[locale]}</li>
              )
            })
          }
        </ul>
      </div>
      <div className={cx('flex-1')}/>
      <Link to={`/${locale}${url}`} className={cx(
        'mx-6', 'mb-6', 'relative',
        'btn', 'btn-outlined', 'btn-outlined-contrast', 'btn-compact',
        'flex', 'flex-row', 'justify-center', 'items-center',
      )}>
        <span className={cx('tracking-wide')}>{callToAction}</span>
      </Link>
    </div>
  );

};

export default ServiceCard;