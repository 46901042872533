import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import cx from 'classnames';
import Select, {components} from 'react-select';
import {observer} from 'mobx-react';

import useLocale from '@/hooks/useLocale';
import {useContactStore} from '@/views/contact/stores/contact-context';
import ContactLayout from '@/views/contact/layouts/ContactLayout';

import s from './styles.module.scss';


const ExpectationSection = observer((props = {}) => {

  const formRef = useRef(undefined);

  const {t, locale} = useLocale('dialog-contact');

  const contactStore = useContactStore();
  const {visible, channel} = contactStore;

  useEffect(() => {
    if (!visible) return;
    const {current: form} = formRef;
    form.reset();
  }, [visible]);

  const channels = useMemo(() => {
    return [
      {value: 1, label: t('channel.options.google')},
      {value: 2, label: t('channel.options.socialMedia')},
      {value: 3, label: t('channel.options.wordOfMouth')},
      {value: 4, label: t('channel.options.kkpfgWebsite')},
      {value: 5, label: t('channel.options.event')},
      {value: 6, label: t('channel.options.other')},
    ];
  }, [t]);

  const onChangeChannel = useCallback(channel => {
    contactStore.setChannel(channel);
  }, [contactStore]);

  const onClickNext = useCallback(() => {
    contactStore.next();
  }, [contactStore])

  const footerComponent = useMemo(() => {
    return (
      <div className={cx('flex', 'flex-row', 'justify-start', 'items-stretch', s.footer)}>
        <button className={cx('flex-1', 'btn', 'btn-submit', 'btn-next')} onClick={onClickNext}>
          <span className={cx('tracking-wider')}>
            Next Question
          </span>
        </button>
      </div>
    );
  }, [onClickNext]);

  return (
    <ContactLayout footerComponent={footerComponent}>
      <div className={cx(
        'flex-1', 'px-4', 'sm:px-8',
        'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'sm:justify-center', 'items-stretch',
        s.container
      )}>
        <div className={cx(
          'px-4', 'sm:py-6', 'box-border', 'flex-1',
          'space-y-4', 'sm:space-y-6',
          'flex', 'flex-col', 'justify-center', 'items-center'
        )}>
          <h2 className={cx(
            'font-semibold', 'text-center', 'text-legacy-purple',
            {'font-sans': locale !== 'en', 'font-serif': locale === 'en'}
          )}>
            {t('channel.title')}
          </h2>
          <hr className={cx('hidden', 'sm:block')}/>
        </div>
        <hr className={cx('block', 'sm:hidden')}/>
        <div className={cx('hidden', 'sm:block', 'w-8')}/>
        <div className={cx(
          'relative', 'box-border', 'py-4', 'sm:py-6', 'flex-1',
          'space-y-4', 'flex', 'flex-col', 'justify-start', 'sm:justify-center', 'items-stretch'
        )}>
          <form ref={formRef} className={cx('space-x-4', 'flex', 'flex-row', 'justify-start', 'items-stretch')}>
            <Select
              options={channels}
              defaultValue={undefined}
              value={channel}
              isSearchable={false}
              placeholder={t('channel.placeholder')}
              components={{
                DropdownIndicator: props => (
                  <components.DropdownIndicator {...props}>
                    <i className={cx('text-2xl', 'icon-chevron-down')}/>
                  </components.DropdownIndicator>
                )
              }}
              onChange={onChangeChannel}
              className={cx('w-full', 'relative', 'z-[400]', 'outline-none')}
              classNames={{
                indicatorSeparator: () => cx(s['select-sep']),
                control: ({isFocused}) => cx(
                  'select',
                  {[s['select-focused']]: isFocused},
                ),
                option: ({isSelected, isFocused}) => cx(
                  s['select-option'],
                  {[s['select-option-focused']]: isFocused},
                  {[s['select-option-selected']]: isSelected},
                )
              }}/>
          </form>
        </div>
      </div>
    </ContactLayout>
  );

});

export default ExpectationSection;