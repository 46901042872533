import React, {useMemo} from 'react';
import cx from 'classnames';

import gridItems from './data/grid-items';
import {PAGE_LENDING} from '@/views/solution/svgs/SolutionApproachMenu';
import SolutionApproachLayout from '@/views/solution/layouts/SolutionApproachLayout';
import SolutionApproachHeadSection from '@/views/solution/sections/SolutionApproachHeadSection';
import SolutionApproachLinkGridSection from '@/views/solution/sections/SolutionApproachLinkGridSection';
import ContactSection from '@/views/common/section/ContactSection';

import s from './styles.module.scss';

import imgBanner from '@/assets/images/page-solution-approach-lending/banner.png';
import imgMobileBanner from '@/assets/images/page-solution-approach-investment-solution/mobile-banner.png';
import imgChart from '@/assets/images/page-solution-approach-lending/chart.svg';


const SolutionApproachLendingPage = (props = {}) => {

  const namespace = useMemo(() => 'page-solution-approach-lending', []);

  return (
    <SolutionApproachLayout>
      <SolutionApproachHeadSection namespace={namespace} banner={imgBanner} mobileBanner={imgMobileBanner}
                                   chart={imgChart} page={PAGE_LENDING}/>
      <SolutionApproachLinkGridSection namespace={namespace} items={gridItems}/>
      <ContactSection/>
    </SolutionApproachLayout>
  );

};

export default SolutionApproachLendingPage;