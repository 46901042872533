import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import useLocale from '@/hooks/useLocale';

import {
  loadingHeader,
  loadingImgVertical,
  loadingImgLeft,
  loadingImgRight
} from '@/assets/images/page-insight';
import { get } from 'lodash';
import { getInsightList } from "@/apis/Insight/getInsight";

import { useIsScreenSm } from '@/providers/ScreenSizeProvider';
import ArticleEntry, {
  DIRECTION_HORIZONTAL,
  DIRECTION_HORIZONTAL_REVERSE,
  DIRECTION_VERTICAL,
} from '../../components/ArticleEntry';

import s from './styles.module.scss';

export const ARRANGEMENT_LONG_START = 'long-start';
export const ARRANGEMENT_LONG_END = 'long-end';
export const SEE_ALL_POSITION_TOP = 'top';
export const SEE_ALL_POSITION_BOTTOM = 'bottom';
export const SEE_ALL_POSITION_NONE = 'none';

const InsightSection = (props = {}) => {
  const {
    title,
    arrangement = ARRANGEMENT_LONG_START,
    seeAllPosition = SEE_ALL_POSITION_NONE,
    isRedirect,
    getInsight = {}
  } = props;

  const [insightsList, setInsightsList] = useState([]);
  const [isHasMore, setIsHasMore] = useState(false);
  const [displayCount, setDisplayCount] = useState(3);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { t, locale } = useLocale('section-insight');
  const isScreenSm = useIsScreenSm();

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        const response = await getInsightList(getInsight);
        const insights = get(response, 'data.insights', []);
        setInsightsList(insights);
      } catch (error) {
        setIsError(true);
        console.error("Error fetching insights:", error);
      }
      setIsLoading(false);
    };

    fetchInsights();
  }, []);

  useEffect(() => {
    setInsightsEntries(renderArticleEntries(displayCount));
    setIsHasMore(insightsList.length > displayCount);
  }, [insightsList]);

  useEffect(() => {
    setInsightsEntries(renderArticleEntries(displayCount));
    setIsHasMore(insightsList.length > displayCount);
  }, [displayCount]);

  const handleSeeAll = () => {
    setDisplayCount(displayCount + 3);
  };

  const renderArticleEntries = (endIndex) => {
    const entries = [];
    for (let i = 0; i < endIndex; i += 3) {
      entries.push(
        <React.Fragment key={i}>
          <div
            className={cx(
              'relative',
              'flex',
              'flex-col',
              'justify-start',
              'items-stretch',
              { 'sm:flex-row': arrangement === ARRANGEMENT_LONG_START },
              { 'sm:flex-row-reverse': arrangement === ARRANGEMENT_LONG_END }
            )}
          >
            <div className={cx('relative', 'flex-shrink-0', s.short)}>
              {isLoading ? (
                <img src={loadingImgVertical} />
              ) : (
                <ArticleEntry
                  article={get(insightsList, i) || null}
                  direction={DIRECTION_VERTICAL}
                />
              )}
            </div>
            <div className={cx('aspect-square', 'w-4', 'sm:w-8')} />
            <div
              className={cx(
                'relative',
                'flex-1',
                'space-y-4',
                'sm:space-y-8',
                'flex',
                'flex-col',
                'justify-start',
                'items-stretch',
                s.long
              )}
            >
              {isLoading ? (
                <>
                  <img src={loadingImgRight} />
                  <img src={loadingImgLeft} />
                </>
              ) : (
                <>
                  <ArticleEntry
                    article={get(insightsList, i + 1) || null}
                    direction={DIRECTION_HORIZONTAL_REVERSE}
                  />
                  <ArticleEntry
                    article={get(insightsList, i + 2) || null}
                    direction={DIRECTION_HORIZONTAL}
                  />
                </>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    }
    return entries;
  };

  const [insightsEntries, setInsightsEntries] = useState(renderArticleEntries(displayCount));

  
  if (!isLoading && (insightsList && insightsList.length === 0 )) {
    return null
  }

  return (
    <section className={cx('relative', s.container)}>
      <div
        className={cx(
          'content',
          'space-y-4',
          'sm:space-y-8',
          'flex',
          'flex-col',
          'justify-start',
          'items-stretch'
        )}
      >
        <div className={cx('flex', 'flex-row', 'justify-start', 'items-center', 'pb-9')}>
          {isLoading ? (
            <img src={loadingHeader} />
          ) : (
            <h2 className={cx('text-sm-h2', 'text-primary-900')}>
              {title || 'Insights'}
            </h2>
          )}

          <div className={cx('flex-1')} />
          {isScreenSm || seeAllPosition !== SEE_ALL_POSITION_TOP ? null : (
            <Link
              to={`/${locale}/insight/overview`}
              className={cx(
                'px-4',
                'py-2',
                'rounded-lg',
                'uppercase',
                'btn',
                'btn-md',
                'btn-outlined',
                'btn-outlined-light',
                'btn-next',
                'space-x-2',
                'flex',
                'flex-row',
                'justify-center',
                'items-center'
              )}
            >
              <span>See All</span>
            </Link>
          )}
        </div>
        {insightsEntries}
        {isLoading || (!isScreenSm && seeAllPosition !== SEE_ALL_POSITION_BOTTOM) ||
        (isScreenSm && seeAllPosition === SEE_ALL_POSITION_NONE) ? null : 
        isRedirect ?
        (
          <Link
            to={`/${locale}/insight/overview`}
            className={cx(
              'px-4',
              'py-2',
              'btn',
              'btn-md',
              'btn-outlined',
              'btn-outlined-light',
              'btn-next',
              'space-x-2',
              'flex',
              'flex-row',
              'justify-center',
              'items-center'
            )}
          >
            <span>{t('seeAll')}</span>
          </Link>
        ) : isHasMore ? (
          <button onClick={handleSeeAll} className={cx(
            'px-4',
            'py-2',
            'btn',
            'btn-md',
            'btn-outlined',
            'btn-outlined-light',
            'btn-next',
            'space-x-2',
            'flex',
            'flex-row',
            'justify-center',
            'items-center'
          )}>
            <span>{t('seeAll')}</span>
          </button>
        ) : null
      }
      </div>
    </section>
  );
};

InsightSection.propTypes = {
  title: PropTypes.string,
  arrangement: PropTypes.oneOf([ARRANGEMENT_LONG_START, ARRANGEMENT_LONG_END]),
  expertId: PropTypes.string,
  isRedirect: PropTypes.bool,
  seeAllPosition: PropTypes.oneOf([SEE_ALL_POSITION_TOP, SEE_ALL_POSITION_BOTTOM, SEE_ALL_POSITION_NONE]),
};

export default InsightSection;
