import React from 'react';
import cx from 'classnames';
import DOMPurify from 'dompurify';
import useLocale from '@/hooks/useLocale';
import Tag from '@/views/common/components/Tag';
import ArticleMetadata from '@/views/common/components/ArticleMetadata';
import KeyTakeaways from '@/views/common/components/KeyTakeaways';
import SocialShareBar from '@/views/common/components/SocialShareBar';
import imgBannerLoading from "@/assets/images/page-insight-section-insight/content.png";
import imgMoblieBannerLoading from "@/assets/images/page-insight-section-insight/content-mobile.png";
import useIsMobile from '@/hooks/useIsMobile'; 
import s from './styles.module.scss';
import { locals } from '@/utils/locales';
import parse from 'html-react-parser';

const InsightDetailsBodySection = ({ insight, isLoading }) => {
  const { locale } = useLocale('');
  const isMobile = useIsMobile(); 
  const {
    tags = [],
    headerEn = '',
    headerTh = '',
    descEn = '',
    descTh = '',
    keyTakeawayEn = '',
    keyTakeawayTh = '',
    contentTh = '',
    contentEn = '',
    downloadUrl = '',
    downloadText = '',
  } = insight || {};

  const header = locale === locals.en ? headerEn : headerTh;
  const desc = locale === locals.en ? descEn : descTh;
  const content = locale === locals.en ? contentEn : contentTh;
  const keyTakeaway = locale === locals.en ? keyTakeawayEn : keyTakeawayTh;
  const bannerImage = isMobile ? imgMoblieBannerLoading : imgBannerLoading;
  if (isLoading) {
    return (
      <section className={cx('w-full', 'pb-[96px]', s.container)}>
        <div className={cx('relative', 'w-full', 'content')}>
          <img
            src={bannerImage}
            alt="Loading"
            className={cx('w-full', 'h-auto', 'object-cover', 'min-h-[400px]')}
          />
        </div>
      </section>
    );
  }

  return (
    <section className={cx('w-full', 'pb-[96px]', s.container)}>
      <div className={cx('relative', 'w-full', 'max-w-screen-lg', 'mx-auto', 'p-4', 'sm:px-6', 'lg:px-8')}>
        <div className={cx(
          'sm:p-16',
          'flex', 'flex-col', 'justify-start', 'items-stretch',
          'bg-white', 'border', 'border-primary-50', 
          s.content
        )}>
          <div className={cx('flex', 'flex-col', 'justify-start', 'items-start', 'w-full', 'px-4', 'py-6', 'sm:p-6', 'lg:p-8')}>
            {/* Content goes here */}
            {tags.length > 0 && (
              <div className="flex flex-wrap gap-2 min-h-[19px]">
                {tags.map((tag, index) => (
                  <Tag
                    key={index}
                    title={tag.name}
                  />
                ))}
              </div>
            )}
            <h2 className={cx('text-primary-900', 'py-4')}>{header}</h2>
            <p className={cx('text-legacy-purple', 'pb-4')}>
              {desc}
            </p>
            <div className={cx('self-stretch', 'flex', 'flex-col', 'flex-start', 'items-stretch')}>
              <ArticleMetadata insight={insight}/>
            </div>
            <div className={cx('pt-6', 'self-stretch', 'flex', 'flex-col', 'flex-start', 'items-center', 'sm:items-end')}>
              <SocialShareBar header={header}/>
            </div>
          </div>
          <div className={cx(
            'space-y-8', 'text-primary-900',
            'flex', 'flex-col', 'justify-start', 'items-stretch',
            'w-full', 'px-4', 'sm:px-6', 'lg:px-8'
          )}>
            {keyTakeaway && (
              <KeyTakeaways insight={insight}/>
            )}
            <div className={cx(s.detail)}>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            {downloadUrl && downloadText && (
              <div className={cx(
                'py-8', 'my-8',
                'border-t', 'border-primary-200',
                'flex', 'flex-row', 'justify-center', 'items-center',
              )}>
                <div className={cx('flex', 'flex-row', 'justify-start', 'align-stretch')}>
                  <a href={downloadUrl} className={cx(
                    'p-4', 'border-y', 'border-l', 'border-primary-200',
                    'rounded-tl-lg', 'rounded-bl-lg',
                  )}>
                    <span className={cx('text-primary-900')}>{downloadText}</span>
                  </a>
                  <a
                    href={downloadUrl}
                    className={cx('btn', 'btn-submit', 'btn-lg-short', 'btn-rounded-right')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className={cx('tracking-wide')}>
                      {locale === locals.en ? "Download" : "ดาวน์โหลด"}
                    </span>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>

  );
};

export default InsightDetailsBodySection;
