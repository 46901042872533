import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import { getInsightList, getInsightTags } from '@/apis/Insight/getInsight';
import { get } from 'lodash';
import useLocale from '@/hooks/useLocale';
import StandardLayout from "@/views/common/layouts/StandardLayout";
import BreadCrumbs from '@/views/common/components/BreadCrumbs';
import BannerTitleSection from '@/views/common/section/BannerTitleSection';
import InsightListSection from "./sections/InsightListSection";
import NewsLetterSection from '@/views/common/section/NewsLetterSection';
import { locals } from '@/utils/locales';
import { loadingInsightBanner, errorBanner, bannerDefault, bannerEmpty } from '@/assets/images/page-insight';
import s from './styles.module.scss';

const InsightPage = (props = {}) => {
  const [insightData, setInsightData] = useState(null);
  const [categoriesData, setCategoriesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { locale } = useLocale('');
  const [bannerImage, setBannerImage] = useState(null);
  const [bannerTitle, setBannerTitle] = useState(null);
  const [bannerDescription, setBannerDescription] = useState(null);
  const [bannerId, setBannerId] = useState(null)

  const breadcrumbsPaths = useMemo(() => {
    return [
      { id: 1, title: 'Home', url: `/${locale}` },
      { id: 3, title: 'Insights', url: '' },
    ];
  }, [locale]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response, categories] = await Promise.all([
          getInsightList({ displayBanner: true, limit: 100 }),
          getInsightTags({ limit: 10 })
        ]);

        setInsightData(get(response, 'data', []));
        setCategoriesData(get(categories, 'data', []));
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!insightData) return;
  
    const banner = get(insightData, 'banner', {});
    const bannerId = get(banner, 'id', null);
    const bannerImage = banner.largeImage || bannerDefault;
    const bannerTitle = locale === locals.th ? banner.headerTh : banner.headerEn;
    const bannerDescription = locale === locals.th ? banner.descTh : banner.descEn;
  
    if (bannerId != null) {
      setBannerId(bannerId);
      setBannerImage(bannerImage);
      setBannerTitle(bannerTitle);
      setBannerDescription(bannerDescription);
    } else {
      setBannerImage(bannerEmpty);
    }
  }, [insightData, locale]);
  

  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <BreadCrumbs paths={breadcrumbsPaths} />
        {
          isLoading ? 
          <section className={cx(
            'relative', 'w-full', 'aspect-square', 'sm:aspect-title-banner', 'mb-4', 'sm:mb-8',
            'bg-no-repeat', 'bg-cover', 'bg-bottom',
            s.container
          )} style={{ backgroundImage: `url(${loadingInsightBanner})` }}>
          </section> :
          error ?
          <section className={cx(
            'relative', 'w-full', 'aspect-square', 'sm:aspect-title-banner', 'mb-4', 'sm:mb-8',
            'bg-no-repeat', 'bg-cover', 'bg-bottom',
            s.container
          )} style={{ backgroundImage: `url(${errorBanner})` }}>
          </section> :
          <BannerTitleSection 
            title={bannerTitle} 
            description={bannerDescription} 
            banner={bannerImage} 
            errorBanner={bannerDefault}
            hasSeemore={true}
            linkTo={`/${locale}/insight/${bannerId}`}
          />
        }
        <InsightListSection error={error} isLoading={isLoading} insightsList={get(insightData, 'insights')} categories={categoriesData}/>
        <NewsLetterSection/>
      </div>
    </StandardLayout>
  )
};

export default InsightPage;
