import {useCallback, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {validLocales} from '@/utils/locales';


const useLocale = (ns, invalidLocaleRedirect = true) => {

  const {language} = useParams();
  const {t, i18n} = useTranslation(ns);

  const navigate = useNavigate();

  useEffect(() => {
    if (!language || !invalidLocaleRedirect || validLocales.includes(language)) return;
    navigate('/en/404');
  }, [language, invalidLocaleRedirect, navigate]);

  useEffect(() => {
    if (!i18n || !language) return;
    (async () => {
      await i18n.changeLanguage(language);
    })();
  }, [i18n, language]);

  const tWithEmptyDefault = useCallback(key => {
    return t(key, '');
  }, [t]);

  return {t: tWithEmptyDefault, i18n, locale: language};

};

export default useLocale;