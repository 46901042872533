import React, { useEffect, useMemo, useRef } from "react";
import cx from "classnames";
import gsap from "gsap";
import { Link } from "react-router-dom";
import useLocale from "@/hooks/useLocale";

import { useIsScreenSm } from "@/providers/ScreenSizeProvider";
import { splitLines } from "@/utils/html-utils";

import s from "./styles.module.scss";

export const HOME_BANNER_CARD_MODE_LTR = "ltr";
export const HOME_BANNER_CARD_MODE_RTL = "rtl";

const HomeBannerCard = (props = {}) => {
  const {
    mode = HOME_BANNER_CARD_MODE_LTR,
    active,
    title,
    description,
    showbutton,
    buttonText,
    banners = {},
    url,
  } = props;

  const { locale } = useLocale("");

  const isScreenSm = useIsScreenSm();

  const banner = useMemo(() => {
    return isScreenSm ? banners.mobile : banners.desktop;
  }, [banners, isScreenSm]);

  const textRef = useRef(undefined);
  const graphicRef = useRef(undefined);

  useEffect(() => {
    if (!active) return;
    const ctx = gsap.context(() => {
      const duration = 1;
      const delay = 0.45;
      gsap.fromTo(
        textRef.current,
        { translateY: "20%" },
        { translateY: "0", duration, ease: "circ.out", delay }
      );
      gsap.fromTo(
        textRef.current,
        { opacity: 0 },
        { opacity: 1, duration, ease: "circ.in", delay }
      );
      gsap.fromTo(
        graphicRef.current,
        { opacity: 0 },
        { opacity: 1, duration, ease: "circ.in", delay }
      );
    });

    return () => ctx.revert();
  }, [active]);
 
  return (
    <div
      className={cx(
        "relative",
        "w-full",
        "aspect-square",
        "sm:aspect-home-banner",
        // "bg-legacy-purple",
        "bg-center",
        "bg-cover",
        "bg-no-repeat",
        s.container
      )}
      style={{ backgroundImage: `url(${banner.background})` }}
    >
      <div
        ref={graphicRef}
        className={cx(
          "absolute",
          "left-0",
          "top-0",
          "w-full",
          "h-full",
          "bg-center",
          "bg-cover",
          "bg-no-repeat"
        )}
        style={{ backgroundImage: `url(${banner.overlay})` }}
      />
      <div
        ref={textRef}
        className={cx(
          "absolute",
          "left-0",
          "top-0",
          "w-full",
          "h-full",
          "flex",
          "flex-col",
          "justify-center",
          "items-center"
        )}
      >
        <div
          className={cx(
            "content",
            "flex",
            "flex-row",
            "justify-start",
            "items-start"
          )}
        >
          {mode !== HOME_BANNER_CARD_MODE_RTL ? null : (
            <div className={cx("flex-1", "flex-shrink-0")} />
          )}
          <div
            className={cx(
              "w-[80%]",
              "sm:w-auto",
              "sm:flex-1",
              "flex-shrink-0",
              "flex",
              "flex-col",
              "justify-start",
              { "items-start": mode === HOME_BANNER_CARD_MODE_LTR },
              { "items-end": mode === HOME_BANNER_CARD_MODE_RTL }
            )}
          >
            <h1
              className={cx("text-white", {
                "text-right": mode === HOME_BANNER_CARD_MODE_RTL,
              })}
            >
              {splitLines(title)}
            </h1>
            <p
              className={cx(
                "mt-3",
                "sm:mt-4",
                "text-white",
                "text-sm",
                "sm:text-xl",
                "font-light",
                { "text-right": mode === HOME_BANNER_CARD_MODE_RTL }
              )}
            >
              {splitLines(description)}
            </p>
            {/* {banner.showbutton && <Link to={`/${locale}${url}`} className={cx('mt-2', 'sm:mt-6', 'btn', 'btn-bright', 'btn-md')}>
              <span className={cx('font-medium', 'text-xs', 'sm:text-base', 'leading-[1.2]')}>
                {splitLines(buttonText)}
              </span>
            </Link>} */}
             {showbutton && <Link to={`${url}`} className={cx('mt-2', 'sm:mt-6', 'btn', 'btn-bright', 'btn-md')}>
              <span className={cx('font-medium', 'text-xs', 'sm:text-base', 'leading-[1.2]')}>
                {splitLines(buttonText)}
              </span>
            </Link>}
          </div>
          {mode !== HOME_BANNER_CARD_MODE_LTR ? null : (
            <div className={cx("flex-1", "flex-shrink-0")} />
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeBannerCard;
