export default [
  {
    id: 1,
    title: 'stocksAndEtfs.title',
    description: 'stocksAndEtfs.description',
    url: '/solution/stocks',
  },
  {
    id: 2,
    title: 'mutualFunds.title',
    description: 'mutualFunds.description',
    url: '/solution/mutual-funds',
  },
  {
    id: 3,
    title: 'mandateService.title',
    description: 'mandateService.description',
    url: '/solution/mandate-service',
  },
  {
    id: 4,
    title: 'privateMarket.title',
    description: 'privateMarket.description',
    url: '/solution/private-market',
  },
];