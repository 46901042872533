import React, { useMemo } from 'react';
import cx from 'classnames';
import { chopArray } from '@/utils/array-utils';
import { useIsScreenSm } from '@/providers/ScreenSizeProvider';
import useLocale from '@/hooks/useLocale';
import SolutionApproachContentSection from '@/views/solution/sections/SolutionApproachContentSection';
import BannerCard from '@/views/solution/sections/SolutionApproachLinkGridSection/components/BannerCard';
import LinkCard from '@/views/solution/sections/SolutionApproachLinkGridSection/components/LinkCard';

const SolutionApproachLinkGridSection = (props = {}) => {
  const { items = [], namespace } = props;
  const isScreenSm = useIsScreenSm();
  const { t } = useLocale(namespace);
  const cardsPerRow = useMemo(() => (isScreenSm ? 1 : 3), [isScreenSm]);

  return (
    <SolutionApproachContentSection>
      <div className={cx('space-y-6', 'flex', 'flex-col', 'justify-start', 'items-stretch')}>
        {
          chopArray(items, cardsPerRow).map((items, index) => (
            <ul key={index} className={cx('space-x-6', 'flex', 'flex-row', 'justify-start', 'items-stretch')}>
              {
                items.map(item => {
                  const { id } = item;
                  return (
                    <li key={id} className={cx('flex-1', 'flex-shrink-0', 'flex','flex-col')}>
                      {
                        (() => {
                          const { type } = item;
                          if (type === 'banner') {
                            const { banner } = item;
                            return <BannerCard banner={banner} />;
                          } else if (type === 'link') {
                            const { theme, title, description, url } = item;
                            return <LinkCard {...{ theme, title: t(title), description: t(description), url }} />;
                          } else {
                            return null;
                          }
                        })()
                      }
                    </li>
                  );
                })
              }
            </ul>
          ))
        }
      </div>
    </SolutionApproachContentSection>
  );
};

export default SolutionApproachLinkGridSection;
