import React from 'react';
import cx from 'classnames';

import TimelineItem from "../TimelineItem";

import s from './styles.module.scss';


const TimelineGroup = (props = {}) => {

  const {yearIndented = false, active = true, group = {}} = props;
  const {year, items} = group;

  return (
    <div className={cx('space-x-6', 'flex', 'flex-row', 'justify-start', 'items-stretch', s.container)}>
      <div className={cx('sm:w-20', 'flex', 'flex-col', 'justify-start', 'items-center', 'flex-shrink-0', 'space-y-1')}>
        <h2 className={cx(
          {'text-legacy-purple': active},
          {'text-primary-200': !active},
          {'pt-16': yearIndented},
        )}>
          {year}
        </h2>
        {
          active ? (
            <div className={cx('flex-1', 'flex', 'flex-col', 'justify-start', 'items-center')}>
              <div className={cx('w-2', 'h-2', 'rounded', s.dot)}/>
              <div className={cx('w-px', 'flex-1', s.line)}/>
            </div>
          ) : null
        }
      </div>
      <ul className={cx(
        'flex-1', 'pt-16', 'sm:pl-12', 'space-y-12',
        'flex', 'flex-col', 'justify-start', 'items-stretch',
      )}>
        {
          items.map(item => {
            const {id} = item;
            return (
              <li key={id}>
                <TimelineItem item={item}/>
              </li>
            );
          })
        }
      </ul>
    </div>
  );

};

export default TimelineGroup;