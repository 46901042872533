export default [
  {
    id: 1,
    title: 'stocks.title',
    description: 'stocks.description',
    theme: 'light',
    url: '/solution/stocks',
  },
  {
    id: 2,
    title: 'privateMarket.title',
    description: 'privateMarket.description',
    theme: 'grey',
    url: '/solution/private-market',
  },
  {
    id: 3,
    title: 'mandateService.title',
    description: 'mandateService.description',
    theme: 'light',
    url: '/solution/mandate-service',
  },
  {
    id: 4,
    title: 'mutualFunds.title',
    description: 'mutualFunds.description',
    theme: 'purple',
    url: '/solution/mutual-funds',
  },
  {
    id: 5,
    title: 'structuredProduct.title',
    description: 'structuredProduct.description',
    theme: 'light',
    url: '/solution/structured-product',
  },
  {
    id: 6,
    title: 'familyWealth.title',
    description: 'familyWealth.description',
    theme: 'purple',
    url: '/solution/family-wealth',
  },
];
