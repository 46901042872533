import React from 'react';

import GoalDoubleColumnSection, {GOAL_DOUBLE_SECTION_MODE_LTR} from "../../../../sections/GoalDoubleColumnSection";
import useLocale from "@/hooks/useLocale";

import imgBanner from '@/assets/images/page-goal-family-section-family-wealth-planning/banner.png';


const FamilyWealthPlanningSection = (props = {}) => {

  const {relatedProducts} = props;

  const {t} = useLocale('page-goal-family-section-family-wealth-planning');

  return (
    <GoalDoubleColumnSection mode={GOAL_DOUBLE_SECTION_MODE_LTR}
                             banner={imgBanner} title={t('title')} excerpt={t('excerpt')}
                             description={t('description')} products={relatedProducts}/>
  )

};

export default FamilyWealthPlanningSection;