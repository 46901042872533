import Api from "../api-core";

const getTags = async ({
  limit,
  offset
}) => {
  try {
    const payload = {
      params: {
        limit,
        offset
      }
    }
    const result = await Api().get(
      `${process.env.REACT_APP_WEALTH_BASE_API}/Tags`,
      payload
    );
    return result.data;
  } catch (error) {
    throw new ("Error:", error)();
  }
};

export {
	getTags
};