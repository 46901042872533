import React, {useCallback, useMemo} from 'react';
import cx from 'classnames';
import {observer} from 'mobx-react';
import {Trans} from 'react-i18next';

import useLocale from '@/hooks/useLocale';
import {useContactStore} from '@/views/contact/stores/contact-context';
import {splitLines} from '@/utils/html-utils';
import ContactLayout from '@/views/contact/layouts/ContactLayout';

import s from './styles.module.scss';

import imgThankYou from '@/assets/images/dialog-contact-section-thank-you/thank-you.svg';
import imgNext from '@/assets/images/dialog-contact-section-thank-you/chevron-right.svg';


const ThankYouSection = observer((props = {}) => {

  const {t, locale} = useLocale('dialog-contact');

  const contactStore = useContactStore();
  const {capitalIndex} = contactStore || {};

  const budgetUnder50m = useMemo(() => {
    return capitalIndex !== undefined && capitalIndex <= 2;
  }, [capitalIndex]);

  const onClickFinish = useCallback(() => {
    contactStore.next();
  }, [contactStore]);

  const footerComponent = useMemo(() => {
    return (
      <div className={cx('flex', 'flex-row', 'justify-start', 'items-stretch', s.footer)}>
        <button className={cx(
          'flex-1', 'p-4', 'btn', 'btn-bright', 'btn-bright-light', 'btn-lg',
          'flex', 'flex-horizontal', 'justify-center', 'items-center',
        )} onClick={onClickFinish}>
          <span className={cx('tracking-wider')}>
            {t('thankYou.close')}
          </span>
        </button>
      </div>
    );
  }, [t, onClickFinish]);

  return (
    <ContactLayout footerComponent={footerComponent}>
      <div className={cx(
        {'min-h-[500px]': budgetUnder50m}, 'sm:min-h-[unset]',
        'flex-1', 'flex', 'flex-col', 'justify-center', 'items-center', s.container
      )}>
        <div className={cx('-mt-[112px]', 'sm:mt-0', 'flex-1', 'flex', 'flex-col', 'justify-center', 'items-center')}>
          <img className={cx('flex-shrink-0')} alt="Thank you" src={imgThankYou}/>
          <div className={cx('mt-10', 'flex', 'flex-col', 'justify-start', 'items-center')}>
            <hr className={cx('upper', 'border-0', 'h-px', 'w-full', 'max-w-[100px]')}/>
            <div className={cx('h-4')}/>
            <h2 className={cx(
              'text-center', 'font-sans', 'font-semibold', 'text-legacy-purple',
              {'font-serif': locale === 'en'}
            )}>
              <Trans t={t} i18nKey="thankYou.title">
                <br/>
                <span className={cx('font-serif', 'font-bold')}></span>
              </Trans>
            </h2>
            <span className={cx('mt-4', 'mx-4', 'font-normal', 'text-legacy-purple', {'text-center': budgetUnder50m})}>
              {!budgetUnder50m ? t('thankYou.excerpt') : <>{splitLines(t('thankYou.limitation'))}{' '}</>}
              {
                budgetUnder50m ? (
                  <a target="_blank" className={cx('underline', 'link', 'link-legacy-purple')}
                     href="https://edge.co.th/">
                    {t('thankYou.visitEdge')}
                  </a>
                ) : null
              }
            </span>
            <div className={cx('h-4')}/>
            <hr className={cx('upper', 'border-0', 'h-px', 'w-full', 'max-w-[100px]')}/>
          </div>
        </div>
      </div>
    </ContactLayout>
  );

});

export default ThankYouSection;