import React from 'react';
import cx from 'classnames';

import StandardLayout from "@/views/common/layouts/StandardLayout";

import s from './styles.module.scss';

import imgBackground from '../../../../assets/images/page-login/background.png';
import imgLogo from '../../../../assets/images/common/logo-long.svg';
import Alert, {ALERT_THEME_ANNOUNCEMENT, ALERT_THEME_ERROR} from '@/views/common/components/Alert';


const LoginPage = (props = {}) => {

  return (
    <StandardLayout>
      <div className={cx(
        'sm:min-h-[800px]',
        'flex', 'flex-col', 'sm:flex-row', 'justify-start', 'items-stretch', s.container
      )}>
        <div className={cx(
          'min-h-[268px]', 'sm:min-h-[auto]',
          'flex-1', 'bg-legacy-purple', 'bg-no-repeat', 'bg-cover', 'bg-center'
        )} style={{backgroundImage: `url(${imgBackground})`}}>
          <div className={cx('block', 'sm:hidden', 'absolute', 'left-0', 'top-0', 'w-full')}>
            <Alert theme={ALERT_THEME_ANNOUNCEMENT} message={'Purus ultrices blandit eget posuere.\nAmet tellus congue est amet luctus lobortis ullamcorper.'}/>
            <Alert theme={ALERT_THEME_ERROR} message={'Invalid username and password'}/>
          </div>
        </div>
        <div className={cx('relative', 'flex-1', 'flex', 'flex-col', 'justify-center', 'items-center')}>
          <div className={cx('hidden', 'sm:block', 'absolute', 'left-0', 'top-0', 'w-full')}>
            <Alert theme={ALERT_THEME_ANNOUNCEMENT} message={'Purus ultrices blandit eget posuere.\nAmet tellus congue est amet luctus lobortis ullamcorper.'}/>
            <Alert theme={ALERT_THEME_ERROR} message={'Invalid username and password'}/>
          </div>
          <div className={cx(
            'pt-12', 'pb-10', 'sm:py-6',
            'flex', 'flex-row', 'justify-center', 'items-center'
          )}>
            <img alt="Logo" src={imgLogo}/>
          </div>
          <form className={cx(
            'sm:mt-4', 'pb-10', 'sm:py-0',
            'flex', 'flex-col', 'justify-start', 'items-stretch', 'space-y-4'
          )}>
            <input type="text" className={cx(
              'rounded-lg', 'border', 'border-solid', 'border-gd-grey',
              'px-4', 'py-3.5', 'text-base', 'font-light', 'placeholder-gd-grey',
            )} placeholder="Username"/>
            <input type="password" className={cx(
              'rounded-lg', 'border', 'border-solid', 'border-gd-grey',
              'p-4', 'py-3.5', 'text-base', 'font-light', 'placeholder-gd-grey',
            )} placeholder="Password"/>
            <button type="submit" className={cx(
              'rounded-lg',
              'p-4', 'py-3.5', 'text-base', 'font-light', 'placeholder-gd-grey',
            )}>
              <span className={cx('uppercase', 'font-medium', 'text-white', 'tracking-wider')}>Login</span>
            </button>
            <div className={cx('flex', 'flex-row', 'justify-start', 'items-center')}>
              <div className={cx('flex-1', 'h-px', s.sep)}/>
              <a className={cx('text-legacy-purple', 'mx-4', 'font-light')}>Forgot password</a>
              <div className={cx('flex-1', 'h-px', s.sep)}/>
            </div>
          </form>
        </div>
      </div>
    </StandardLayout>
  );

};

export default LoginPage;