import imgBanner01 from '../../../assets/images/page-why-us-our-services/banner-01.png';
import imgBanner02 from '../../../assets/images/page-why-us-our-services/banner-02.png';
import imgBanner03 from '../../../assets/images/page-why-us-our-services/banner-03.png';

export default [
  {
    id: 1,
    cover: imgBanner01,
    title: 'Self-Directed\nInvestment',
    description: {
      en: 'Through our state-of-the-art Kiatnakin Phatra client portal, you will have access to important market information and Kiatnakin Phatra’s award-winning research to assist you in making informed investment decisions on your own terms.',
      th: 'เข้าถึงข้อมูลข่าวสารบทวิเคราะห์คุณภาพผ่าน Kiatnakin Phatra client portal เพื่อช่วยในการตัดสินใจการลงทุนของท่านลูกค้าด้วยตนเอง'
    },
    callToAction: 'Our Offer',
    url: '/solution/investing',
    items: [
      {
        id: 1,
        description: {
          en: 'Powerful trading platforms to help you navigate the market',
          th: 'แพลตฟอร์มที่พร้อมช่วยคุณในทุกโอกาสการลงทุน'
        },
      },
      {
        id: 2,
        description: {
          en: 'Research, tools, and expert insights to help make confident investment decisions',
          th: 'ตัดสินใจได้อย่างแม่นยำด้วยบทวิเคราะห์ เครื่องมือและมุมมองจากผู้เชี่ยวชาญของเรา'
        },
      },
      {
        id: 3,
        description: {
          en: 'Thousands of Investments available includes stocks, ETFs, and mutual funds.',
          th: 'เข้าถึงการลงุทนจากทั่วโลกทั้ง หุ้น อีทีเอฟ และกองทุนรวม เพียงปลายนิ้วสัมผัส'
        },
      }
    ]
  },
  {
    id: 2,
    cover: imgBanner02,
    title: 'Managed\nAdvisory',
    description: {
      en: 'Your Financial Consultant analyzes your needs and recommends most appropriate asset allocation and product for you. You will benefit from the professional guidance while maintaining control of your portfolio.',
      th: 'ที่ปรึกษาทางการเงินของท่านวิเคราะห์เป้าหมายการลงทุน เพื่อนำเสนอแนวทางการจัดพอร์ท และผลิตภัณฑ์การ ลงทุนที่เหมาะสม เพื่อประกอบการ ตัดสินใจของลูกค้า'
    },
    callToAction: 'Our Approach',
    url: '/solution/cio-office',
    items: [
      {
        id: 1,
        description: {
          en: 'Dedicated relationship with a Financial Consultant who will get to know you and your needs',
          th: 'วิเคราะห์เป้าหมายการลงทุน เพื่อสร้างแผนการเงินที่ตอบโจทย์เฉพาะคุณ'
        },
      },
      {
        id: 2,
        description: {
          en: 'Personalized financial plan to help you meet your goals',
          th: 'เสนอแนวทางการจัดพอร์ทและผลิตภัณฑ์การลงทุนที่เหมาะสม เพื่อประกอบการตัดสินใจของคุณ'
        },
      },
      {
        id: 3,
        description: {
          en: 'Seamlessly deliver comprehensive financial solution by a Financial Consultant',
          th: 'บริการรอบด้านอย่างไรรอยต่อ'
        },
      }
    ]
  },
  {
    id: 3,
    cover: imgBanner03,
    title: 'Discretionary\nPortfolio',
    description: {
      en: 'Exclusive portfolio selections based on client’s risk profile. Day-to-day investment decisions will be delegated to us. Updates on portfolio and performance will be provided monthly.',
      th: 'ทางเลือกการลงทุนภายใต้ความเสี่ยงที่เหมาะสมของลูกค้า โดยเราเป็นผู้ตัดสินใจการลงทุนแทนลูกค้า และมีการรายงาน ผลประกอบการและรายละเอียดการลงทุน ให้ทราบทุกเดือน'
    },
    callToAction: 'Our Solution',
    url: '/solution/mandate-service',
    items: [
      {
        id: 1,
        description: {
          en: 'Comprehensive investment models with  best-in-class products through “Open Architecture”',
          th: 'โซลูชั่นครบวงจรที่ใช้กลยุทธ์หลากหลายเพื่อตอบโจทย์และความต้องการในการลงทุน'
        },
      },
      {
        id: 2,
        description: {
          en: 'Robust investment process with comprehensive risk management',
          th: 'บริหาร ควบคุมความเสี่ยงและติด ตามพอร์ตอย่างใกล้ชิดโดยมืออาชีพ'
        },
      },
      {
        id: 3,
        description: {
          en: 'Regular updates via reports and commentary clips',
          th: 'หมดกังวลจากการติดตาม ทำรายการ และจัดพอร์ต พร้อมรายงานผล การลงทุนอย่างสม่ำเสมอ'
        },
      },
    ],
  },
];