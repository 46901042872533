import React, {useMemo} from 'react';
import cx from 'classnames';

import {PAGE_ADVISORY} from '@/views/solution/svgs/SolutionApproachMenu';
import SolutionApproachLayout from '@/views/solution/layouts/SolutionApproachLayout';
import SolutionApproachHeadSection from '@/views/solution/sections/SolutionApproachHeadSection';
import SolutionApproachCioOfficeContentSection
  from '@/views/solution/pages/SolutionApproachCioOfficePage/sections/SolutionApproachCioOfficeContentSection';
import ContactSection from '@/views/common/section/ContactSection';

import s from './styles.module.scss';

import imgBanner from '@/assets/images/page-solution-approach-cio-office/banner.png';
import imgMobileBanner from '@/assets/images/page-solution-approach-investment-solution/mobile-banner.png';
import imgChart from '@/assets/images/page-solution-approach-cio-office/chart.svg';


const SolutionApproachCioOfficePage = (props = {}) => {

  const namespace = useMemo(() => 'page-solution-approach-cio-office', []);

  return (
    <SolutionApproachLayout>
      <SolutionApproachHeadSection namespace={namespace} banner={imgBanner} mobileBanner={imgMobileBanner}
                                   chart={imgChart} page={PAGE_ADVISORY}/>
      <SolutionApproachCioOfficeContentSection namespace={namespace}/>
      <ContactSection/>
    </SolutionApproachLayout>
  );

};

export default SolutionApproachCioOfficePage;