import React from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';
import {Link} from 'react-router-dom';

import { getDateByLocale } from '@/utils/locales';
import { locals } from '@/utils/locales';
import useLocale from '@/hooks/useLocale';
import Tag, {TAG_MODE_LIGHT} from "../Tag";
import { emptyImg } from '@/assets/images/page-insight';

import s from './styles.module.scss';


const HighlightArticleEntry = (props = {}) => {

  const { t, locale  } = useLocale('insight-card');

  const {article = null} = props;

  if (!article) {
    return;
  }
  
   const {
    id,
    smallImage,
    headerTh,
    headerEn,
    contentDate,
    descTh,
    descEn,
    tags = [],
  } = article;
  
  const inputBoxes = [];

  if(tags){
    for (let i of tags) {
      inputBoxes.push(<Tag mode={TAG_MODE_LIGHT} title={i.name} />);
    }
  }
  return (
    <Link to={`/${locale}/insight/${id}`}>
      <div className={cx(
        'relative', 'p-2', 'sm:p-12',
        'aspect-highlight-article', 'sm:aspect-square',
        'w-full', 'sm:w-auto',
        'bg-white', 'border', 'border-primary-50',
        'bg-cover', 'bg-no-repeat', 'bg-center',
        'flex', 'justify-start', 'items-stretch', 'h-[755px]', 'max-w-[730px]',
        s.container
      )} style={{backgroundImage: `url(${smallImage || emptyImg})`}}>
        <div className={cx(
          'relative', 'flex-1', 'p-4',
          'flex', 'flex-col', 'justify-center', 'items-stretch',
        )}>
          <div
          className={cx('flex', 'flex-wrap', 'gap-1.5', 'py-2', 'min-h-[43px]')}
          >
            {inputBoxes}
          </div>
          <div className={cx('mt-8', 'flex-1', 'flex', 'flex-col', 'justify-start', 'items-start')}>
            <h2 className={cx('text-white', 'break-words', 'line-clamp-2', 'text-ellipsis')}>{locale === locals.th ? headerTh : headerEn}</h2>
          </div>
          <hr className={cx('flex-shrink-0', 'block', 'mt-4', 'w-full', 'border-0', 'h-px', 'bg-primary-50')}/>
          <span className={cx('mt-2', 'font-light', 'text-sm', 'text-primary-50')}>
            {getDateByLocale(contentDate, locale)}
          </span>
          <p className={cx('mt-6', 'font-light', 'text-white', 'text-xl', 'break-words', 'line-clamp-5', 'text-ellipsis')}>
            {locale === locals.th ? descTh : descEn}
          </p>
          <span className={cx(
            'self-end', 'border', 'border-white',
            'py-1', 'px-4', 'mt-6',
            'font-medium', 'uppercase', 'rounded-lg', 'text-white'
          )}>
            {t("readMore")}
          </span>
        </div>
      </div>
    </Link>
  );

};

HighlightArticleEntry.propTypes = {
  article: PropTypes.object,
};

export default HighlightArticleEntry;