import Api from "../api-core";

export const getAnnouncement = async ({
  pageNo,
  orderBy
}) => {
  const formData = {
    "Limit": 10,
    "PageNo": pageNo, 
    "OrderBy": orderBy
  };
  try {
    const result = await Api().post(
      `${process.env.REACT_APP_GET_ANNOUNCEMENT}`,
      formData
    );
    return result;
  } catch (error) {
    // Handle errors
    console.error("Error:", error);
  }
};

export default getAnnouncement;
