import React, {useMemo} from 'react';
import cx from 'classnames';

import gridRelatedProducts from '@/views/goal/pages/GoalBusinessPage/data/grid-related-products';
import familyBusinessRelatedProducts from '@/views/goal/pages/GoalBusinessPage/data/family-business-related-products';
import expansionRelatedProducts from '@/views/goal/pages/GoalBusinessPage/data/expansion-related-producs';
import realEstateRelatedProducts from '@/views/goal/pages/GoalBusinessPage/data/real-estate-related-products';
import newOpportunitiesRelatedProducts
  from '@/views/goal/pages/GoalBusinessPage/data/new-opportunities-related-products';
import useLocale from "@/hooks/useLocale";
import StandardLayout from "@/views/common/layouts/StandardLayout";
import BreadCrumbs from "@/views/common/components/BreadCrumbs";
import GoalBusinessHeadSection from "@/views/goal/pages/GoalBusinessPage/sections/GoalBusinessHeadSection";
import FamilyBusinessSection from '@/views/goal/pages/GoalBusinessPage/sections/FamilyBusinessSection';
import ExpansionSection from '@/views/goal/pages/GoalBusinessPage/sections/ExpansionSection';
import RealEstateSection from '@/views/goal/pages/GoalBusinessPage/sections/RealEstateSection';
import NewOpportunitiesSection from '@/views/goal/pages/GoalBusinessPage/sections/NewOpportunitiesSection';
import GoalHelpSection from '@/views/goal/sections/GoalHelpSection';
import GoalQuestionSection from '@/views/goal/sections/GoalQuestionSection';
import GoalRelatedProductsGridSection from '@/views/goal/sections/GoalRelatedProductsGridSection';


const GoalBusinessPage = (props = {}) => {

  const {t, i18n, locale} = useLocale('page-goal-business');

  const breadcrumbsPaths = useMemo(() => {
    return [
      {id: 1, title: 'Home', url: `/${locale}`},
      {id: 2, title: 'What\'s your goal', url: ''},
      {id: 3, title: t('pageName'), url: ''},
    ];
  }, [t]);

  return (
    <StandardLayout>
      <div className={cx('flex', 'flex-col', 'justify-start', 'items-stretch')}>
        <BreadCrumbs paths={breadcrumbsPaths}/>
        <GoalBusinessHeadSection/>
        <GoalQuestionSection/>
        <FamilyBusinessSection relatedProducts={familyBusinessRelatedProducts}/>
        <ExpansionSection relatedProducts={expansionRelatedProducts}/>
        <RealEstateSection relatedProducts={realEstateRelatedProducts}/>
        <NewOpportunitiesSection relatedProducts={newOpportunitiesRelatedProducts}/>
        <GoalHelpSection/>
        <GoalRelatedProductsGridSection namespace="solutions" relatedProducts={gridRelatedProducts}/>
      </div>
    </StandardLayout>
  );

};

export default GoalBusinessPage;